/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-no-comment-textnodes */
// /* eslint-disable react/jsx-no-bind
import MainCard from 'ui-component/cards/MainCard';
import { Button, Input, TextField, CircularProgress, Box, Typography } from '@mui/material';
import react from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { Authenticated } from 'utils/checkerfunction';
import styled from 'styled-components';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { UserData } from 'utils/userData';
import PdfView from 'ui-component/cards/PdfView';
import { ConstructionOutlined } from '@mui/icons-material';
import Card from "@mui/material/Card";
import Collapse from "@mui/material/Collapse";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import KeyboardArrowDownIcon from 
"@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from 
"@mui/icons-material/KeyboardArrowUp";
import CalendarMonthIcon from '@mui/icons-material/Event';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import Divider from '@mui/material/Divider';
import ClearIcon from '@mui/icons-material/Clear';
import LoadingButton from '@mui/lab/LoadingButton';

const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    width: 40%;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
`;

const FormForBlood = styled.form`
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    width: 100%;
    padding: 20px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
`;

function CircularProgressWithLabel({ value }) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <div    
        style={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <CircularProgress variant="determinate" value={value} style={{height:200, width:200}}/>
        </div>
        <div style={{ minWidth: '35px' }}>
          <Typography variant="body2" style={{ fontSize: '16px' }} color="#ffff">{`${Math.round(value)}%`}</Typography>
        </div>
        <div style={{color:'#fff'}}>Uploading the File</div>
      </div>
    );
  }

const UploadReport = () => {

    const bloodReports = JSON.parse(localStorage.getItem('bloodReports'));
    const finalPermission = UserData().permissions
    const uploadReport = finalPermission?.find(permission => permission.name === "Upload Reports");

    const location = useLocation();
    Authenticated();
    const [selectedImage, setSelectedImage] = react.useState();
    const [open, setOpen] = react.useState(false);
    const [UploadLoad, setUploadLoad] = react.useState(false);
    const [progress, setProgress] = react.useState(0);
    const [apiResponse, setApiResponse] = react.useState('')
    const [toastMessage, setToastMessage] = react.useState('')
    const [cardOpenStates, setCardOpenStates] = react.useState(
        bloodReports?.map(() => false)
    );
    const [selectedNames, setSelectedNames] = react.useState(Array(bloodReports?.length).fill(''));
    const [selectedFile, setSelectedFile] = react.useState(Array(bloodReports?.length).fill());
    
    const [loading, setLoading] = react.useState(
        bloodReports?.map(() => false)
    );
    const toggleCardOpen = (index) => {
        setCardOpenStates((prevStates) =>
          prevStates.map((state, i) => (i === index ? !state : state))
        );
    };

    const imageChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedImage(e.target.files[0]);
        }
    };

    const handleImageChange = (index, e) => {
        const newName = e.target.files[0];
        if (newName) {
          setSelectedNames((prevNames) =>
            prevNames.map((name, i) => (i === index ? newName.name : name))
          );
          setSelectedFile((prevNames) =>
            prevNames.map((name, i) => (i === index ? newName : name))
          );
        }
    };
    
    const clearSelectedName = (index) => {
        setSelectedNames((prevNames) =>
          prevNames.map((name, i) => (i === index ? '' : name))
        );
        setSelectedFile((prevNames) =>
          prevNames.map((name, i) => (i === index ? '' : name))
        )
    };

    console.log('hello',loading)

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleAlert = () => {
        setOpen(true);
    };

    const navigate = useNavigate();

    const search = useLocation().search;
    const id = new URLSearchParams(search).get('id');
    const plan = new URLSearchParams(search).get('reportType');
    const name = new URLSearchParams(search).get('user');
    const url = new URLSearchParams(search).get('url');
    const email = new URLSearchParams(search).get('email');
    const phone = new URLSearchParams(search).get('phone');
    const customerId = new URLSearchParams(search).get('customer_id');
    // const email = location.state.email;
    // const phone = location.state.phone;
    const onSubmit = (data) => {
        setUploadLoad(true);
        const formData = new FormData();

        formData.append('user_id', id);
        formData.append('files', selectedImage);

        let setter = '';
        const plan = new URLSearchParams(search).get('reportType');

        if (plan === 'Diet') {
            setter = 'uploadDietPlan';
        } else if (plan === 'Dna') {
            setter = 'uploadDNAReport';
        } else {
            setter = 'uploadBloodReport';
        }

        axios
            .post(`${process.env.REACT_APP_BASEURL}admin/user/${setter}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                },
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    const percentage = (loaded / total) * 100;
                    // Update your progress bar or store the percentage
                    setProgress(Math.round(percentage));
                },
            })
            .then(async (response) => {
                console.log(response)
                if (response.data.result === 'success') {
                    setUploadLoad(false);
                    setApiResponse('success')
                    setToastMessage(response.data.message)
                    handleAlert();
                    setProgress(0)
                    setTimeout(() => {
                        window.open('/user/newTest', '_self');
                    }, 2200);
                }
            })
            .catch((error) => {
                setUploadLoad(false);
                setApiResponse('error')
                setToastMessage(error.response.data.message)
                handleAlert();
            })
    };

    const uploadBloodreport = (index, report) => {
        setLoading((prevStates) => ({
            ...prevStates,
            [index]: true,
          }));
        const formData = new FormData();

        formData.append('user_id', id);
        formData.append('files', selectedFile[index]);
        formData.append('id', report.id);
        formData.append('is_past', report.is_past_kit);
        axios
            .post(`${process.env.REACT_APP_BASEURL}admin/user/uploadBloodReports`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                },
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    const percentage = (loaded / total) * 100;
                    setProgress(Math.round(percentage));
                },
            })
            .then((response) => {
                setApiResponse('success')
                setToastMessage(response.data.message)
                setSelectedImage('')
                setSelectedFile((prevNames) => ({
                    ...prevNames,
                    [index]: '',
                }));
                setSelectedNames((prevNames) => ({
                    ...prevNames,
                    [index]: '',
                }));
                handleAlert();
                setLoading((prevStates) => ({
                    ...prevStates,
                    [index]: false,
                }));
                navigate(-1);
            })
            .catch((error) => {
                setApiResponse('error')
                setToastMessage(error.response.data.message)
                handleAlert();
                setLoading((prevStates) => ({
                    ...prevStates,
                    [index]: false,
                }));
            })
    }

    const Alert = react.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

    const isLoading = (loading) => {
        if (Array.isArray(loading)) {
            return loading.some(Boolean);
          }
          if (typeof loading === 'object' && loading !== null) {
            return Object.values(loading).some(Boolean);
          }
          return false;
        }

    return (
        <div style={{position:'relative'}}>
            {(UploadLoad || isLoading(loading)) && <div
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                zIndex: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection:'column',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                }} >
                {progress !== 100 ? 
                <>
                <CircularProgressWithLabel value={progress} />
                </> : <>
                <CircularProgress style={{height:200, width:200, position:'absolute'}} />
                <div style={{color:'#fff', fontSize:15}}>Compressing the File</div>
                </>}
            </div>}
            <Button onClick={() => navigate(-1)} style={{ color: 'darkgrey' }}>
                ← Back
            </Button>
            <br />
            <Snackbar open={open} autoHideDuration={6000} style={{ alignItems: 'center' }} onClose={handleClose}>
                <Alert onClose={handleClose} severity={apiResponse} sx={{ width: '100%' }}>
                    {toastMessage}
                </Alert>
            </Snackbar>
            <MainCard>
                <h1>Upload Report For {plan}</h1>
                <br />
                <br />
               {plan === 'Blood' ? (
                    <FormForBlood onSubmit={handleSubmit(onSubmit)}>
                    <h3>Customer Name: {capitalize(name)}</h3>
                    {/* <h3>Customer Id : {customerId}</h3> */}
                    {
                        customerId && (customerId!=="" && customerId!=='undefined' && customerId!=="null") ? <h3>Customer Id : {customerId}</h3> : <h3>Customer Id : Not available</h3>
                    }
                    {
                        email && (email!=="" && email!=='undefined' && email!=="null") ? <h3>Email : {email}</h3> : <h3>Email : Not available</h3>
                    }
                    {
                        phone && (phone!=="" && phone!=='undefined' && phone!=="null") ? <h3>Phone : {phone}</h3> : <h3>Phone : Not available</h3>
                    }
                    </FormForBlood>
               ) :
               (<Form onSubmit={handleSubmit(onSubmit)}>
                    <h3>Customer Name: {capitalize(name)}</h3>
                    {/* <h3>Customer Id : {customerId}</h3> */}
                    {
                        customerId && (customerId!=="" && customerId!=='undefined' && customerId!=="null") ? <h3>Customer Id : {customerId}</h3> : <h3>Customer Id : Not available</h3>
                    }
                    {
                        email && (email!=="" && email!=='undefined' && email!=="null") ? <h3>Email : {email}</h3> : <h3>Email : Not available</h3>
                    }
                    {
                        phone && (phone!=="" && phone!=='undefined' && phone!=="null") ? <h3>Phone : {phone}</h3> : <h3>Phone : Not available</h3>
                    }

                    <Input name="id" value={id} {...register('user_id')} readonly disabled />
                    <br />
                    <br />
                    <br />
                    <div className="form-group">
                        <Input type="file" disabled={!uploadReport?.can_create} className="form-control-file" id="exampleFormControlFile1" onChange={imageChange} />
                    </div>
                    <br />
                    <br />
                    
                        <div>
                            <LoadingButton disabled={!uploadReport?.can_create} loading={UploadLoad} type="submit" variant="outlined">
                                Upload
                            </LoadingButton>
                            {/* <Button variant="outlined">
                                Upload
                            </Button> */}
                            {url && url !== 'null' && (
                                <Button
                                    variant="contained"
                                    style={{ marginLeft: '14px', color: '#ffffff' }}
                                    onClick={()=>{
                                    window.open(decodeURIComponent(url),'_blank')
                                    }}
                                >
                                    {plan === 'Dna' ? 'View DNA Report' : 'View Existing Invoice'}
                                </Button>
                            )}
                        </div>
                </Form>
                )}
            {plan === 'Blood' &&
            bloodReports &&
            bloodReports?.map((report, index)=>(
                <Card 
                key={report.id}
                sx={{
                    minWidth: 300,
                    borderRadius: '10px',
                    border: '1px solid #E6E6E6',
                    background: '#FFF',
                    boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.12)',
                    margin: '20px 0px'
                }}>
                    <CardHeader
                        title={bloodReports?.length === 1 ? 'Blood Report' : `Blood Report ${index + 1}`}
                        sx={{background: '#1B99DF'}}
                        titleTypographyProps={{
                            style: {
                                color: '#FFF'
                            }
                        }}
                        action={
                            <div>
                                {report.url && (
                                <Button onClick={()=>{window.open(report.url, '_blank')}} variant='outlined' sx={{background: '#EAFFF8', color: '#2D59D2', marginRight: '20px', ":hover": { bgcolor: "#2D59D2", color: "#FFFFFF" } }}>
                                    View Existing Report
                                </Button>
                                )}
                                <IconButton
                                    onClick={() =>{ if (uploadReport?.can_create) toggleCardOpen(index); }}
                                    aria-label="expand"
                                    size="small"
                                    >
                                    {cardOpenStates[index] ? <KeyboardArrowUpIcon />
                                        : <KeyboardArrowDownIcon />}
                                </IconButton>
                            </div>
                        }
                    />
                    <div style={{ 
                        backgroundColor: "rgba(211,211,211,0.4)" 
                    }}>
                        <Collapse in={cardOpenStates[index]} sx={{bgcolor: '#FFF'}} timeout="auto"
                            unmountOnExit>
                            <CardContent>
                                <Container sx={{ 
                                    height: 100, 
                                    lineHeight: 2,
                                }}>
                                    <div style={{display:'flex', justifyContent:'space-between'}}>
                                        <div style={{display:'flex', flexDirection:'column', fontSize: '16px' }}>
                                        Sample Collection Date
                                        <TextField
                                            value={report.receive_date}
                                            disabled
                                            style={{ fontSize: '16px' }}
                                            InputProps={{
                                            endAdornment: (
                                                <CalendarMonthIcon style={{ color: 'gray' }} />
                                            )
                                            }}
                                        />
                                        </div>
                                        <div style={{display:'flex', flexDirection:'column', width:'80%', fontSize: '16px' }}>
                                        Upload Report
                                        <TextField
                                            InputProps={{
                                            startAdornment: (
                                                <Button
                                                color="primary"
                                                variant="contained"
                                                sx={{
                                                    color: 'white'
                                                }}
                                                size="large"
                                                component="label"
                                                >
                                                Browse
                                                <input
                                                    hidden
                                                    type="file"
                                                    onChange={(e) => handleImageChange(index, e)}
                                                />
                                                </Button>
                                            ),
                                            endAdornment: (
                                                selectedNames[index] && (
                                                  <IconButton onClick={() => clearSelectedName(index)}>
                                                    <ClearIcon />
                                                  </IconButton>
                                                )
                                              )
                                            }}
                                            id="outlined-basic"
                                            placeholder="Select and upload the blood test report"
                                            sx={{
                                                width: '100%',
                                                paddingLeft: '0.5rem'
                                            }}
                                            value={selectedNames[index]}
                                            onChange={(event) => setSelectedNames(index, event.target.value)}
                                        />
                                        {/* <Input type="file" className="form-control-file" id="exampleFormControlFile1" onChange={imageChange} /> */}
                                        </div>
                                    </div>
                                </Container>
                            </CardContent>
                            <Divider variant="middle" />
                            <CardActions style={{display: 'flex', justifyContent: 'center'}}>
                            {loading[index] ? (
                                <CircularProgress /> // Show loader while loading
                            ) : (
                                <Button onClick={()=>{uploadBloodreport(index, report);}} variant='outlined' sx={{background: '#1B99DF', color: '#FFF' , ":hover": { color: "#1B99DF" }}} >Upload</Button>
                            )}
                            </CardActions>
                        </Collapse>
                    </div>
                </Card>
            ))}
            </MainCard>
            {console.log(selectedImage)}
        </div>
    );
};

export default UploadReport;
