import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import { Box, Button, TextField, Select, Link, Divider, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip, Modal, FormControl, FormLabel, FormControlLabel, Radio, RadioGroup, MenuItem, InputLabel, InputAdornment, Pagination } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';
import EditIcon from '@mui/icons-material/Edit';
import MaterialTable from 'material-table';
import tableIcons from 'themes/MaterialTableIcons';
import axios from 'axios';
import exportFromJSON from 'export-from-json';
import { Header, Text, TableHeading } from './style';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import ReactLoading from 'react-loading';
import './style.css';
import { borderRadius } from '@mui/system';
import excell from "../../../assets/images/excell.svg";
import { makeStyles } from '@material-ui/core/styles';
import { UserData } from 'utils/userData';
import SearchInput from '@mui/material/OutlinedInput';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '25px',
                paddingRight: "25px"
            },
            '&:hover fieldset': {
            },
            '&.Mui-focused fieldset': {
            },
        },
    },
}));

const Recipe = () => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [loadingsearch, setLoadingsearch] = useState(false);
    const [allRecipes, setAllRecipes] = useState([]);
    const [exportLink, setExportLink] = useState(null);
    const [loading, setLoading] = useState(false);
    const [recipeToDelete, setRecipeToDelete] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(100);
    const [searchValue, setSearchValue] = useState('');
    const [type, setType] = useState('');
    const [typeIndex, setTypeIndex] = useState(null);
    const [allBaseTypes, setAllBaseTypes] = useState([]);
    const [exportStart, setExportStart] = useState(1);
    const [exportEnd, setExportEnd] = useState(1000);
    const [exportDialogOpen, setExportDialogOpen] = useState(false);
    const [failureMessage, setFailureMessage] = useState('');
    const [testInput, setTestInput] = useState('')
    const [recipeInActiveMessage, setRecipeInActiveMessage] = useState('')
    const exportStartRef = useRef(null);

    const finalPermission = UserData().permissions
    const importExport = finalPermission?.find(permission => permission.name === "Import/Export Recipes");
    const recipe = finalPermission?.find(permission => permission.name === "Recipe Panel");

    const handlePageChange = (event, value) => {
        setPage(value);
        setLoading(true);
        // getData();
    };

    const deleteRecipe = async () => {
        setLoading(true);
        setDialogOpen(false);
        try {
            const res = await axios.delete(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/recipe/${recipeToDelete}`)
            if (res.status === 200) {
                alert(res.data?.message);
                window.location.reload();
            } else {
                alert('Something went wrong');
                setLoading(false);
            }
        } catch (err) {
            console.log(err)
            alert(err.response.data.message)
            setLoading(false);
        }
    }

    const deleteDialogContent = {
        title: 'Delete Recipe',
        content: 'Are you sure you want to delete this Recipe?',
        action: 'Delete',
        color: 'error',
        function: deleteRecipe
    }
    const [dialogAction, setDialogAction] = useState(deleteDialogContent);

    const navigate = useNavigate();

    const handleClose = () => {
        setRecipeToDelete(null);
        setDialogOpen(false);
    };

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize)
    })

    const buttonStyle = {
        color: 'white',
        marginRight: '0.5rem',
        fontSize: '0.9rem',
        padding: '5px 45px',
    }

    const handleFileUpload = async (e) => {
        setLoading(true);
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('files', file);
        const res = await axios.post(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/recipe/import`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        setLoading(false);
        if (res.data?.result === 'success') {
            alert(res.data?.message);
            window.location.reload();
        }
        else alert(res.data?.message)
    }

    const renderDialog = () => (
        <Dialog
            open={dialogOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {dialogAction?.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {dialogAction?.content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={() => {
                    deleteRecipe();
                }} autoFocus color={dialogAction?.color}>
                    {dialogAction?.action}
                </Button>
            </DialogActions>
        </Dialog>
    )

    useEffect(async () => {
        // const res = await axios.post(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/recipe/export`)
        // setExportLink(res.data.data[0]?.excel_url)

        const res1 = await axios.get(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/base_types`)
        setAllBaseTypes(res1.data.data)
    }, [])

    const handleCloseExportDialog = () => {
        setExportDialogOpen(false);
    }

    const exportDialog = () => (
        <div>
            <Dialog
                open={exportDialogOpen}
                onClose={handleCloseExportDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <div
                        style={{
                            fontSize: 15,
                            fontWeight: 500,
                            color: '#313131',
                            paddingLeft: 0,
                            paddingRight: 0
                        }}
                    >
                        <div
                            style={{
                                paddingLeft: 0,
                                paddingBottom: 20,
                                paddingTop: 0,
                                borderBottom: '1px solid #919191',
                                fontSize: 20,
                                marginBottom: 20,
                                color: '#313131'
                            }}
                        >
                            Export Recipes
                        </div>
                        <div
                            style={{
                                paddingLeft: 0,
                                paddingBottom: 30,
                                paddingTop: 0,
                                fontSize: 18,
                                lineHeight: '30px',
                                marginBottom: 0,
                                color: '#616161'
                            }}
                        >
                            Enter the start and end index of the recipes you<br /> want to export out of the total {total} recipes.
                        </div>
                        <div
                            style={{
                                paddingLeft: 0,
                                paddingBottom: 15,
                                paddingTop: 0,
                                display: 'flex',
                                color: '#313131'
                            }}
                        >

                            <TextField
                                id="outlined-basic"
                                label="Start Index"
                                variant="outlined"
                                type="number"
                                ref={exportStartRef}
                                style={{ width: '100%' }}
                            // value={exportStart}
                            // onChange={(e) => {
                            //     console.log(e.target.value, "111111")
                            //     setExportStart(e.target.value);

                            // }}
                            />
                        </div>
                        <div
                            style={{
                                paddingLeft: 0,
                                paddingBottom: 15,
                                paddingTop: 0,
                                display: 'flex',
                                color: '#313131'
                            }}
                        >

                            <TextField
                                id="outlined-basic"
                                label="End Index"
                                variant="outlined"
                                type="number"
                                style={{ width: '100%' }}
                            // value={exportEnd}
                            // onChange={(e) => {
                            //     setExportEnd(e.target.value);
                            // }}
                            />
                        </div>
                    </div>
                    <p
                        style={{
                            fontSize: 14,
                            color: '#D32C2C',
                            textAlign: 'center',
                            padding: 0,
                            margin: 0
                        }}
                    >
                        {failureMessage}
                    </p>
                </DialogContent>
                <DialogActions>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            borderTop: '1px solid #919191',
                            paddingTop: 20,
                            paddingBottom: 10
                        }}
                    >
                        <Button
                            onClick={() => handleCloseExportDialog()}
                            style={{
                                border: '1px solid #1B99DF',
                                background: '#FFFFFF',
                                borderRadius: 10,
                                color: '#1B99DF',
                                fontSize: 15,
                                fontWeight: 500,
                                padding: '6px 75px',
                                margin: '0 5px'
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() =>
                                handleExportLink()}
                            style={{
                                border: '1px solid #1B99DF',
                                background: '#1B99DF',
                                borderRadius: 10,
                                color: '#ffffff',
                                fontSize: 15,
                                fontWeight: 500,
                                padding: '6px 75px',
                                margin: '0 5px'
                            }}
                        >
                            Confirm
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    )

    const handleExportLink = async (e) => {
        if (exportEnd === 0) alert('Please enter a valid number')
        else if (exportEnd > total || exportStart > total) setFailureMessage(`Please enter a number less than ${total}.`)
        else if (exportStart > exportEnd) setFailureMessage('Start index cannot be greater than end index.')
        else {
            setLoading(true);
            setExportDialogOpen(false);
            try {
                const res = await axios.post(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/recipe/export?start=${exportStart}&end=${exportEnd}`)
                console.log(res, "res export Recipe");
                if (res !== "") {
                    setLoading(false);
                    const link = document.createElement("a");
                    link.download = res.data.data[0].excel_url;
                    link.href = res.data.data[0].excel_url;
                    link.target = "_blank";
                    link.click();
                }
            } catch (err) {
                console.log(err, "err export Recipe");
                alert('Something went wrong. Please try again later.')
                setLoading(false);
            }
        }
    }

    const fetchData = () => {
        console.log("Searching for", searchValue);
    
      axios
          .get(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/recipe?size=${limit}&page=${page}&search=${searchValue}&type=${type}`)
          .then((response) => {
              const data = response.data;
              setRecipeInActiveMessage(data?.inactive_message);
              setTotal(data?.total_count);
              setAllRecipes(data.data);
              setLoading(false);
          })
          .catch((error) => {
              console.error('Error', error.response);
              setLoading(false);
          });
      };  

    let searchTimeout;

    useEffect(() => {
        if (page === 1 && searchValue === '') setLoading(true);
        if(searchValue.length > 0) setPage(1);
        setLoading(true);
        
        clearTimeout(searchTimeout);
        
        searchTimeout = setTimeout(() => {
            if(searchValue) fetchData();
        }, 2000);

        return () => {
            clearTimeout(searchTimeout);
        };
      }, [page, limit, searchValue, type]);

    useEffect(() => {
        if(!searchValue) fetchData();
    }, [page, limit, searchValue, type]);

    const arr = []
    console.log(recipeInActiveMessage, "allRecipes")
    allRecipes?.map((item, i) =>
        arr.push({
            id: (limit * (page - 1)) + i + 1,
            name: item.is_active ? item.name : <Tooltip title={recipeInActiveMessage} ><span style={{ color: '#D32C2C' }}>{item.name}</span></Tooltip>,
            tags: item.tags.length > 2
                ? item.tags.slice(0, 2).map(tag => tag.name).join(", ") + "..."
                : item.tags.map(tag => tag.name).join(", "),
            macro_nutrients: item.macro_nutrients.length === 0 ? <Tooltip title={recipeInActiveMessage} ><span style={item.is_active ? {} : { color: '#D32C2C' }}>Calories: 0 gm | Carbs: 0 gm | Protein: 0 gm | Fat: 0 gm</span></Tooltip> : item.is_active ? item.macro_nutrients.map((ele, index) => <span>{ele.name + ": " + ele.value + " " + (ele.unit === 'g' ? 'gm' : ele?.unit) + (index !== item.macro_nutrients.length - 1 ? " | " : "")}</span>) : <Tooltip title={recipeInActiveMessage} ><span style={{ color: '#D32C2C' }}>{item.macro_nutrients.map((ele, index) => <span>{ele.name + ": " + ele.value + " " + (ele.unit === 'g' ? 'gm' : ele?.unit) + (index !== item.macro_nutrients.length - 1 ? " | " : "")}</span>)}</span></Tooltip>,
            action: <div style={{ display: 'inline' }}>
                <Tooltip title="Edit Ingredient">
                    <EditIcon sx={{ cursor: recipe?.can_create ? 'pointer' : 'default', color: recipe?.can_create ? '#02a9f4' : '#AAA1A1' }} onClick={() => {
                        if(recipe?.can_create){
                        navigate('/nutrition/recipe/add', { state: { item } })
                        // setDialogAction(editDialogContent)
                        }
                    }} />
                </Tooltip>
                &nbsp;&nbsp;
                <Tooltip title="Delete Ingredient">
                    <DeleteIcon sx={{ cursor: recipe?.can_create ? 'pointer' : 'default', color: recipe?.can_create ? '#f44336' : '#AAA1A1' }} onClick={() => {
                        if(recipe?.can_create){
                        setRecipeToDelete(item.id);
                        setDialogOpen(true);
                        }
                    }} />
                </Tooltip>
            </div>
        })
    )

    const handleBaseChange = (baseName, index) => {
        setType(baseName);
        setTypeIndex(index);
        setPage(1);
        setLimit(10);
        setSearchValue('');
    }


    const exporthandlerV1 = async () => {
        const starting = prompt("Enter start index (min 1)")
        const startIndex = parseInt(starting, 10);
        setExportStart(startIndex);
        let endIndex = 100;
        if (startIndex <= 0) alert('Start Index cannot be less than or equal to 1')
        else if (startIndex > total) alert(`Start Index cannot be greater than ${total}`)
        else if (startIndex) {
            const ending = prompt(`Enter end index (max ${total})`)
            endIndex = parseInt(ending, 10);
            setExportEnd(endIndex);
            if (endIndex <= 0) alert('End Index cannot be less than or equal to 1')
            else if (endIndex > total) alert(`End Index cannot be greater than ${total}`)
            else if (startIndex > endIndex) alert('Start index cannot be greater than end index.')
            else if (startIndex === endIndex) alert('Start index and end index cannot be same.')
            else if (startIndex && endIndex) {
                setLoading(true);
                try {
                    const res = await axios.post(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/recipe/export?start=${startIndex}&end=${endIndex}`)
                    console.log(res, "res export Recipe");
                    if (res !== "") {
                        setLoading(false);
                        const link = document.createElement("a");
                        link.download = res.data.data[0].excel_url;
                        link.href = res.data.data[0].excel_url;
                        link.target = "_blank";
                        link.click();
                    }
                } catch (err) {
                    console.log(err, "err export Recipe");
                    alert('Something went wrong. Please try again later.')
                    setLoading(false);
                }
            }
        }
    }

    const classes = useStyles();

    return (
        <MainCard>
            <Header direction={windowWidth > 700 ? 'row' : 'column'}>
                <TableHeading>Recipe List</TableHeading>
                &nbsp;&nbsp;
                <div
                    className="header-buttons"
                    style={{
                        display: 'flex',
                        flexDirection: windowWidth > 700 ? 'row' : 'column',
                        justifyContent: 'space-between'
                    }}
                >
                    <Button
                        component="label"
                        size="small"
                        variant="contained"
                        startIcon=<img src={excell} alt=" " />
                        sx={buttonStyle}
                        disabled={!importExport?.can_create}
                        // onClick={() => { console.log("Clicked") }}
                      
                    >
                        Import
                        <input hidden type="file" accept=".xls, .xlsx" onChange={handleFileUpload} />
                    </Button>
                    &nbsp;
                    <Button
                        size="small"
                        variant="contained"
                        startIcon=<img src={excell} alt=" " />
                        sx={buttonStyle}
                        href={exportLink}
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                        // onClick={console.log("Downloading...")}
                        disabled={!importExport?.can_create}
                        onClick={() => { exporthandlerV1() }}
                     
                    >
                        {/* <a href={exportLink} style={{textDecoration: 'none', color: 'white'}}> */}
                        Export
                        {/* </a> */}
                    </Button>
                    &nbsp;
                    <Button
                        size="small"
                        variant="contained"
                        startIcon={<AddIcon />}
                        disabled={!recipe?.can_create}
                        sx={buttonStyle}
                        onClick={() => { navigate('/nutrition/recipe/add') }}
                       
                    >
                        New Recipe
                    </Button>
                </div>
            </Header>
            {renderDialog()}
            {exportDialog()}
            &nbsp;&nbsp;
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            padding: "10px 10px",
                                        }}
                                    >
                                        <div>
                                            {
                                                allBaseTypes.map((item, index) =>
                                                    index < 3 &&
                                                    <Button
                                                        variant={type === item.name ? 'contained' : 'outlined'}
                                                        sx={{
                                                            borderRadius: '100px',
                                                            color: type === item.name ? 'white' : '#1B99DF',
                                                            marginRight: '0.5rem',
                                                            padding: '0.5rem 1rem',
                                                            fontSize: '1rem'
                                                        }}
                                                        onClick={() => handleBaseChange(item.name, index)}
                                                    >
                                                        {item.name}
                                                    </Button>)
                                            }
                                            &nbsp;&nbsp;
                                            {
                                                typeIndex >= 3 &&
                                                <Button
                                                    variant='contained'
                                                    sx={{
                                                        borderRadius: '100px',
                                                        color: 'white',
                                                        marginRight: '0.5rem',
                                                        padding: '0.5rem 1rem',
                                                        fontSize: '1rem'
                                                    }}
                                                // onClick={() => handleBaseChange(item.name)}
                                                >
                                                    {type}
                                                </Button>
                                            }
                                            <div className="dropdown">
                                                <p className="dropbtn">+ More</p>
                                                <div className="dropdown-content">
                                                    {allBaseTypes?.map((item, index) => index >= 3 && item.name !== 'All' && <p onClick={() => handleBaseChange(item.name, index)}>{item.name}</p>)}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <TextField
                                        autoComplete="off"
                                        className="search-bar"
                                        key="search"
                                        autoFocus="autoFocus"
                                        id="standard-basic"
                                        placeholder='Search'
                                        variant="standard"
                                        value={searchValue}
                                        onChange={(e) => {
                                            setSearchValue(e.target.value);
                                        }}
                                    /> */}
                                        <div className='search-bar' >
                                            <SearchInput
                                                autoComplete="off"
                                                key="search"
                                                autoFocus="autoFocus"
                                                className={classes.root}
                                                id="standard-basic"
                                                placeholder='Search for Recipe'
                                                variant="outlined"
                                                inputProps={{ style: { borderRadius: "0px" } }}
                                                value={searchValue}
                                                onChange={(e) => {
                                                    setSearchValue(e.target.value);
                                                }}
                                                style={{
                                                    backgroundColor:'#02a9f4',
                                                    color:'#ffff',
                                                    borderRadius:9
                                                }}
                                                endAdornment={
                                                    <div style={{ display: 'flex', alignItems: 'center', marginLeft:7 }}>
                                                        <SearchRoundedIcon />
                                                        <span>Search</span>
                                                </div>
                                                }
                                            />
                                            {/* <div className='search-icon-container' >
                                                <SearchRoundedIcon />
                                                <span>Search</span>
                                            </div> */}
                                        </div>
                                        {/* &nbsp;&nbsp;&nbsp; */}
                                        {/* <Button 
                                variant="contained" 
                                size="small"
                                sx={{color: 'white'}} 
                                onClick={() => {
                                if(searchKey === '' || searchValue === '') {
                                    alert('Please select search key and enter search value');
                                } else {
                                    getData();
                                }
                            }}>Search</Button> */}
                                        {/* <div style={{ width: "13rem" }}>
                                <MTableToolbar {...props} />
                            </div> */}
                                    </div>
  
                    <div className='recipe-table' >

                        <MaterialTable
                            title="List of Ingredients"
                            icons={tableIcons}
                            isLoading={loading}
                            columns={[
                                { title: 'S.No', field: 'id', headerStyle: {width:'15px !important'}, cellStyle:{width:'15px'}},
                                { title: 'Name', field: 'name', headerStyle: { width: '20%' }, cellStyle: { width: "20%" } },
                                { title: 'Macro-Nutrients (in gm)', field: 'macro_nutrients', headerStyle: { width: '43%' }, cellStyle: { width: "43%" }, },
                                { title: 'Tags', field: 'tags', headerStyle: { width: '20%' }, cellStyle: { width: "20%" } },
                                { title: 'Action', field: 'action', headerStyle: { borderRadius: '0 10px 0 0'}, cellStyle:{width:'30px'}, sorting: false }
                            ]}
                            data={arr}
                            options={{
                                toolbar: false,
                                search: false,
                                paging: false,
                                // pageSize: 5,
                                maxBodyHeight: '700px',
                                border: '1px solid #adadad',
                                cellStyle: {
                                    border: '1px solid #adadad',
                                    // overflow: 'hidden',
                                },
                                headerStyle: {
                                    backgroundColor: '#EDEDED',
                                    color: '#616161',
                                    fontWeight: 100,
                                    border: '1px solid #adadad',
                                },
                                rowStyle: (x) => {
                                    if (x.tableData.id % 2) {
                                        return {
                                            backgroundColor: '#EDEDED',
                                            // border: '1px solid #adadad'
                                        };
                                    }
                                    return {
                                        backgroundColor: '#FFFFFF',
                                        // border: '1px solid #adadad'
                                    };
                                }
                            }}
                            style={{
                                border: '1px solid #adadad',
                                padding: '10px 10px',
                            }}
                        />
                <div
                    width="100%"
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '1rem'
                    }}
                >
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="demo-simple-select-label">Results per Page</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={limit}
                            label="Results per Page"
                            onChange={(event) => { setLimit(event.target.value) }}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                    </FormControl>
                    &nbsp;&nbsp;
                    <Pagination
                        count={Math.ceil(total / limit) || 10}
                        page={page}
                        onChange={handlePageChange}
                        variant="outlined"
                        shape="rounded"
                        size="small"
                    />
                </div>
                    </div>
            
        </MainCard>
    )
}

export default Recipe;