import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import MainCard from '../../../ui-component/cards/MainCard';
import axios from 'axios';
import {
    MenuItem, Select, Button,
    Tooltip,
    Pagination,
    FormControl,
    InputLabel,
    TextField,
    InputAdornment,
} from '@mui/material';
import MaterialTable from 'material-table';
import tableIcons from 'themes/MaterialTableIcons';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { UserData } from 'utils/userData';
import ReactLoading from "react-loading";
import CircularProgress from '@material-ui/core/CircularProgress';
import "./style.css";




const Dashboard = () => {
    const navigate = useNavigate();
    const [user, setUserData] = useState(null);
    const [userId, setUserID] = useState(null);
    const [data, setData] = useState(null);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [total, setTotal] = useState(10);
    const [search,  setSearch] = useState('');
    const [roleId, setRoleId] = useState('all');
    const [AdminId, setAdminId] = useState('');
    const [open, setOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastSeverity, setToastSeverity] = useState("success");
    const [disableForRole, setDisableForRole] = useState(false);
    const [tableLoading, setTableLoading] = useState(true);
    const [loadingsearch, setLoadingsearch] = useState(false);

    const success = (message) => {
        setToastSeverity("success");
        setToastMessage(message);
        setOpen(true);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setToastMessage("");
        setOpen(false);
    }

    const errorToast = (message) => {
        setToastSeverity("error");
        setToastMessage(message);
        setOpen(true);
    }

    const warningToast = (message) => {
        setToastSeverity("warning");
        setToastMessage(message);
        setOpen(true);
    }

    const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

    const getAllData = async () => {
        axios.get(`${process.env.REACT_APP_BASEURL}admin/consultation/consultants?page=${page}&size=${size}&search=${search}&role_id=${roleId === "all" ? "" : roleId}&admin_user_id=${AdminId}`).then((response) => {
            setData(response.data.data);
            setTotal(response.data.total_count);
            setTableLoading(false);
        }).then(() => {
            setTableLoading(false);
        })
    }

    let searchTimeout;

    const role = UserData().role;
    
    useEffect(() => {
        if(role === "10" || role === "6" || role === "7"){
            if(AdminId) {
                setTableLoading(true);
                getAllData();
            }
        } else {
            setTableLoading(true);
            getAllData();
        }
    }, [search, page, size, roleId, AdminId, role]);

    useEffect(() => {
        setTableLoading(true);
        axios.get(`${process.env.REACT_APP_BASEURL}admin/consultation/roles`).then((response) => {
            setUserData(response.data.data);
        });
    }, []);

    useEffect(() => {
        setPage(1);
    }, [search, roleId]);

    useEffect(() => {
        const role = UserData().role;
        const name = UserData().name;
        const adminId = UserData().userId;
        if (role === "10" || role === "6" || role === "7") {
            setTableLoading(true);
            setTimeout(() => {
                setRoleId(role);
                setAdminId(adminId);
                setDisableForRole(true);
                setTableLoading(false);
            }, 50);
        }
    }, []);

    const userList = user?.map((i) => <MenuItem value={i.id}>{i.name}</MenuItem>);


    const malaki = (event) => {
        setRoleId(event.target.value);
    };

    const columns = [
        { title: 'S.No', field: 'sno' },
        { title: 'Name', field: 'name' },
        { title: 'Designation', field: 'designation' },
        { title: 'Email', field: 'email' },
        { title: 'Experience', field: 'Experience',
        customSort: (a, b) => (parseInt(a.Experience.split(" ")[0],10) - parseInt(b.Experience.split(" ")[0],10))},
        { title: 'Availability', field: 'availability', sorting: false },
        { title: 'Region', field: 'region', sorting: false },
        {
            title: 'Action', field: 'action', sorting: false

        }
    ];

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleDeleteConsultant = (id) => {
        try {
            axios.delete(`${process.env.REACT_APP_BASEURL}admin/consultation/consultants/${id}`).then((response) => {
                if (response.data.result === "success") {
                    success(response.data.message);
                    setTimeout(() => {
                        window.location.reload();
                    }
                        , 1000);
                } else {
                    errorToast(response.data.message);
                }
            }).catch((err) => {
                console.log(err);
                errorToast(err.response.data.message);
            });
        }
        catch (err) {
            console.log(err);
            errorToast("Could not delete the consultant");
        }
    }

    const arr = []

    data?.map((item, i) => {
        if (item.is_approved !== 1 && item.is_approved !== 2) {
            arr.push({
                sno: size * (page - 1) + i + 1,
                name: item.name,
                designation: item.designation,
                email: item.email ? item.email : 'Not Available',
                Experience: item.year_of_experience ? item.year_of_experience + " years" : 'Not Available',
                region: item.region,
                availability: (
                    <span className='slot-button' onClick={() => {
                        navigate('/consultation/dashboard/slotselection', { state: { item } })
                    }} >
                        Select Slots
                    </span>
                ),
                action: <div style={{ display: 'flex' }}>
                    <Tooltip title="Edit Consultant" >
                        <EditIcon sx={{ cursor: 'pointer', color: '#02a9f4' }} onClick={() => {
                            navigate(`/consultation/dashboard/add`, { state: { item } })
                        }} />
                    </Tooltip>
                    &nbsp;&nbsp;
                    <Tooltip title="Delete Consultant" >
                        <DeleteIcon color='error' sx={{ cursor: 'pointer' }} onClick={() => { handleDeleteConsultant(item.id) }} />
                    </Tooltip>
                </div>
            });
        }
        return null;
    });

    return (
        <>
            <MainCard>
                <div className='header-container' >
                    <div>
                        {console.log(roleId, "role id")}
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" value={roleId ? roleId : "All"} sx={{ width: "200px" }} placeholder="All" onChange={malaki} disabled={disableForRole} >
                            {userList}
                            <MenuItem value="all">All Experts</MenuItem>
                        </Select>
                    </div>
                    <TextField
                        autoComplete="off"
                        disabled={disableForRole}
                        key="search"
                        autoFocus
                        id="standard-basic"
                        placeholder='Search for experts name'
                        variant="standard"
                        style={{ width: '300px' }}
                        InputProps={{
                            startAdornment: (
                                <div style={{ display: 'flex', alignItems: 'center', marginLeft:7 }}>
                                    {loadingsearch ? (
                                    <InputAdornment position="end">
                                        <CircularProgress size={24} />
                                    </InputAdornment>
                                    ) : (
                                    <InputAdornment position="end">
                                        <SearchRoundedIcon />
                                    </InputAdornment>
                                    )}
                                </div>
                            ),
                            endAdornment: (
                                <InputAdornment position="end" style={{ cursor: "pointer" }}
                                    disabled={disableForRole}
                                    onClick={
                                        () => setSearch('')
                                    } >
                                    {/* <CloseIcon /> */}
                                </InputAdornment>
                            )
                        }}
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value);
                            setTableLoading(true);
                        }}
                    />
                </div>
                {/* give a space between the select and the table */}
                <br /><br />
                        <div>
                            <MaterialTable
                                title={null}
                                icons={tableIcons}
                                columns={columns}
                                isLoading={tableLoading}
                                data={arr}
                                options={{
                                    // pageSize: 20,
                                    toolbar: false,
                                    search: true,
                                    paging: false,
                                    maxBodyHeight: '700px',
                                    headerStyle: {
                                        backgroundColor: '#01579b',
                                        color: '#FFF',
                                        fontWeight: 100
                                    },

                                    // eslint-disable-next-line consistent-return
                                    rowStyle: (x) => {
                                        if (x.tableData.id % 2) {
                                            return { backgroundColor: '#eef9ff' };
                                        }
                                    }
                                }}
                            />
                        <div className='pagination-container' >
                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                <InputLabel id="demo-simple-select-label">Results per Page</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={size}
                                    label="Results per Page"
                                    onChange={(event) => { setSize(event.target.value) }}
                                >
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                </Select>
                            </FormControl>
                            &nbsp;&nbsp;
                            <Pagination
                                count={Math.ceil(total / size) || 1}
                                page={page}
                                onChange={handlePageChange}
                                variant="outlined"
                                shape="rounded"
                                size="small"
                            />
                        </div>
                        </div>
            </MainCard>
            <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={toastSeverity} sx={{ width: '100%' }}>
                    {toastMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default Dashboard;