import MainCard from 'ui-component/cards/MainCard';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import React from 'react';
import { Authenticated } from '../../../utils/checkerfunction';

const columns = [
    { field: 'id', headerName: 'ID', width: 80 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'org_name', headerName: 'Organization', width: 150 },
    { field: 'testname', headerName: 'Test Type', width: 200 },
    { field: 'receive_date', headerName: 'Test Date', width: 150 },
    { field: 'Schedule Date', headerName: 'Test Time', width: 200 },
    { field: 'user_address', headerName: 'Address', width: 300 }
    // { field: 'user_id', headerName: 'User Id', width: 300 }
];

const baseURL = `${process.env.REACT_APP_BASEURL}admin/user/getAllScheduledTestUserList`;

const ScheduledTest = () => {
    Authenticated();
    const [Data, setData] = React.useState(null);
    axios.defaults.headers.common = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    };
    React.useEffect(() => {
        axios.get(baseURL).then((response) => {
            setData(response.data.result);
        });
    }, []);

    return (
        <MainCard title="Scheduled Test">
            <div style={{ height: 700, width: '100%' }}>
                <DataGrid getRowId={(Data) => Data.id} rows={Data} columns={columns} />
            </div>
        </MainCard>
    );
};

export default ScheduledTest;
