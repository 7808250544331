import ReactDOM from 'react-dom';
import LogRocket from 'logrocket';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { store } from 'store';
import { UserData } from 'utils/userData';
import 'assets/scss/style.scss';

// ==============================|| REACT DOM RENDER  ||============================== //

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    // eslint-disable-next-line no-multi-assign
    console.log = console.warn = console.error = () => {};
    console.clear();
} else {
    console.log('%c Development Mode', 'background: #222; color: #bada55');
}
LogRocket.init('xg19le/unlockfit');
LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
    name: UserData().name,
    email: UserData().email,

    // Add your own custom user variables here, ie:
    role: UserData().roleName
});

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
