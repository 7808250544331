import styled from 'styled-components';

export const Header = styled.div`
    background: #FAFAFA;
    border: 1px solid #ADADAD;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    font-weight: 500;
    font-family: 'Poppins';
    color: #313131;
    padding: 15px;
    font-size: 20px;
`;
