import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import { Box, Button, TextField, Select, Link, Divider, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip, Modal, FormControl, FormLabel, FormControlLabel, Radio, RadioGroup, MenuItem, InputLabel, InputAdornment, Pagination, Input } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';
import EditIcon from '@mui/icons-material/Edit';
import MaterialTable from 'material-table';
import tableIcons from 'themes/MaterialTableIcons';
import axios from 'axios';
import exportFromJSON from 'export-from-json';
import { Header, Text } from './style';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import ReactLoading from 'react-loading';
import { WindowSharp } from '@mui/icons-material';
import './style.css';
import { fontSize } from '@mui/system';
import excell from "../../../assets/images/excell.svg";
import { makeStyles } from '@material-ui/core/styles';
import toast, { Toaster } from 'react-hot-toast';
import { UserData } from 'utils/userData';
import SearchInput from '@mui/material/OutlinedInput'
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '25px',
                paddingRight: "25px"
            },
            '&:hover fieldset': {
            },
            '&.Mui-focused fieldset': {
            },
        },
    },
}));

const Ingredients = () => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [allIngredients, setAllIngredients] = useState([]);
    const [ingredientToEdit, setIngredientToEdit] = useState(null);
    const [ingredientToDelete, setIngredientToDelete] = useState(null);
    const [exportLink, setExportLink] = useState(null);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(100);
    const [searchValue, setSearchValue] = useState('');
    const [loadingsearch, setLoadingsearch] = useState(false);
    const [exportStart, setExportStart] = useState(1);
    const [exportEnd, setExportEnd] = useState(1000);
    const [exportDialogOpen, setExportDialogOpen] = useState(false);
    const [failureMessage, setFailureMessage] = useState('');
    const [ingredientInActiveMessage, setIngredientInActiveMessage] = useState('')
    const finalPermission = UserData().permissions
    const importExport = finalPermission?.find(permission => permission.name === "Import/Export Ingredients");
    const ingredients = finalPermission?.find(permission => permission.name === "Ingredients Panel");

    const deleteIngredient = async () => {
        setLoading(true);
        setDialogOpen(false);
        try {
            const res = await axios.delete(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/ingredient/${ingredientToDelete}`)
            if (res.status === 200) {
                window.location.reload();
            } else {
                alert('Something went wrong');
                setLoading(false);
            }
        } catch (err) {
            console.log(err)
            alert(err.response.data.message)
            setLoading(false);
        }
    }

    const deleteDialogContent = {
        title: 'Delete Ingredient',
        content: 'Are you sure you want to delete this Ingredient?',
        action: 'Delete',
        color: 'error',
        function: deleteIngredient
    }

    const [dialogAction, setDialogAction] = useState(deleteDialogContent);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const buttonStyle = {
        color: 'white',
        marginRight: '0.5rem',
        fontSize: '0.9rem',
        padding: '5px 45px',
    }

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize)
    })

    // useEffect(async () => {
    //     const res = await axios.post(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/ingredient/export`)
    //     setExportLink(res.data.data[0]?.excel_url)
    // }, [])

    const handleCloseExportDialog = () => {
        setExportDialogOpen(false);
    }

    const exportDialog = () => (
        <Dialog
            open={exportDialogOpen}
            onClose={handleCloseExportDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div
                        style={{
                            fontSize: 15,
                            fontWeight: 500,
                            color: '#313131',
                            paddingLeft: 0,
                            paddingRight: 0
                        }}
                    >
                        <div
                            style={{
                                paddingLeft: 0,
                                paddingBottom: 20,
                                paddingTop: 0,
                                borderBottom: '1px solid #919191',
                                fontSize: 20,
                                marginBottom: 20,
                                color: '#313131'
                            }}
                        >
                            Export Ingredients
                        </div>
                        <div
                            style={{
                                paddingLeft: 0,
                                paddingBottom: 30,
                                paddingTop: 0,
                                fontSize: 18,
                                lineHeight: '30px',
                                marginBottom: 0,
                                color: '#616161'
                            }}
                        >
                            Enter the start and end index of the Ingredients you<br /> want to export out of the total {total} Ingredients.
                        </div>
                        <div
                            style={{
                                paddingLeft: 0,
                                paddingBottom: 15,
                                paddingTop: 0,
                                display: 'flex',
                                color: '#313131'
                            }}
                        >
                            <TextField
                                id="outlined-basic"
                                label="Start Index"
                                variant="outlined"
                                type="number"
                                style={{ width: '100%' }}
                                value={exportStart}
                                onChange={(e) => {
                                    setExportStart(e.target.value);
                                }}
                            />
                        </div>
                        <div
                            style={{
                                paddingLeft: 0,
                                paddingBottom: 15,
                                paddingTop: 0,
                                display: 'flex',
                                color: '#313131'
                            }}
                        >
                            <TextField
                                id="outlined-basic"
                                label="End Index"
                                variant="outlined"
                                type="number"
                                style={{ width: '100%' }}
                                value={exportEnd}
                                onChange={(e) => {
                                    setExportEnd(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    <p
                        style={{
                            fontSize: 14,
                            color: '#D32C2C',
                            textAlign: 'center',
                            padding: 0,
                            margin: 0
                        }}
                    >
                        {failureMessage}
                    </p>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        borderTop: '1px solid #919191',
                        paddingTop: 20,
                        paddingBottom: 10
                    }}
                >
                    <Button
                        onClick={() => handleCloseExportDialog()}
                        style={{
                            border: '1px solid #1B99DF',
                            background: '#FFFFFF',
                            borderRadius: 10,
                            color: '#1B99DF',
                            fontSize: 15,
                            fontWeight: 500,
                            padding: '6px 75px',
                            margin: '0 5px'
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => handleExportLink()}
                        style={{
                            border: '1px solid #1B99DF',
                            background: '#1B99DF',
                            borderRadius: 10,
                            color: '#ffffff',
                            fontSize: 15,
                            fontWeight: 500,
                            padding: '6px 75px',
                            margin: '0 5px'
                        }}
                    >
                        Confirm
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    )

    const handleExportLink = async (e) => {
        if (exportEnd === 0) alert('Please enter a valid number')
        else if (exportEnd > total || exportStart > total) setFailureMessage(`Please enter a number less than ${total}.`)
        else if (exportStart > exportEnd) setFailureMessage('Start index cannot be greater than end index.')
        else {
            setLoading(true);
            setExportDialogOpen(false);
            try {
                const res = await axios.post(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/ingredient/export?start=${exportStart}&end=${exportEnd}`)
                console.log(res, "res export Recipe");
                if (res !== "") {
                    setLoading(false);
                    const link = document.createElement("a");
                    link.download = res.data.data[0].excel_url;
                    link.href = res.data.data[0].excel_url;
                    link.target = "_blank";
                    link.click();
                }
            }
            catch (err) {
                setLoading(false);
                alert('Something went wrong. Please try again.')
            }

        }
    }




    useEffect(() => {
        if (searchValue.length > 1) {
            setPage(1);
        }
    }, [searchValue])

    let searchTimeout;

    const fetchData = () => {
      console.log("Searching for", searchValue);
      const encodedSearchValue = encodeURIComponent(searchValue);
  
    axios
        .get(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/ingredient?size=${limit}&page=${page}&search=${encodedSearchValue}`)
        .then((response) => {
            const data = response.data;
            if (data.result === 'success') setLoading(false);
            setTotal(data?.total_count);
            setIngredientInActiveMessage(data.inactive_message);
            setAllIngredients(data.data);
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
            console.error("Error fetching data:", error);
            setLoading(false);
        });
    };  

    useEffect(() => {
        if (page === 1 && searchValue === '') setLoading(true);
        setLoading(true);
            
            clearTimeout(searchTimeout);

        searchTimeout = setTimeout(() => {
            if(searchValue) fetchData();
        }, 2000);

            return () => {
            clearTimeout(searchTimeout);
        };
      }, [page, limit, searchValue]);

    useEffect(() => {
        if(!searchValue) fetchData();
    }, [page, limit, searchValue]);


    const handleClose = () => {
        setIngredientToDelete(null);
        setDialogOpen(false);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
        setLoading(true);
        // getData();
    };

    const renderDialog = () => (
        <Dialog
            open={dialogOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {dialogAction?.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {dialogAction?.content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={() => {
                    deleteIngredient();
                }} autoFocus color={dialogAction?.color}>
                    {dialogAction?.action}
                </Button>
            </DialogActions>
        </Dialog>
    )

    const arr = []
    allIngredients?.map((item, i) =>
        arr.push({
            sno: <span>{(limit * (page - 1)) + i + 1}</span>,
            name: (
                <div style={{ whiteSpace: 'pre-wrap' }}>
                  {item.is_active ? (
                    item.name
                  ) : (
                    <Tooltip title={ingredientInActiveMessage}>
                      <span style={{ color: '#D32C2C' }}>{item.name}</span>
                    </Tooltip>
                  )}
                </div>
            ),
            tags: item?.tags.map((tag, index) => tag?.name).join(', '),
            macro_nutrients:
            item.macro_nutrients.length === 0 ? (
              <Tooltip title={ingredientInActiveMessage}>
                <span style={item.is_active ? {} : { color: "#D32C2C" }}>
                Calories: 0 gm | Carbs: 0 gm | Protein: 0 gm | Fat: 0 gm
                </span>
              </Tooltip>
            ) : item.is_active ? (
              item.macro_nutrients.map((ele, index) => (
                <span>
                  {ele.name +
                    ": " +
                    ele.value +
                    " " +
                    (ele.unit === "g" ? "gm" : ele?.unit) +
                    (index !== item.macro_nutrients.length - 1 ? " | " : "")}
                </span>
              ))
            ) : (
              <Tooltip title={ingredientInActiveMessage}>
                <span style={{ color: "#D32C2C" }}>
                    {item.macro_nutrients.map((ele, index) => (
                    <span>
                      {ele.name +
                        ": " +
                        ele.value +
                        " " +
                        (ele.unit === "g" ? "gm" : ele?.unit) +
                        (index !== item.macro_nutrients.length - 1 ? " | " : "")}
                    </span>
                  ))}
                </span>
              </Tooltip>
            ),
            action: <div style={{ display: 'inline' }}>
                <Tooltip title="Edit Ingredient">
                    <EditIcon sx={{ cursor: ingredients?.can_create ? 'pointer' : 'default', color: ingredients?.can_create ? '#02a9f4' : '#AAA1A1' }}  onClick={() => {
                        if (ingredients?.can_create){
                        navigate('/nutrition/ingredients/add', { state: { item } })
                        // setDialogAction(editDialogContent)
                        }
                    }}
                    />
                </Tooltip>
                &nbsp;&nbsp;
                <Tooltip title="Delete Ingredient">
                    <DeleteIcon sx={{ cursor: ingredients?.can_create ? 'pointer' : 'default', color: ingredients?.can_create ? '#f44336' : '#AAA1A1' }} onClick={() => {
                        if (ingredients?.can_create){
                        setIngredientToDelete(item.id);
                        setDialogOpen(true);
                        }
                    }} />
                </Tooltip>
            </div>
        })
    )

    const handleFileUpload = async (e) => {
        setLoading(true);
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('files', file);
        const res = await axios.post(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/ingredient/import`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        setLoading(false);
        console.log(res, "res import Ing");

        if (res.data?.result === 'success') {
            window.location.reload();
            alert(res.data?.message)
        }
        else alert(res.data?.message)
    }
    const exporthandlerV1 = async () => {
        const starting = prompt("Enter start index (min 1)")
        const startIndex = parseInt(starting, 10);
        // setExportStart(startIndex);
        let endIndex = 100;
        if (startIndex <= 0) alert('Start Index cannot be less than or equal to 1')
        else if (startIndex > total) alert(`Start Index cannot be greater than ${total}`)
        else if (startIndex) {
            const ending = prompt(`Enter end index (max ${total})`)
            endIndex = parseInt(ending, 10);
            // setExportEnd(endIndex);
            console.log(startIndex, endIndex, "start end");
            if (endIndex <= 0) alert('End Index cannot be less than or equal to 1')
            else if (endIndex > total) alert(`End Index cannot be greater than ${total}`)
            else if (startIndex > endIndex) alert('Start index cannot be greater than end index.')
            else if (startIndex === endIndex) alert('Start index and end index cannot be same.')
            else if (startIndex && endIndex) {
                setLoading(true);
                try {
                    const res = await axios.post(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/ingredient/export?start=${startIndex}&end=${endIndex}`)
                    console.log(res, "res export Recipe");
                    if (res !== "") {
                        setLoading(false);
                        const link = document.createElement("a");
                        link.download = res.data.data[0].excel_url;
                        link.href = res.data.data[0].excel_url;
                        link.target = "_blank";
                        link.click();
                    }
                } catch (err) {
                    console.log(err, "err export Recipe");
                    alert('Something went wrong. Please try again later.')
                    setLoading(false);
                }
            }
        }
    }

    const classes = useStyles();

    return (
        <MainCard>
            <Header direction={windowWidth > 700 ? 'row' : 'column'}>
                <span className='header-heading' >Ingredients List</span>
                &nbsp;&nbsp;
                <div
                    className="header-buttons"
                    style={{
                        display: 'flex',
                        flexDirection: windowWidth > 700 ? 'row' : 'column',
                        justifyContent: 'space-between'
                    }}
                >
                    <Button
                        component="label"
                        size="small"
                        variant="contained"
                        disabled={!importExport?.can_create}
                        startIcon=<img src={excell} alt=" " />
                        sx={buttonStyle}
                      
                    >
                        Import
                        <input hidden type="file" accept=".xls, .xlsx" onChange={handleFileUpload} />
                    </Button>
                    &nbsp;
                    <Button
                        size="small"
                        variant="contained"
                        startIcon=<img src={excell} alt=" " />
                        sx={buttonStyle}
                        href={exportLink}
                        disabled={!importExport?.can_create}
                        // onClick={console.log("Downloading...")}
                        onClick={() => {
                            exporthandlerV1()
                        }}
                      
                    >
                        {/* <a href={exportLink} style={{textDecoration: 'none', color: 'white'}}> */}
                        Export
                        {/* </a> */}
                    </Button>
                    &nbsp;
                    <Button
                        size="small"
                        variant="contained"
                        startIcon={<AddIcon />}
                        sx={buttonStyle}
                        disabled={!ingredients?.can_create}
                        onClick={() => { navigate('/nutrition/ingredients/add') }}
                      //  disabled={UserData().roleName !== "SuperAdmin"}
                    >
                        New Ingredient
                    </Button>
                </div>
            </Header>
            {renderDialog()}
            &nbsp;&nbsp;
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "10px 10px",
                        }}
                    >
                        <span className='table-heading' >
                            List of Ingredients
                        </span>
                        <div className='search-bar' >
                            <SearchInput
                                autoComplete="off"
                                key="search"
                                autoFocus
                                className={classes.root}
                                id="standard-basic"
                                placeholder='Search for Ingredient'
                                variant="outlined"
                                inputProps={{ style: { borderRadius: "0px" } }}
                                value={searchValue}
                                style={{
                                    backgroundColor:'#02a9f4',
                                    color:'#ffff',
                                    borderRadius:9
                                }}
                                onChange={(e) => {
                                    setSearchValue(e.target.value);
                                }}
                                endAdornment={
                                    <div style={{ display: 'flex', alignItems: 'center', marginLeft:7 }}>
                                            <SearchRoundedIcon />
                                            <span>Search</span>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                    <MaterialTable
                        isLoading={loading}
                        className='ingredients-table'
                        title=""
                        icons={tableIcons}
                        columns={[
                            { title: 'S.No', field: 'sno', headerStyle: {width:'15px !important'}, cellStyle:{width:'15px'}},
                            { title: 'Name', field: 'name' },
                            { title: 'Macro-Nutrients', field: 'macro_nutrients', headerStyle: { width: '43%' }, cellStyle: { width: "43%" }, },
                            { title: 'Tags', field: 'tags' },
                            { title: 'Action', field: 'action', headerStyle: { borderRadius: '0 10px 0 0'}, cellStyle:{width:'30px'}, sorting: false }
                        ]}
                        data={arr}
                        options={{
                            toolbar: false,
                            search: false,
                            paging: false,
                            // pageSize: 5,
                            maxBodyHeight: '700px',
                            border: '1px solid #adadad',
                            cellStyle: {
                                border: '1px solid #adadad',
                                // overflow: 'hidden',
                            },
                            headerStyle: {
                                backgroundColor: '#EDEDED',
                                color: '#616161',
                                fontWeight: 100,
                                border: '1px solid #adadad',
                            },
                            rowStyle: (x) => {
                                if (x.tableData.id % 2) {
                                    return { backgroundColor: '#EDEDED', border: '1px solid #adadad' };
                                }
                                return { backgroundColor: '#FFFFFF', border: '1px solid #adadad' };
                            }
                        }}
                        style={{
                            border: '1px solid #adadad',
                            padding: '10px 10px',
                        }}
                    />
                    <div
                        width="100%"
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '1rem'
                        }}
                    >
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="demo-simple-select-label">Results per Page</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={limit}
                            label="Results per Page"
                            onChange={(event) => { setLimit(event.target.value) }}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                    </FormControl>
                    <Pagination
                        count={Math.ceil(total / limit) || 10}
                        page={page}
                        onChange={handlePageChange}
                        variant="outlined"
                        shape="rounded"
                        size="small"
                    />
                    </div>



            {exportDialog()}
        </MainCard>
    )
}

export default Ingredients;