import { UserData } from 'utils/userData';
import dashboard from './dashboard';
import settings from './setting';
import utilities from './utilities';

const role = UserData().role;

// ==============================|| MENU ITEMS ||============================== //

const items = (role) => {
    // if (role === '1') {
    //     return [dashboard, settings];
    // }
    return [dashboard, settings].filter((item) => item !== null);;
};

const menuItems = {
    items: items(role)
};

export default menuItems;
