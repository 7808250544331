import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import config from 'config';

// ==============================|| ROUTING RENDER ||============================== //

const urlParams = new URLSearchParams(window.location.search);
const isUserInParams = urlParams.get('user') !== null;

const isAuthenticated = isUserInParams ? false : localStorage.getItem('token') !== null;

export default function ThemeRoutes() {
    if (isAuthenticated) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useRoutes([MainRoutes], config.basename);
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useRoutes([AuthenticationRoutes], config.basename);
}
