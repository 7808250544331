import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import MaterialTable from 'material-table';
import tableIcons from 'themes/MaterialTableIcons';
import { Button, Input } from '@mui/material';
import axios from 'axios';
import { Authenticated } from '../../../utils/checkerfunction';
import { useNavigate } from 'react-router-dom';
import { UserData } from 'utils/userData';

const RejectedVideo = () => {
    Authenticated();

    const navigate = useNavigate();

    const columns = [
        { title: 'S.No', field: 'id' },
        { title: 'Name', field: 'username' },
        { title: 'Organization', field: 'org_name' },
        { title: 'Day', field: 'day_id' },
        { title: 'Activity', field: 'activity_name' },
        { title: 'Level', field: 'levels' },
        { title: 'Category', field: 'category' },
        { title: 'Comment', field: 'comments' },
        { title: 'Review', field: 'uploaded_video_url' }
    ];

    const [Data, setData] = React.useState(null);

    const baseURL = `${process.env.REACT_APP_BASEURL}admin/user/getUserVideoList?type=rejected`;
    axios.defaults.headers.common = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    };

    const array = [];
    const getData = async () => {
        const response = await axios.get(baseURL);
        response.data.data.forEach((element) => {
            array.push(element);
        });
        setData(array);
    };

    React.useEffect(() => {
        Authenticated();
        getData();
    }, []);

    const arr = [];

    // eslint-disable-next-line array-callback-return
    Data?.map((item, i) => {
        if (item.is_approved === 2) {
            arr.push({
                id: i + 1,
                username: item.username,
                day_id: item.day_id,
                org_name: item.org_name,
                activity_name: item.activity_name,
                levels: item.levels,
                category: item.category,
                comments: item.comments,
                uploaded_video_url: item.uploaded_video_url ? (
                    <Button
                        onClick={() =>
                            navigate(
                                `/assessment/view/?name=${item.username}&uservideo=${item.uploaded_video_url}&trainervideo=${item.trainervideo}&activityname=${item.activity_name}&level=${item.levels}&category=${item.category}&day=${item.day_id}&orgname=${item.org_name}`
                            )
                        }
                    >
                        View
                    </Button>
                ) : (
                    'No'
                )
            });
        }
    });

    return (
        <MainCard>
            <div>
                <MaterialTable
                    title={null}
                    icons={tableIcons}
                    columns={columns}
                    data={arr}
                    options={{
                        // pageSize: 20,
                        paging: false,
                        maxBodyHeight: '700px',
                        headerStyle: {
                            backgroundColor: '#01579b',
                            color: '#FFF',
                            fontWeight: 100
                        },

                        // eslint-disable-next-line consistent-return
                        rowStyle: (x) => {
                            if (x.tableData.id % 2) {
                                return { backgroundColor: '#eef9ff' };
                            }
                        }
                    }}
                />
            </div>
        </MainCard>
    );
};

export default RejectedVideo;
