import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import MaterialTable from 'material-table';
import tableIcons from 'themes/MaterialTableIcons';
import axios from 'axios';
import { Authenticated } from '../../../utils/checkerfunction';

const ViewOrganiation = () => {
    const columns = [
        { title: 'S.No', field: 'sno' },
        { title: 'Organazation', field: 'org_name' },
        { title: 'Added On', field: 'created_at' },
        { title: 'Domain', field: 'website' },
        { title: 'WhiteList', field: 'auth_domain' }
    ];

    const [Data, setData] = React.useState(null);

    const baseURL = `${process.env.REACT_APP_BASEURL}admin/user/getOrgList/`;

    axios.defaults.headers.common = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    };

    const array = [];
    const getData = async () => {
        const response = await axios.get(baseURL);

        response.data.data.forEach((element) => {
            array.push(element);
        });
        setData(array);
        // console.table(array);
    };

    React.useEffect(() => {
        Authenticated();
        getData();
    }, []);

    const arr = [];

    console.log('hello', Data);
    // eslint-disable-next-line array-callback-return
    Data?.map((item, i) => {
        if (item.is_approved !== 1 && item.is_approved !== 2) {
            arr.push({
                sno: i + 1,
                org_name: item.org_name,
                created_at: item.created_at,
                website: item.website,
                auth_domain: item.auth_domain
            });
        }
    });

    return (
        <MainCard>
            <div>
                <MaterialTable
                    title={null}
                    icons={tableIcons}
                    columns={columns}
                    data={arr}
                    options={{
                        // pageSize: 20,
                        paging: false,
                        maxBodyHeight: '700px',
                        headerStyle: {
                            backgroundColor: '#01579b',
                            color: '#FFF',
                            fontWeight: 100
                        },

                        // eslint-disable-next-line consistent-return
                        rowStyle: (x) => {
                            if (x.tableData.id % 2) {
                                return { backgroundColor: '#eef9ff' };
                            }
                        }
                    }}
                />
            </div>
        </MainCard>
    );
};

export default ViewOrganiation;
