/* eslint-disable jsx-a11y/label-has-associated-control */
import { TextField, Button, TextareaAutosize, FormLabel } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import MainCard from 'ui-component/cards/MainCard';
import { Authenticated } from '../../../utils/checkerfunction';

import styled from 'styled-components';
import axios from 'axios';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// ============================|| StyleZone ||============================ //

const Formdiv = styled.div``;

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

const AddOrganization = () => {
    Authenticated();
    // ============================|| Hooks ||============================ //
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    console.log(errors);

    // ============================|| Functions ||============================ //
    axios.defaults.headers.common = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    };

    const onSubmit = (data) => {
        axios.post(`${process.env.REACT_APP_BASEURL}admin/user/addOrganization`, data).then((response) => {
            console.log(response);
            handleClick();
            setTimeout(() => {
                navigate('/organization/view');
            }, 1100);
        });
    };

    return (
        <MainCard>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Organization Added Successfully
                </Alert>
            </Snackbar>
            <div style={{ width: '100%' }}>
                <Formdiv style={{ width: '50%', padding: '40px' }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <label>Name :</label>
                        <br />
                        <br />
                        <TextField
                            id="outlined-basic"
                            label="Organization Name"
                            variant="outlined"
                            {...register('org_name', { required: true, maxLength: 80 })}
                        />{' '}
                        <span />
                        <br />
                        <br />
                        <label>Website</label>
                        <br />
                        <br />
                        <TextField
                            id="outlined-basic"
                            label="Organization website"
                            variant="outlined"
                            {...register('website', { required: true, maxLength: 80 })}
                        />{' '}
                        <span />
                        <br />
                        <br />
                        <FormLabel id="demo-row-radio-buttons-group-label">Whitelist domains :</FormLabel>
                        <br />
                        <br />
                        <TextareaAutosize
                            aria-label="empty textarea"
                            placeholder="Domain"
                            style={{ width: '60%', minHeight: '20%' }}
                            {...register('auth_domain', { required: true, maxLength: 160 })}
                        />
                        <br />
                        <br />
                        <Button variant="contained" type="submit">
                            Save
                        </Button>
                    </form>
                </Formdiv>
            </div>
        </MainCard>
    );
};

export default AddOrganization;
