import { useState, useEffect, forwardRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import {
    Box, Button, Checkbox, TextField, Link, Divider, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip, Modal, FormControl, FormLabel, FormControlLabel, Pagination, Stack, Grid, MenuItem,
    Select,
    InputLabel, IconButton, Autocomplete, Chip
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AddIcon from '@mui/icons-material/Add';
import { common } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MaterialTable from 'material-table';
import tableIcons from 'themes/MaterialTableIcons';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Header, GrayBox, MidWhiteBox } from './style';
import ReactLoading from 'react-loading';
import InputAdornment from '@mui/material/InputAdornment';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import axios from 'axios';
import { CurrencyYenSharp } from '@mui/icons-material';
import "./style.css"
import { makeStyles } from '@material-ui/core/styles';
import { margin, maxHeight } from '@mui/system';
import { Text } from '../style';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import CreateIcon from '@mui/icons-material/Create';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const units = [
    {
        "display_value": "Mililitre",
        "map_value": "ml"
    },
    {
        "display_value": "Litre",
        "map_value": "l"
    },
    {
        "display_value": "Miligram",
        "map_value": "mg"
    },
    {
        "display_value": "Gram",
        "map_value": "g"
    },
    {
        "display_value": "Kilogram",
        "map_value": "kg"
    },
    {
        "display_value": "Inch",
        "map_value": "inch"
    }

]

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '25px',
                paddingRight: "25px"
            },
            '&:hover fieldset': {
            },
            '&.Mui-focused fieldset': {
            },
        },
    },
}));

// const units = [
//     "gm", "ml", "inches", "piece"
// ]
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AddNewIngredient = () => {
    const classes = useStyles();

    const location = useLocation();

    console.log(location, "location 2333333333333333");

    const dummyMeasurement = {
        name: "",
        value: "",
        unit: "",
    }

    const [isEdit, setIsEdit] = useState(
        location.state ? true : false
    );
    const navigate = useNavigate();
    const [ingName, setIngName] = useState(
        isEdit && location.state ? location.state.item.name : ''
    );

    const [customTagInput, setCustomTagInput] = useState('');
    const [customTags, setCustomTags] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [tagSuggestions, setTagSuggestions] = useState([]);
    const [tagsearch, settagsearch]=useState('');
    const [tags, setTags] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [inputValue, setInputValue] = useState('');
    const [tagInput, setTagInput] = useState('');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [allMacrosCount, setAllMacrosCount] = useState(0);
    const [page, setPage] = useState(1);
    const [allMacros, setAllMacros] = useState([]);
    const [search, setSearch] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [uneditedMacroNutrients, setUneditedMacroNutrients] = useState([]);
    const [allMacrosNutrients, setAllMacrosNutrients] = useState([]);
    const [isAddingNewMeasurement, setIsAddingNewMeasurement] = useState(false);
    const [customMeasurementName, setCustomMeasurementName] = useState('');
    const [customMeasurementValue, setCustomMeasurementValue] = useState('');
    const [customMeasurementUnit, setCustomMeasurementUnit] = useState('gm');
    const [customMeasurementIsMultipliable, setCustomMeasurementIsMultipliable] = useState(false);
    const [trigger, setTrigger] = useState(1);
    const [measurements, setMeasurements] = useState([]);
    const [openCustomMeasurementModal, setOpenCustomMeasurementModal] = useState(false);
    const [indexToEdit, setindexToEdit] = useState();
    const [ingredientMeasurement, setIngredientMeasurement] = useState([]);
    const [newMeasurement, setNewMeasurement] = useState(dummyMeasurement);
    const [measurementEditIndex, setMeasurementEditIndex] = useState(-1);
    const [hasMarcos, setHasMarcos] = useState(true);
    // get name and id from query string
    const location1 = useLocation();
    const searchParams = new URLSearchParams(location1.search);
    const name = searchParams.get('name');
    const id = searchParams.get('id');
    const [CustomIngridientForNew, setCustomIngridientForNew] = useState({ name: '', value: 0, unit: '' });

    const [IngridientForNew, setIngridientForNew] = useState([
        { id: 1, name: '', value: 0, unit: '' },
    ]);

    const [open, setOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState('')
    const Alert = forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const [tagsArrayObj, setTagsArrayObj] = useState([]);
    const [finalTags, setFinalTags] = useState([]);

    const handleIngredientMeasurementAddition = () => {
        // Get the last id in the current recipeMeasurementList
        const lastId = IngridientForNew[IngridientForNew.length - 1].id;
        const newMeasurement = {
            id: lastId + 1,
            name: null,
            value: 0,
            unit: '',
        };

        setIngridientForNew((prevList) => [...prevList, newMeasurement]);
    };

    const handleRemoveRow = (idToRemove) => {
        const updatedList = IngridientForNew.filter((item) => item.id !== idToRemove);
        setIngridientForNew(updatedList);
    };


    const mandatoryMacros = [
        {
            name: 'Calories',
            id: 9,
            value: 0,
            unit: 'kcal',
        },
        {
            name: 'Carbs',
            id: 8,
            value: 0,
            unit: 'g'
        },
        {
            name: 'Protein',
            id: 2,
            value: 0,
            unit: 'g'
        },
        {
            name: 'Fat',
            id: 4,
            value: 0,
            unit: 'g'
        }
    ]



    // console.log(location.state.item.id, "location.state.item.id 233333333");

    useEffect(() => {
        if (location?.state?.item) {
            console.log(location.state.item, "state")
            axios.get(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/ingredient/${location.state.item.id}`)
                .then(res => {
                    console.log(res.data.data, "Get One ingredient data");
                    if (res.data.data.length > 0) {
                        setTagsArrayObj(res.data.data[0].tags);
                        setHasMarcos(res.data.data[0].has_macros)
                        // setNewMacroNutrientsList(res.data.data[0].macro_nutrients);
                        if (res.data.data[0].macro_nutrients.length === 0) {
                            setMacroNutrients(mandatoryMacros);
                        } else {
                            setMacroNutrients(res.data.data[0].macro_nutrients);
                        }
                        setTags(res.data.data[0].tags.map(tag => tag.name).join(','));
                    }
                }).catch(err => {
                    console.log("error in ingredient", err);
                })
            axios.get(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/macros?size=10&page=1&search=`).then(res => {
                setAllMacrosCount(res.data.total_count);
            })
            axios.get(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/ingredient/measurement/${location.state.item.id}`).then(res => {
                setIngredientMeasurement(res.data.data);
            })
        }
        if (name && id) {
            axios.get(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/ingredient/${id}`)
                .then(res => {
                    console.log(res.data.data, "Get One ingredient data");
                    if (res.data.data.length > 0) {
                        setIngName(res.data.data[0].name);
                        // setNewMacroNutrientsList(res.data.data[0].macro_nutrients);
                        if (res.data.data[0].macro_nutrients.length === 0) {
                            setMacroNutrients(mandatoryMacros);
                        } else {
                            setMacroNutrients(res.data.data[0].macro_nutrients);
                        }
                        setTags(res.data.data[0].tags.map(tag => tag.name).join(','));
                    }
                }).catch(err => {
                    console.log("error in ingredient", err);
                })
            axios.get(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/macros?size=10&page=1&search=`).then(res => {
                setAllMacrosCount(res.data.total_count);
            })
            axios.get(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/ingredient/measurement/${id}`).then(res => {
                setIngredientMeasurement(res.data.data);
            })
        }
        console.log(name, id, "name,id");
    }, [])




    // console.log(location.state, "location.state 23333333333333");

    const [macroNutrients, setMacroNutrients] = useState(mandatoryMacros);

    // useEffect(() => {
    //     axios.get(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/macros?size=${allMacrosCount}&page=1&search=1`).then(res => {
    //         setAllMacros(res.data.data);
    //         const macroIds = [];
    //         macroNutrients?.forEach(macro => {
    //             macroIds.push(macro.id);
    //         })
    //         const carbId = allMacros.find(macro => macro.name === 'Carbohydrate')?.id;
    //         const fatId = allMacros.find(macro => macro.name === 'Fat')?.id;
    //         const proteinId = allMacros.find(macro => macro.name === 'Protein')?.id;
    //         const caloriesId = allMacros.find(macro => macro.name === 'Calories')?.id;
    //         if (!macroIds.includes(carbId)) {
    //             setMacroNutrients(prev => {
    //                 const temp = [...prev];
    //                 temp.push({
    //                     id: carbId,
    //                     name: 'Carbohydrate',
    //                     value: 0,
    //                     unit: 'g',
    //                 })
    //                 return temp;
    //             })
    //         }
    //         if (!macroIds.includes(fatId)) {
    //             setMacroNutrients(prev => {
    //                 const temp = [...prev];
    //                 temp.push({
    //                     id: fatId,
    //                     name: 'Fat',
    //                     value: 0,
    //                     unit: 'g',
    //                 })
    //                 return temp;
    //             }
    //             )
    //         }
    //         if (!macroIds.includes(proteinId)) {
    //             setMacroNutrients(prev => {
    //                 const temp = [...prev];
    //                 temp.push({
    //                     id: proteinId,
    //                     name: 'Protein',
    //                     value: 0,
    //                     unit: 'g',
    //                 })
    //                 return temp;
    //             }
    //             )
    //         }
    //         if (!macroIds.includes(caloriesId)) {
    //             setMacroNutrients(prev => {
    //                 const temp = [...prev];
    //                 temp.push({
    //                     id: caloriesId,
    //                     name: 'Calories',
    //                     value: 0,
    //                     unit: 'kcal',
    //                 })
    //                 return temp;
    //             }
    //             )
    //         }
    //     })
    //     setTempMacros(macroNutrients);
    // }, [allMacrosCount])

    const addIngredientMeasurement = () => {
        setIsAddingNewMeasurement(true);
    }

    useEffect(() => {
        console.log(ingredientMeasurement, "ingredientMeasurement")
    }, [ingredientMeasurement])

    const handleCustomMeasurementModalClose = () => {
        setCustomMeasurementName('')
        setCustomMeasurementValue('')
        setCustomMeasurementUnit('gm')
        setCustomMeasurementIsMultipliable(false)
        setOpenCustomMeasurementModal(false);
    };

    const handleCustomMeasurementSave = async () => {
        if (Number(customMeasurementValue) <= 0) {
            alert("Value should be greater than 0")
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/units`, {
                name: customMeasurementName,
                value: Number(customMeasurementValue),
                unit: customMeasurementUnit,
                is_multiply: customMeasurementIsMultipliable
            })
            if (res.data.result === "success") {
                setOpenCustomMeasurementModal(false);
                setCustomMeasurementName('')
                setCustomMeasurementValue('')
                setCustomMeasurementUnit('gm')
                setCustomMeasurementIsMultipliable(false)
                setTrigger(trigger + 1)
                setMeasurements(res.data.data)
                setIngridientForNew((prevList) =>
                prevList.map((prevItem, index) =>
                    prevItem.id === indexToEdit ? { ...prevItem, name: res.data.data[0].name } : prevItem
                ));
                // alert(res.data.message)
                if (isAddingNewMeasurement) {
                    setNewMeasurement({
                        name: res.data.data[0].name,
                        value: Number(customMeasurementValue),
                        unit: customMeasurementUnit,
                    })
                } else {
                    const temp = [...ingredientMeasurement];
                    temp[measurementEditIndex].name = res.data.data[0].name;
                    temp[measurementEditIndex].value = Number(customMeasurementValue);
                    temp[measurementEditIndex].unit = customMeasurementUnit;
                }
                // handleCustomMeasurementModalClose();
                
            } else {
                alert(res.data.message)
            }
        } catch (err) {
            if (err) alert(err.response.data.message)
        }
    }

    const renderCustomMeasurementDialog = () => {
        console.log('customBaseNameno');


        return (
            <Dialog
                open={openCustomMeasurementModal}
                onClose={() => { setOpenCustomMeasurementModal(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <span
                            style={{
                                fontSize: 15,
                                fontWeight: 500,
                                color: '#313131',
                                paddingLeft: 0,
                                paddingRight: 0
                            }}
                        >
                            <div
                                style={{
                                    paddingLeft: 0,
                                    paddingBottom: 20,
                                    paddingTop: 0,
                                    borderBottom: '1px solid #919191',
                                    fontSize: 20,
                                    marginBottom: 20,
                                    color: '#313131'
                                }}
                            >
                                Custom Measurement
                            </div>
                            <div
                                style={{
                                    paddingLeft: 0,
                                    paddingBottom: 30,
                                    paddingTop: 0,
                                    fontSize: 18,
                                    lineHeight: '30px',
                                    marginBottom: 0,
                                    color: '#616161'
                                }}
                            >
                                Add details about the custom measurement.
                            </div>
                            <div
                                style={{
                                    paddingLeft: 0,
                                    paddingBottom: 15,
                                    paddingTop: 0,
                                    display: 'flex',
                                    color: '#313131',
                                }}
                            >
                                <Text
                                    style={{
                                        width: '50%',
                                        color: '#313131',
                                        fontSize: 18,
                                    }}
                                >
                                    Name
                                </Text>
                                <TextField
                                    variant="outlined"
                                    placeholder="Cup"
                                    fullWidth
                                    value={customMeasurementName}
                                    onChange={(e) => {
                                        setCustomMeasurementName(e.target.value);
                                        // setIngridientForNew((prevList) =>
                                        //     prevList.map((prevItem, index) =>
                                        //         prevItem.id === indexToEdit ? { ...prevItem, name: e.target.value } : prevItem
                                        //     ));
                                        setNewMeasurement({ ...newMeasurement, name: e.target.value });
                                    }
                                    }
                                />
                            </div>
                            <div
                                style={{
                                    paddingLeft: 0,
                                    paddingBottom: 15,
                                    paddingTop: 0,
                                    display: 'flex',
                                    color: '#313131'
                                }}
                            >
                                <Text
                                    style={{
                                        width: '50%',
                                        color: '#313131',
                                        fontSize: 18,
                                    }}
                                >
                                    <span>Value</span>
                                </Text>
                                <TextField
                                    type='number'
                                    variant="outlined"
                                    placeholder="250"
                                    fullWidth
                                    value={customMeasurementValue}
                                    onChange={(e) => {
                                        setCustomMeasurementValue(e.target.value);
                                        setIngridientForNew((prevList) =>
                                            prevList.map((prevItem, index) =>
                                                prevItem.id === indexToEdit ? { ...prevItem, value: e.target.value } : prevItem
                                            ));
                                        setNewMeasurement({ ...newMeasurement, value: e.target.value });
                                    }
                                    }
                                />
                            </div>
                            <div
                                style={{
                                    paddingLeft: 0,
                                    paddingBottom: 15,
                                    paddingTop: 0,
                                    display: 'flex',
                                    color: '#313131'
                                }}
                            >
                                <Text
                                    style={{
                                        width: '50%',
                                        color: '#313131',
                                        fontSize: 18,
                                    }}
                                >
                                    <span>Unit</span>
                                </Text>
                                {/* select optiom */}
                                <Select
                                    value={customMeasurementUnit}
                                    style={{ boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px" }}
                                    onChange={(e) => {
                                        setCustomMeasurementUnit(e.target.value);
                                        setIngridientForNew((prevList) =>
                                            prevList.map((prevItem, index) =>
                                                prevItem.id === indexToEdit ? { ...prevItem, unit: e.target.value } : prevItem
                                            ));
                                        setNewMeasurement({ ...newMeasurement, unit: e.target.value });
                                    }
                                    }
                                    fullWidth
                                >

                                    {
                                        units.map((item, index) => (
                                            <MenuItem value={item.map_value}>{item.display_value}</MenuItem>
                                        ))
                                    }
                                </Select>

                            </div>
                            <div
                                style={{
                                    paddingLeft: 0,
                                    paddingBottom: 15,
                                    paddingTop: 0,
                                    display: 'flex',
                                    color: '#313131'
                                }}
                            >
                                <Text
                                    style={{
                                        width: '70%',
                                        color: '#313131',
                                        fontSize: 18,
                                    }}
                                >
                                    <span>Is the unit multipliable ? </span>
                                </Text>
                                <div style={
                                    {
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'row'
                                    }
                                }>
                                    <Checkbox
                                        checked={customMeasurementIsMultipliable}
                                        onChange={(e) => {
                                            setCustomMeasurementIsMultipliable(e.target.checked);
                                        }
                                        }
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <Text>Yes</Text>
                                </div>

                            </div>
                        </span>
                        <p
                            style={{
                                fontSize: 14,
                                color: '#D32C2C',
                                textAlign: 'center',
                                padding: 0,
                                margin: 0
                            }}
                        >
                            {/* {failureMessage} */}
                        </p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            borderTop: '1px solid #919191',
                            paddingTop: 20,
                            paddingBottom: 10
                        }}
                    >
                        <Button
                            onClick={handleCustomMeasurementModalClose}
                            style={{
                                border: '1px solid #1B99DF',
                                background: '#FFFFFF',
                                borderRadius: 10,
                                color: '#1B99DF',
                                fontSize: 15,
                                fontWeight: 500,
                                padding: '6px 75px',
                                margin: '0 5px'
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                console.log('confirm clicked');
                                handleCustomMeasurementSave();
                            }}
                            style={{
                                border: '1px solid #1B99DF',
                                background: '#1B99DF',
                                borderRadius: 10,
                                color: '#ffffff',
                                fontSize: 15,
                                fontWeight: 500,
                                padding: '6px 75px',
                                margin: '0 5px'
                            }}
                        >
                            Confirm
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        )
    };

    useEffect(() => {
        const getMeasurements = async () => {
            const res = await axios.get(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/v1/units`);
            setMeasurements(res.data.data);
            console.log("measurements", res.data.data)
        }
        getMeasurements();
    }, []);

    // useEffect(() => {
    //     if(measurements.length > 0 || ingredientMeasurement > 0) {
    //         const newIngredientMeasurement = [];
    //         ingredientMeasurement.forEach((measurement) => {
    //             newIngredientMeasurement.push({
    //                 id: measurement.id,
    //                 name: measurement.name,
    //                 value: measurement.value,
    //                 unit: measurement.unit,
    //                 serving : calculateServings(measurement.name,measurement.value,)
    //             })
    //         });
    //         setIngredientMeasurement(newIngredientMeasurement);
    //     }
    // }, [measurements, ingredientMeasurement]);

    const [tempMacros, setTempMacros] = useState(macroNutrients);

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize)
    })

    useEffect(() => {
        setTempMacros(macroNutrients);
    }, [macroNutrients])



    useEffect(() => {
        if (modalOpen) {
            if (search.length > 1){
              setPage(1);

            } 
             setLoading(true);
            axios.get(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/macros?size=20&page=${page}&search=${search}`)
                .then(res => {
                    setAllMacrosCount(res.data.total_count);
                    setAllMacros(res.data.data);
                    setLoading(false);
                })
        }
    }, [modalOpen, page, search])

    const handleClose = () => {
        if (macroNutrients.length !== tempMacros.length) {
            alert("Please save the changes");
        } else {
            setModalOpen(false);
            setSearch('');
        }
    };

    useEffect(() => {
        console.log("modal changed")
    }, [modalOpen])

    const modalBoxStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: windowWidth > 600 ? '60vw' : '80vw',
        maxHeight: '80vh',
        bgcolor: 'background.paper',
        borderCollapse: "separate",
        borderRadius: "0.7rem",
        boxShadow: 24,
        p: 4,
        overflow: 'auto'
    };

    const blackCheckboxStyle = {
        color: common.black,
        '&.Mui-checked': {
            color: common.black,
        }
    }

    const blueCheckboxStyle = {
        color: common.blue,
        '&.Mui-checked': {
            color: common.blue,
        }
    }

    const handlePageChange = (event, value) => {
        setPage(value);
    }
     
    const showPagination = search.length < 3 || allMacrosCount <= 20;
    console.log('allMacrosCount:', allMacrosCount);
    const renderModal = () => {

        console.log("Modal Rendered")
        return (
            <Modal
                open={modalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalBoxStyle}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginBottom: '10px',
                            alignItems: 'center'
                        }}
                    >
                        <span className='modal-heading' >Select macro-nutrients</span>
                        {/* Add Search Box */}
                        <div className='search-bar' >
                            <TextField
                                autoComplete="off"
                                key="search"
                                autoFocus="autoFocus"
                                className={classes.root}
                                id="standard-basic"
                                placeholder='Search'
                                variant="outlined"
                                inputProps={{ style: { borderRadius: "50px" } }}
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                }}
                            />
                            <div className='search-icon-container' >
                                <SearchRoundedIcon />
                                <span>Search</span>
                            </div>
                        </div>
                    </div>
                    <Divider variant="middle" />
                    {
                        loading ?
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <ReactLoading type='spin' color='#000' height='5%' width='5%' sx={{ alignSelf: 'center' }} />
                            </div> :
                            allMacros.length > 0 ? (
                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                    {allMacros.map((macro, index) => (
                                        <Grid item xs={2} sm={4} md={3} key={index} style={{ color: '#313131' }} >
                                            <FormControlLabel
                                                control={<Checkbox
                                                    disabled={mandatoryMacros.some((mandatoryMacro) => mandatoryMacro.name === macro.name)}
                                                    checked={
                                                        tempMacros.some((tempMacro) => tempMacro.name === macro.name)
                                                    }
                                                    sx={blueCheckboxStyle}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setTempMacros([...tempMacros, { ...macro, value: 0 }]);
                                                            // setMacroNutrients([...macroNutrients, { ...macro, value: 0 }])
                                                        } else {
                                                            setTempMacros(tempMacros.filter(tempMacro => tempMacro.name !== macro.name));
                                                            // setMacroNutrients(macroNutrients.filter((m) => m.id !== macro.id))
                                                        }
                                                    }} />}
                                                label={macro.name}
                                            // labelPlacement = "start"
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            ) : (<h2>No Macros Found</h2>)
                    }{
                        allMacrosCount > 20 ? (
                            <div
                                width="100%"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '1rem'
                                }}
                            > 
                            {showPagination &&(
                                <Pagination
                                    count={Math.ceil(allMacrosCount / 20)}
                                    page={page}
                                    onChange={handlePageChange}
                                    variant="outlined"
                                    shape="rounded"
                                    size="small"
                                />
                            )}
                            </div>
                        ) : (null)
                    }
                    <Divider />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '1vw'
                        }}
                    >
                        <Button
                            size="small"
                            variant="outlined"
                            sx={{
                                fontSize: '1rem',
                            }}
                            onClick={() => {
                                setTempMacros(macroNutrients);
                                setModalOpen(false);
                                setSearch('');
                            }}
                        >
                            Cancel
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button
                            size='small'
                            color='primary'
                            variant='contained'
                            sx={{
                                color: 'white',
                                fontSize: '1rem',
                            }}
                            onClick={() => {
                                // setUneditedMacroNutrients(tempMacros);
                                setMacroNutrients(tempMacros);
                                setModalOpen(false);
                                setSearch('');
                            }}
                        >
                            {isEdit ? 'Update' : 'Add'}
                        </Button>
                    </div>
                </Box>
            </Modal>
        )
    }

    const checkEmptyMacros = () => {
        let flag = true;
        macroNutrients.forEach(macro => {
            if (macro.value !== "0") flag = false;
        })
        return flag;
    }

    const checkIfAllSelectedIngredientsHaveInfo = () => {
        let result = true;
        IngridientForNew.forEach((newMeasure) => {
            if (!newMeasure.name || !newMeasure.value || !newMeasure.unit) {
                result = false;
            }
        })
        return result;
    };

    const addIngredient = async () => {
        if (ingName === '') alert('Please enter ingredient name');
        else if (checkEmptyMacros()) alert('Please enter values in macro-nutrients');
        else if (!isEdit && !checkIfAllSelectedIngredientsHaveInfo()) alert('Please Select Measurement')
        else if (defaultTags === '') alert('Please enter tags');
        else {
            let tagsArray = [];
            if (isEdit) {
                tagsArray = defaultTags.map(tag => ({
                    "name": tag
                }));
            }
            else {
                tagsArray = defaultTags;
            }

            const data = {
                name: ingName,
                has_macros: hasMarcos,
                description: '',
                tags: tagsArray,
                measurement: IngridientForNew.map((measurement) => {
                    console.log(measurement);
                    return {
                        name: measurement.name,
                        value: measurement.value,
                        unit: measurement.unit,
                    }
                }),
                macro_nutrients: isEdit ? macroNutrients.map(macro => ({
                    name: macro.name,
                    value: macro.value

                })) : macroNutrients
            }
            const url = isEdit ? `${process.env.REACT_APP_BASEURL}admin/nutrition_plans/ingredient/${location.state.item.id}` : `${process.env.REACT_APP_BASEURL}admin/nutrition_plans/ingredient`;
            try {
                const res = isEdit ? await axios.patch(url, data) : await axios.post(url, data);
                console.log(res, "sdsdsdsdsd 111111111111111111111111");
                if (res.status === 200) {
                    console.log(res)
                    // alert(res.data.message);
                    // navigate(-1);
                    setToastMessage(res.data.message)
                    setOpen(true);
                } else {
                    alert('Something went wrong');
                }
            }
            catch (err) {
                alert(err.response.data.message)
            }
        }
    }

    const getUnit = (name) => {
        let unit = "gm";
        measurements.forEach(measurement => {
            if (measurement.name === name) unit = measurement.unit;
        })
        return unit;
    }

    const getValue = (name) => {
        let value = 0;
        measurements.forEach(measurement => {
            if (measurement.name === name) value = measurement.value;
        })
        return value;
    }

    const handleMeasurementChange = (event, index) => {
        if (event.target.value === "Custom Value") {
            setOpenCustomMeasurementModal(true);
        } else {
            const newIngredientMeasurement = [...ingredientMeasurement];
            newIngredientMeasurement[index].name = event.target.value;
            newIngredientMeasurement[index].unit = getUnit(event.target.value);
            setIngredientMeasurement(newIngredientMeasurement);
            setTrigger(trigger + 1);
        }
    }

    const handleMeasurementValueChange = (event, index) => {
        const newIngredientMeasurement = [...ingredientMeasurement];
        newIngredientMeasurement[index].value = event.target.value;
        setIngredientMeasurement(newIngredientMeasurement);
        setTrigger(trigger + 1);
    }

    const handleMeasurementUnitChange = (event, index) => {
        const newIngredientMeasurement = [...ingredientMeasurement];
        newIngredientMeasurement[index].unit = event.target.value;
        setIngredientMeasurement(newIngredientMeasurement);
        setTrigger(trigger + 1);
    }

    const handleEditUpdate = (index) => {
        if (measurementEditIndex !== index) {
            setMeasurementEditIndex(index);
        } else {
            updateIngredientMeasurement(index);
        }
    }

    const addNewIngredientMeasurement = async () => {
        if (Number(newMeasurement.value) <= 0) {
            alert("Measurement value cannot be 0 or less than 0");
            return;
            // eslint-disable-next-line no-else-return
        }
        const body = {
            ingredient_id: location.state.item.id,
            name: newMeasurement.name,
            value: Number(newMeasurement.value),
            unit: newMeasurement.unit
        }
        axios.post(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/ingredient/measurement`, body).then(res => {
            if (res.data.result === "success") {
                setIngredientMeasurement(res.data.data)
                setIsAddingNewMeasurement(false);
                setTrigger(trigger + 1);
                setNewMeasurement(dummyMeasurement)
            } else {
                alert(res.data.message);
            }
        }
        ).catch(err => {
            console.log(err, "Error in adding ingredient measurement");
            alert(err.response.data.message);
        }
        )
    }

    const updateIngredientMeasurement = async (index) => {
        if (Number(ingredientMeasurement[index].value) <= 0) {
            alert("Measurement value cannot be 0 or less than 0");
            return;
            // eslint-disable-next-line no-else-return
        }
        try {
            const body = {
                name: ingredientMeasurement[index].name,
                value: Number(ingredientMeasurement[index].value),
                unit: ingredientMeasurement[index].unit
            }
            const res = await axios.patch(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/ingredient/measurement/${ingredientMeasurement[index].id}`, body);
            if (res.data.result === "success") {
                setIngredientMeasurement(res.data.data)
                setMeasurementEditIndex(-1);
                setTrigger(trigger + 1);
            } else {
                alert(res.data.message);
            }
        }
        catch (err) {
            console.log(err, "Error in updating ingredient measurement");
            alert(err.response.data.message);
        }
    }

    const deleteIngredientMeasurement = async (index) => {
        try {
            const res = await axios.delete(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/ingredient/measurement/${ingredientMeasurement[index].id}`);
            if (res.data.result === "success") {
                setIngredientMeasurement(res.data.data)
                setMeasurementEditIndex(-1);
                setTrigger(trigger + 1);
            } else {
                alert(res.data.message);
            }
        }
        catch (err) {
            console.log(err, "Error in deleting ingredient measurement");
            alert(err.response.data.message);
        }
    }

    const handleMeasurementChangeForNew = (event, index) => {
        if (event.target.value === "Custom Value") {
            setOpenCustomMeasurementModal(true);
        } else {
            setIngridientForNew((prevList) =>
                prevList.map((prevItem) =>
                    prevItem.id === index ? { ...prevItem, name: event.target.value, value: getValue(event.target.value), unit: getUnit(event.target.value) } : prevItem
                ));
        }
    }

    useEffect(() => {
        const getTagSuggestions = async () => {
            try {
                const apiUrl = `${process.env.REACT_APP_BASEURL}admin/nutrition_plans/ingredient/tags?search=${tagsearch}`;
                const response = await axios.get(apiUrl);

                setTagSuggestions(response.data.data);
                console.log("##ingredients", response.data);
                //  setIsLoading(false); 
            } catch (error) {
                console.error('There was a problem with the Axios request:', error);
                // setIsLoading(false);
            }
        };
        getTagSuggestions();
    }, [tagsearch]);

    const handleTagsChange = (event, finalTags) => {
        // setTagsArrayObj(finalTags)
        // console.log(finalTags, '##finaltags')
        const tagsMap = new Map();
        finalTags.forEach(tag => {
          const trimmedTag = tag.trim().toLowerCase(); // For case-insensitive comparison
          if (trimmedTag.length > 0 && !tagsMap.has(trimmedTag)) {
            tagsMap.set(trimmedTag, tag.trim());
          }
        });
        const tagsArray = Array.from(tagsMap.values()).map(tag => ({ name: tag }));
        console.log('hello',tagsArray)
        setTagsArrayObj(tagsArray);
    };


    const defaultTags = tagsArrayObj.map((defaultTag => defaultTag.name));

    return (
        <MainCard>
            <Header>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <ArrowCircleLeftIcon onClick={() => { navigate(-1); }} sx={{ cursor: 'pointer', color: '#02a9f4' }} />
                    &nbsp;&nbsp;
                    <span className='header-heading' > {isEdit ? 'Edit Ingredient' : 'Add Ingredient'} </span>
                </div>
                <Button
                    startIcon={!isEdit && <AddIcon />}
                    color='primary'
                    variant='contained'
                    sx={{
                        color: 'white',
                        fontSize: '1rem',
                    }}
                    onClick={addIngredient}
                >
                    {isEdit ? 'Update Ingredient' : 'Add Ingredient'}
                </Button>
            </Header>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <GrayBox>
                <div>
                    <h5 style={{ color: '#313131', fontWeight: 500, fontSize: '1rem', marginLeft: "10px" }}>
                        Ingredient Name and Tags
                    </h5>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: windowWidth > 700 ? 'row' : 'column',
                            alignItems: 'center',
                            marginLeft: "10px"
                        }}
                    >
                        <TextField
                            id='outlined-basic'
                            label='Ingredient Name'
                            variant='outlined'
                            size='small'
                            sx={{ minWidth: '25vw', fontSize: '1rem' }}
                            value={ingName}
                            onChange={(e) => { setIngName(e.target.value) }}
                        />
                        &nbsp;&nbsp;

                            <div>
                                {/* <FormControl sx={{ minWidth: '36vw', width: '100%' }} size="small"> */}
                                {/* <p style={{ color: 'black', fontWeight: '500' }}>Ingredient Tags</p> */}
                                <Autocomplete
                                    multiple
                                    id="tags-filled"
                                    options={tagSuggestions.map((option => option.name))}
                                    defaultValue={defaultTags}
                                    value={defaultTags}
                                    variant='outlined'
                                    size="small"
                                    sx={{ minWidth: '36vw', fontSize: '1rem' }}
                                    disableClearable
                                    freeSolo
                                    renderTags={(value, getTagProps) =>
                                        value
                                          .filter(option => option.trim()).map((option, index) => (
                                                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                        ))
                                    }
                                    style={{ width: 500 }}
                                    onInputChange={(event, newInputValue)=>{
                                        const value = newInputValue.trim()
                                        settagsearch(value);
                                    }}
                                    onChange={handleTagsChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Tags (Press enter to add custom tags)"
                                        />
                                    )}
                                    renderOption={(props, option, { inputValue, selected }) => (
                                        <li {...props}>
                                            {console.log('first',option)}
                                            <span style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                                {option} {selected && <CheckIcon fontSize="small" />}
                                            </span>
                                        </li>
                                    )}
                                />
                                {/* </FormControl> */}
                            </div>
                        &nbsp;&nbsp;
                        &nbsp;&nbsp;
                        &nbsp;&nbsp;
                        <div>
                            {/* check box */}
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={hasMarcos}
                                        onChange={(e) => { setHasMarcos(e.target.checked) }}
                                        // disabled={!marcoDisable}
                                        name='checkedB'
                                        color='primary'
                                    />
                                }
                                label='Update Macros'
                            />
                        </div>
                    </div>
                </div>
                {
                    (location?.state?.item?.id || id) ? (
                        <MidWhiteBox>
                            <span style={{ fontSize: '1.2rem', marginLeft: "20px" }} >Measurement</span>
                            <Button
                                size='small'
                                startIcon={<AddIcon />}
                                color='primary'
                                variant='contained'
                                sx={{
                                    color: 'white',
                                    fontSize: '1rem',
                                }}
                                onClick={() => { addIngredientMeasurement() }}
                            >
                                Add Measurement
                            </Button>
                        </MidWhiteBox>
                    ) : (
                        <MidWhiteBox>
                            <span style={{ fontSize: '1.2rem', marginLeft: "20px" }} >Measurement</span>
                            <Button
                                size='small'
                                startIcon={<AddIcon />}
                                color='primary'
                                variant='contained'
                                sx={{
                                    color: 'white',
                                    fontSize: '1rem',
                                }}
                                onClick={() => { handleIngredientMeasurementAddition() }}
                            >
                                Add Measurement
                            </Button>
                        </MidWhiteBox>
                    )
                }
                {isEdit ? (<div>
                    {
                        ingredientMeasurement.map((measurement, index) => (
                            <div className={windowWidth > 700 ? 'measurement-row-input' : 'column'}>
                                <div style={{ minWidth: '28vw', width: '30%', marginRight: '1rem' }}>
                                    {
                                        measurementEditIndex === index ? (
                                            <FormControl fullWidth >
                                                <InputLabel id="demo-simple-select-label">Measurement</InputLabel>
                                                <Select
                                                    fullWidth
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Results per Page"
                                                    value={measurement.name}
                                                    sx={{
                                                        paddingLeft: "15px"
                                                    }}
                                                    onChange={(e) => { handleMeasurementChange(e, index) }}
                                                >
                                                    {measurements.map((measurement, index) => (
                                                        <MenuItem value={measurement.name}>{measurement.name}</MenuItem>
                                                    ))}
                                                    <MenuItem value="Custom Value">Custom Value</MenuItem>
                                                </Select>
                                            </FormControl>
                                        ) : (
                                            <p
                                                className='measurement-name'
                                            >
                                                {measurement.name}
                                            </p>
                                        )
                                    }
                                </div>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <div style={{ display: 'flex', alignItems: 'center', width: 'min-content' }}>
                                    {
                                        measurementEditIndex === index ? (
                                            <TextField
                                                type="number"
                                                id="outlined-basic"
                                                placeholder="Value"
                                                variant="outlined"
                                                size="small"
                                                label="Value"
                                                value={measurement.value}
                                                sx={{ minWidth: '10vw', width: '20%', marginRight: '1rem' }}
                                                onChange={(e) => { handleMeasurementValueChange(e, index) }}
                                            />
                                        ) : (
                                            <p
                                                className='measurement-value'
                                            >
                                                {measurement.value}
                                            </p>
                                        )
                                    }
                                </div>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <div style={{ display: 'flex', alignItems: 'center', width: 'min-content' }}>
                                    {
                                        measurementEditIndex === index ? (
                                            <TextField
                                                id="outlined-basic"
                                                placeholder="Unit"
                                                variant="outlined"
                                                size="small"
                                                label="Unit"
                                                value={measurement.unit}
                                                onChange={(e) => { handleMeasurementUnitChange(e, index) }}
                                                sx={{ minWidth: '10vw', width: '20%', marginRight: '1rem' }}
                                            />
                                        ) : (
                                            <p className='measurement-value'>
                                                {measurement.unit}
                                            </p>
                                        )
                                    }
                                </div>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <div className='measurement-button-container' >
                                    <div style={{}}>
                                        <IconButton
                                            color="primary"
                                            variant="contained"
                                            onClick={() => { handleEditUpdate(index) }}
                                        >
                                            {
                                                measurementEditIndex === index ? (
                                                    <DoneIcon />
                                                ) : (
                                                    <CreateIcon />
                                                )
                                            }
                                        </IconButton>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <div style={{}}>
                                        <IconButton
                                            variant="contained"
                                            sx={{
                                                color: 'red',
                                            }}
                                            onClick={() => {
                                                deleteIngredientMeasurement(index)
                                            }}

                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    {
                        isAddingNewMeasurement && (
                            <div className={windowWidth > 700 ? 'measurement-row-input' : 'column'}>
                                <div style={{ minWidth: '28vw', width: '30%', marginRight: '1rem' }}>

                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Measurement</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Results per Page"
                                            value={newMeasurement.name}
                                            sx={{
                                                paddingLeft: "15px"
                                            }}
                                            onChange={(e) => {
                                                if (e.target.value === "Custom Value") {
                                                    setOpenCustomMeasurementModal(true)
                                                } else {
                                                    setNewMeasurement({ ...newMeasurement, name: e.target.value, unit: getUnit(e.target.value), value: getValue(e.target.value) })
                                                }
                                            }}
                                        >
                                            {
                                                measurements.map((measurement, index) => (
                                                    <MenuItem value={measurement.name}>{measurement.name}</MenuItem>
                                                ))
                                            }
                                            <MenuItem value="Custom Value">Custom Value</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <div style={{ display: 'flex', alignItems: 'center', width: 'min-content' }}>
                                    <TextField
                                        type="number"
                                        id="outlined-basic"
                                        placeholder="Value"
                                        label="Value"
                                        variant="outlined"
                                        size="small"
                                        sx={{ minWidth: '10vw', width: '20%', marginRight: '1rem' }}
                                        value={newMeasurement.value}
                                        onChange={(e) => { setNewMeasurement({ ...newMeasurement, value: e.target.value }) }}
                                    />
                                </div>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <div style={{ display: 'flex', alignItems: 'center', width: 'min-content' }}>
                                    <TextField
                                        type="text"
                                        id="outlined-basic"
                                        placeholder="Unit"
                                        variant="outlined"
                                        label="Unit"
                                        size="small"
                                        sx={{ minWidth: '10vw', width: '20%', marginRight: '1rem' }}
                                        value={newMeasurement.unit}
                                    />
                                </div>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <div className='measurement-button-container' >
                                    <div style={{}}>
                                        <IconButton
                                            color="primary"
                                            variant="contained"
                                            onClick={() => { addNewIngredientMeasurement() }}
                                        >
                                            <DoneIcon />
                                        </IconButton>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <div style={{}}>
                                        <IconButton
                                            variant="contained"
                                            sx={{
                                                color: 'red',
                                            }}
                                            onClick={() => {
                                                setIsAddingNewMeasurement(false)
                                                setNewMeasurement(dummyMeasurement)
                                            }}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>) : (
                    <div>
                        {IngridientForNew.map((item, index) => (
                            <div className='measurement-row-input'>
                                <div key={item.id} style={{ minWidth: '28vw', width: '30%', marginRight: '1rem' }}>

                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Measurement</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Results per Page"
                                            value={item.name}
                                            sx={{
                                                paddingLeft: "15px"
                                            }}
                                            onChange={(e) => {
                                                handleMeasurementChangeForNew(e, item.id)
                                                setindexToEdit(item.id)
                                                console.log('hello',item)
                                                // setIngridientForNew((prevList) =>
                                                // prevList.map((prevItem) =>
                                                // prevItem.id === item.id ? { ...prevItem, name: e.target.value, value: getValue(e.target.value), unit: getUnit(e.target.value) } : prevItem
                                                // ));
                                            }}
                                        >
                                            {
                                                measurements.map((measurement, index) => (
                                                    <MenuItem value={measurement.name}>{measurement.name}</MenuItem>
                                                ))
                                            }
                                            <MenuItem value="Custom Value">Custom Value</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <div style={{ display: 'flex', alignItems: 'center', width: 'min-content' }}>
                                    <TextField
                                        type="number"
                                        id="outlined-basic"
                                        placeholder="Value"
                                        label="Value"
                                        variant="outlined"
                                        size="small"
                                        sx={{ minWidth: '10vw', width: '20%', marginRight: '1rem' }}
                                        value={item.value}
                                        onChange={(e) => {
                                            setIngridientForNew((prevList) =>
                                                prevList.map((prevItem) =>
                                                    prevItem.id === item.id ? { ...prevItem, value: e.target.value } : prevItem
                                                ));
                                        }}
                                    />
                                </div>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <div style={{ display: 'flex', alignItems: 'center', width: 'min-content' }}>
                                    <TextField
                                        disabled
                                        type="text"
                                        id="outlined-basic"
                                        placeholder="Unit"
                                        variant="outlined"
                                        label="Unit"
                                        size="small"
                                        sx={{ minWidth: '10vw', width: '20%', marginRight: '1rem' }}
                                        value={item.unit}
                                    />
                                </div>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <div className='measurement-button-container' >
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <div style={{}}>
                                        {index !== 0 && (
                                            <IconButton
                                                variant="contained"
                                                sx={{
                                                    color: 'red',
                                                }}
                                                onClick={() => {
                                                    handleRemoveRow(item.id)
                                                }}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                <MidWhiteBox>
                    <span style={{ fontSize: '1.2rem', marginLeft: "20px" }} >Add macro-nutrients according to 100gm of ingredient</span>
                    <Button
                        size='small'
                        disabled={!hasMarcos}
                        startIcon={<AddIcon />}
                        color='primary'
                        variant='contained'
                        sx={{
                            color: 'white',
                            fontSize: '1rem',
                        }}
                        onClick={() => { setModalOpen(true) }}
                    >
                        {isEdit ? 'Add New Macros' : 'Add Macros'}
                    </Button>
                </MidWhiteBox>



                {renderModal()}
                {
                    hasMarcos && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: windowWidth > 700 ? 'row' : 'column',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                flexWrap: 'wrap',
                                maxHeight: '40vh',
                                overflowY: 'scroll'
                            }}
                        >
                            {
                                macroNutrients.map((item, index) => (
                                        <div
                                            style={{
                                                margin: '10px',
                                            }}
                                        >
                                            <p
                                                style={{
                                                    fontSize: '1rem',
                                                    color: '#313131',
                                                    marginBottom: '10px'
                                                }}
                                            >{item.name}</p>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    marginRight: '2vw',
                                                    marginBottom: '5vh'
                                                }}
                                            >
                                                <TextField
                                                    id='outlined-basic'
                                                    // label={item.name}
                                                    variant='outlined'
                                                    type='number'
                                                    size='small'
                                                    sx={{ minWidth: '100px', maxWidth: '150px' }}
                                                    value={item.value}
                                                    onChange={(e) => {
                                                        // only change when decial has 2 digits after dot
                                                        if (e.target.value.split('.').length > 1 && e.target.value.split('.')[1].length > 2) {
                                                            return;
                                                        }
                                                        const temp = [...macroNutrients];
                                                        item.value = parseFloat(e.target.value)
                                                        temp[index].value = parseFloat(e.target.value);
                                                        setMacroNutrients(temp);
                                                    }}
                                                />
                                                &nbsp;
                                                {item.unit === 'g' ? 'gm' : item.unit}
                                            </div>
                                        </div>
                                    )
                                    )
                            }
                        </div>
                    )
                }
            </GrayBox>
            {renderCustomMeasurementDialog()}
            <Snackbar open={open} autoHideDuration={4000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity="success" sx={{ width: '100%' }}>
                    {toastMessage}
                </Alert>
            </Snackbar>
        </MainCard>
    )
}

export default AddNewIngredient