import { useState, useEffect, useRef, forwardRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import MainCard from 'ui-component/cards/MainCard';
import {
    Box,
    Button,
    Checkbox,
    TextField,
    Link,
    Divider,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Tooltip,
    Modal,
    FormControl,
    FormLabel,
    FormControlLabel,
    Pagination,
    Stack,
    Grid,
    MenuItem,
    Select,
    InputLabel,
    IconButton,
    Autocomplete,
    Chip
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AddIcon from '@mui/icons-material/Add';
import { common } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MaterialTable from 'material-table';
import tableIcons from 'themes/MaterialTableIcons';
import { Header, GrayBox, MidWhiteBox } from './style';
import ReactLoading from 'react-loading';
import InputAdornment from '@mui/material/InputAdornment';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import axios from 'axios';
import $ from 'jquery';
import { SelectChangeEvent } from '@mui/material/Select';
import './style.css';
import { makeStyles } from '@material-ui/core/styles';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import CreateIcon from '@mui/icons-material/Create';
import { display, fontSize, margin, padding, width } from '@mui/system';
import { Receipt } from '@mui/icons-material';
import { element } from 'prop-types';
import { Text } from '../style';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';


const useStyles = makeStyles((theme) => ({
    dialog: {
        padding: '10px !important'
    },
    dialogContent: {
        borderTop: '1px solid #919191',
        borderBottom: '1px solid #919191'
    },
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '25px',
                paddingRight: "25px"
            },
            '&:hover fieldset': {
            },
            '&.Mui-focused fieldset': {
            },
        },
    },
    disabledButton: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {

            }
        },
    }
}));

const AddNewRecipe = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isEdit, setIsEdit] = useState(location.state ? true : false);
    const [recipeId, setRecipeId] = useState(null);
    const [recipeName, setRecipeName] = useState('');
    const [recipeImageLink, setRecipeImageLink] = useState('');
    const [recipeDescription, setRecipeDescription] = useState('');
    const [allBaseTypes, setAllBaseTypes] = useState([]);
    const [recipeType, setRecipeType] = useState([]);
    const [recipeTypeForMultiSelect, setRecipeTypeForMultiSelect] = useState([]);
    const [recipeTags, setRecipeTags] = useState([]);
    const [recipeMacros, setRecipeMacros] = useState([]);
    const [recipeCookingMethod, setRecipeCookingMethod] = useState('');
    const [allIngredients, setAllIngredients] = useState([]);
    const [allIngredientsCount, setAllIngredientsCount] = useState(0);
    const [tempIngredients, setTempIngredients] = useState([]);
    const [selectedIngredients, setSelectedIngredients] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [isAddingRecipeMeasurement, setIsAddingRecipeMeasurement] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [duplicateRecipeName, setDuplicateRecipeName] = useState('');
    const [measurementName, setMeasurementName] = useState([]);
    const [measurementValue, setMeasurementValue] = useState();
    const [measurementUnit, setMeasurementUnit] = useState();
    const [measurementServing, setMeasurementServing] = useState(1)
    const [editMeasurementName, setEditMeasurementName] = useState([]);
    const [editMeasurementValue, setEditMeasurementValue] = useState('');
    const [editMeasurementUnit, setEditMeasurementUnit] = useState('');
    const [editMeasurementServing, setEditMeasurementServing] = useState('');
    const [measurementEditIndex, setMeasurementEditIndex] = useState(-1);
    const [recipeMeasurementList, setRecipeMeasurementList] = useState([]);
    const [calories, setCalories] = useState(0);
    const [isEditingNutritionProfile, setIsEditingNutritionProfile] = useState(false);
    const [isCustomMeasurement, setIsCustomMeasurement] = useState(false);
    const [customEditFieldIndex, setCustomEditFieldIndex] = useState(-1);
    const [customIngredientMeasurement, setCustomIngredientMeasurement] = useState([]);
    const [updatedMarcoList, setUpdatedMarcoList] = useState([]);
    const [originalMarcoList, setOriginalMarcoList] = useState([]);
    const [isUpdate, setIsUpdate] = useState(false);
    const [tagsArrayObj, setTagsArrayObj] = useState([]);
    const [isIngredientChange, setIsIngredientChange] = useState(false);
    const [tagSuggestions, setTagSuggestions] = useState([]);
    const [tagsearch, settagsearch]=useState('');
    const [tags, setTags] = useState([]);
    const [lastTags, setLastTags] = useState([]);
    const [realTags, setRealTags] = useState([]);
    const [measurements, setMeasurements] = useState([]);
    const [indexToEdit, setindexToEdit] = useState();
    const [openCustomMeasurementModal, setOpenCustomMeasurementModal] = useState(false);
    const [customMeasurementName, setCustomMeasurementName] = useState('');
    const [customMeasurementValue, setCustomMeasurementValue] = useState('');
    const [customMeasurementUnit, setCustomMeasurementUnit] = useState('');
    const [customMeasurementIsMultipliable, setCustomMeasurementIsMultipliable] = useState(false);
    const [trigger, setTrigger] = useState(1);
    const [customMeasurementIndex, setCustomMeasurementIndex] = useState(-1);
    const [TotalGm, setTotalGm] = useState();
    const [IndexForCustom, setIndexForCustom] = useState({custom: false, ing_id:''});
    const [recipeMeasurementForNew, setRecipeMeasurementForNew] = useState([
        { id: 1, name: null, serving: null, value: null, unit: null },
    ]);
    const [Disable, setDisable] = useState(true);

    const [open, setOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState('')
    const Alert = forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpen(false);
    };
    
      const handleRecipeMeasurementAddition = () => {
        // Get the last id in the current recipeMeasurementList
        const lastId = recipeMeasurementForNew[recipeMeasurementForNew.length - 1].id;
        const newMeasurement = {
          id: lastId + 1,
          name: null,
          serving: null,
          value: null,
          unit: null,
        };
    
        setRecipeMeasurementForNew((prevList) => [...prevList, newMeasurement]);
      };

      const handleRemoveRow = (idToRemove) => {
        const updatedList = recipeMeasurementForNew.filter((item) => item.id !== idToRemove);
        setRecipeMeasurementForNew(updatedList);
      };


    const handleRecipeTypeChange = (event) => {
        const {
            target: { value }
        } = event;
        setRecipeTypeForMultiSelect(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
    };

    const handleMeasurementNameChange = (event) => {
        const {
            target: { value }
        } = event;
        setMeasurementName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
    };

    const handleEditMeasurementChange = (event) => {
        const {
            target: { value }
        } = event;
        console.log('first',event.target.value)
        setEditMeasurementName(
            // On autofill we get a stringified value.
            event.target.value
        );
        const temp = measurements.find((item) => item.name === event.target.value)
        setEditMeasurementValue(temp.value);
        setEditMeasurementUnit(temp.unit);
        console.log(editMeasurementName);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleClearMeasurementValues = () => {
        setMeasurementName([]);
        setMeasurementValue('');
        setMeasurementServing(1);
        setIsAddingRecipeMeasurement(false);
    };

    const handleClose = () => {
        if (allIngredients.length !== tempIngredients.length) {
            alert("Please save the changes");
        } else {
            setModalOpen(false);
            setSearch('');
            setAllIngredients([]);
        }
    };

    const modalBoxStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: windowWidth > 600 ? '60vw' : '80vw',
        maxHeight: '80vh',
        bgcolor: 'background.paper',
        borderCollapse: 'separate',
        borderRadius: '0.7rem',
        boxShadow: 24,
        p: 4,
        overflow: 'auto'
    };

    const blackCheckboxStyle = {
        color: common.black,
        '&.Mui-checked': {
            color: common.black
        }
    };

    useEffect(() => {
        const getMeasurements = async () => {
            const res = await axios.get(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/v1/units`);
            setMeasurements(res.data.data);
            console.log("measurements", res.data.data)
        }
        getMeasurements();
    }, []);

    useEffect(async () => {
        if (isEdit) {
            console.log('isEdit');
            const recipe = location.state.item;
            setRecipeId(recipe.id);
            const res = await axios.get(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/recipe/${recipe.id}`);
            const recipeData = res.data.data[0];
            setRecipeName(recipeData.name);
            setRecipeImageLink(recipeData.img_url);
            setRecipeType(recipeData.base_type);
            setRecipeTypeForMultiSelect(recipeData.base_type.split(',').map((item) => item.trim()));
            setRecipeDescription(recipeData.description);
            setRecipeCookingMethod(recipeData.cooking_instructions);
            setRecipeTags(recipeData.tags);
            setLastTags(recipeData.tags);
            console.log(recipeData, '8888888888888888');
            setOriginalMarcoList(recipeData.macros_nutrients);
            setCalories(getCalories(recipeData.macros_nutrients))
            setTotalGm(recipeData.total_value_in_grams)
            const ings = recipeData.ingredients.map((ingredient) => {
                console.log(ingredient, "ininini");
                return {
                    id: ingredient.ingredient_id,
                    name: ingredient.ingredient_name,
                    quantity: ingredient.quantity,
                    units: ingredient.ingredient_unit,
                    is_active: ingredient.is_active ? ingredient.is_active : false,
                    measurement: ingredient.ingredient_measurement,
                    macro_nutrients: ingredient.macro_nutrients,
                    ...ingredient,
                    measurement_unit: ingredient.unit,
                    measurementList: []
                };
            })
            console.log(ings, "ingssfsd");
            setSelectedIngredients(ings);
            setTempIngredients(ings);
            // const measurementList = await axios.get(
            //     `${process.env.REACT_APP_BASEURL}admin/nutrition_plans/recipe/measurement/${recipe.id}`
            // );
            setRecipeMacros(recipeData.macros_nutrients);
            setRecipeMeasurementList(makeMeasurementList(recipeData.servings));
            const promises = ings.map(async (ingredient) => {
                const response = await axios.get(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/ingredient/measurement/${ingredient.ingredient_id
                    }`)
                return response.data.data
            });
            Promise.all(promises)
                .then((data) => {
                    data.forEach((item, index) => {
                        if (item.length > 0) {
                            ings[index].measurementList = item
                        } else {
                            ings[index].measurementList = measurements
                        }
                    })
                    console.log("measurements from ing", ings);
                    setSelectedIngredients(ings);
                    setTrigger(trigger + 1);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, []);


    const getRealTags = (id, Ingredients, type, tags) => {
        console.log(id, Ingredients, type, tags, "realTags")
        const ingredArray = [];
        Ingredients.forEach((ingredient) => {
            if (ingredient.quantity) {
                ingredArray.push({
                    ingredient_id: ingredient.ingredient_id,
                    quantity: ingredient.quantity,
                    unit: 'gram'
                });
            }
        });
        const data = {
            type: type.join(','),
            ingredients: ingredArray,
            recipe_id: id
        };
        const temp = []
        if (ingredArray.length > 0) {
            axios.post(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/ingredient/tags`, data).then((res) => {
                console.log(res, "res")
                if (res.status === 200) {
                    setTotalGm(res.data.data[0].total_value_in_grams);
                    // if (res.data.data[0].ingredient_tags.length > 0) {
                    //     if (tags && tags.length > 0) {
                    //         tags.forEach((tag) => {
                    //             if (!res.data.data[0].ingredient_tags.includes(tag)) {
                    //                 temp.push(tag)
                    //             }
                    //         })
                    //     }
                    // }
                    console.log(temp, "temp")
                    // setRealTags(temp)
                }
            })
        }

    }

    // useEffect(() => {
    //     if (recipeId && recipeTypeForMultiSelect && recipeTags) {
    //         getRealTags(recipeId, recipeTypeForMultiSelect, recipeTags.split(',').map((item) => item.trim()))
    //     }
    // }, [recipeId, recipeTypeForMultiSelect, recipeTags]);    

    // useEffect(() => {
    //     const tags = removeDuplicates(recipeTags.split(',').map((item) => item.trim()));
    //     setRecipeTags(tags.join(', '));
    // }, [recipeTags]);

    console.log(realTags, "realTags")
    // console.log(recipeMacros, "23333 88");
    console.log(selectedIngredients, "selectedIngredients")
    const makeMeasurementList = (measurementList) => {
        const newList = [];
        measurementList.forEach((measurement) => {
            newList.push({
                name: measurement.measurement_unit,
                value: measurement.value_in_gm,
                id: measurement.id,
                serving: measurement.serving,
                unit: measurement.unit
            });
        });
        return newList;
    };

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
    });

    useEffect(() => {
        console.log('useEffect selectedIngredients', selectedIngredients);
        const calculateMacros = async () => {
            if (selectedIngredients.length > 0) {
                if (isEdit && !selectedIngredients.some((ingredient) => ingredient.measurement)) {
                    console.log('Setting Recipe Macros');
                } else {
                    const hasQuantity = selectedIngredients.some((ingredient) => ingredient.quantity);
                    if (hasQuantity) {
                        if (recipeTypeForMultiSelect === '') {
                            alert('Please select the Recipe Type first');
                        } else {
                            const ingredArray = [];
                            selectedIngredients.forEach((ingredient) => {
                                if (ingredient.quantity) {
                                    ingredArray.push({
                                        ingredient_id: ingredient.ingredient_id,
                                        quantity: ingredient.quantity,
                                    });
                                }
                            });
                            const data = {
                                type: recipeTypeForMultiSelect.join(','),
                                ingredients: ingredArray,
                                recipe_id: recipeId
                            };
                            try {
                                const res = await axios.post(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/recipe/calculate`, data);
                                if (res.status === 200) {
                                    setRecipeMacros(res.data.data[0].calculated_macros);
                                    setCalories(getCalories(res.data.data[0].calculated_macros))
                                    setTotalGm(res.data.data[0].total_value_in_grams);
                                    console.log("this3")
                                    // if (res.data.data[0].ingredient_tags || res.data.data[0].ingredient_tags.length > 0) {
                                    //     console.log("This1")
                                    //     if (isEdit) {
                                    //         let tags = [...realTags,...res.data.data[0].ingredient_tags];
                                    //         tags = removeDuplicates(tags);
                                    //         setRecipeTags(tags.join(','));
                                    //     } else {
                                    //         let tags = [...recipeTags.split(','), ...res.data.data[0].ingredient_tags];
                                    //         tags = removeDuplicates(tags);
                                    //         setRecipeTags(tags.join(','));
                                    //     }
                                    // } else {
                                    //     setRecipeTags(realTags.join(','));
                                    //     console.log("This2")
                                    // }
                                }
                                // const res = await axios.get(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/recipe/${recipeId}`);
                                // const recipeData = res.data.data[0];
                                // setRecipeTags(recipeData.tags.map((tag) => tag.name).join(', '));
                                // setRecipeMacros(recipeData.macros_nutrients);
                                // setCalories(getCalories(recipeData.macros_nutrients))
                            } catch (error) {
                                console.log("Error in calculating macros", error);
                            }
                        }
                    }
                }
            } else {
                setRecipeMacros([]);
                // setRecipeTags(realTags.join(', '));
            }
            setTempIngredients(selectedIngredients);
        }
        // const addMeasurementList = async () => {
        //     selectedIngredients.forEach(async (ingredient) => {
        //         const res = await axios.get(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/ingredient/measurement/${ingredient.ingredient_id}`);
        //         const list = res.data.data.length>0 ? res.data.data : measurements;
        //         console.log(list, "res5466")
        //         const newSelectedIngredients = [...selectedIngredients];
        //         const index = newSelectedIngredients.findIndex((item) => item.ingredient_id === ingredient.ingredient_id);
        //         newSelectedIngredients[index].measurementList = list
        //         setSelectedIngredients(newSelectedIngredients);
        //         setTrigger(trigger+1);
        //     });
        // };
        // addMeasurementList();
        if (isIngredientChange) {
            calculateMacros();
            setIsIngredientChange(false);
        }
    }, [selectedIngredients]);

    // useEffect(() => {

    // }, [selectedIngredients]);


    function removeDuplicates(array) {
        return [...new Set(array)];
    }

    const isMultipliable = (measurement) => {
        let flag = false;
        // eslint-disable-next-line consistent-return
        measurements.forEach((item) => {
            if (item.name === measurement) {
                flag = item.is_multiply
            }
        })
        return flag;
    };

    const isCustomRecipeMeasurement = (measurement) => {
        let flag = true;
        // eslint-disable-next-line valid-typeof
        if (typeof (measurement) === 'array') {
            measurement = measurement[0]
            measurements.forEach((item) => {
                if (item.name === measurement) {
                    flag = false;
                }
            })
        } else {
            measurements.forEach((item) => {
                if (item.name === measurement) {
                    flag = false;
                }
            })
        }
        // eslint-disable-next-line consistent-return

        if (typeof (measurement) === 'string') {
            console.log(measurement, flag, "flag for custom")
            return flag;
        }
        return null;
    }

    const handleIngredientMeasurementChangeV1 = (e, index, type, measurementsList, ingredientid) => {
        if (!measurementsList) {
            measurementsList = measurements;
        }
        // console.log(type, measurementsList, e, index, "measurementList123")
        setIsIngredientChange(true);
        if (e.target.value !== "Custom Value") {
            const newSelectedIngredients = [...selectedIngredients];
            newSelectedIngredients[index][type] = e.target.value;
            if (type === 'measurement') {
                if (!newSelectedIngredients[index].units || newSelectedIngredients[index].units === '') {
                    newSelectedIngredients[index].units = 1;
                }
            }
            if (!checkIfMasterMeasurement(newSelectedIngredients[index].measurement)) {
                let units = newSelectedIngredients[index].units ? newSelectedIngredients[index].units : 1;
                units = Number(units)
                console.log('first')
                newSelectedIngredients[index].quantity = measurementsList.find((item) => item.name === newSelectedIngredients[index]?.measurement)?.value ? measurementsList.find((item) => item.name === newSelectedIngredients[index].measurement)?.value * units : measurements.find((item) => item.name === newSelectedIngredients[index].measurement)?.value ? measurements.find((item) => item.name === newSelectedIngredients[index].measurement)?.value * units : "";
            } else {
                // eslint-disable-next-line no-lonely-if
                if (isMultipliable(newSelectedIngredients[index].measurement)) {
                    console.log('first')
                    let units = newSelectedIngredients[index].units ? newSelectedIngredients[index].units : 1;
                    units = Number(units)
                    newSelectedIngredients[index].quantity = (measurementsList.find(item => item.name === newSelectedIngredients[index]?.measurement)?.value || measurements.find(item => item.name === newSelectedIngredients[index]?.measurement)?.value) * units;                      
                } else {
                    console.log('first111')
                    newSelectedIngredients[index].quantity = (measurementsList.find(item => item.name === newSelectedIngredients[index]?.measurement)?.value || measurements.find(item => item.name === newSelectedIngredients[index]?.measurement)?.value);
                }
            }
            newSelectedIngredients[index].measurement_unit = measurementsList.find((item) => item.name === newSelectedIngredients[index].measurement)?.unit ? measurementsList.find((item) => item.name === newSelectedIngredients[index].measurement)?.unit : "";
            setSelectedIngredients(newSelectedIngredients);
            setTrigger(trigger + 1);
        } else {
            setOpenCustomMeasurementModal(true);
            setCustomMeasurementIndex(index);
            if (type === 'measurement') {
                setCustomMeasurementIndex(index)
                setIndexForCustom({ custom: true, ing_id: ingredientid });
            }
        }
    };

    const getUnitFromMeasuremetArray = (measurement, measurementsList) => {
        if (!measurementsList) {
            measurementsList = measurements;
        }
        if (measurements.find((item) => item.name === measurement)) {
            const unit = measurements.find((item) => item.name === measurement).unit;
            return unit;
        }
        return 'gm';
    };

    const checkIfMasterMeasurement = (measurement) => {
        if (measurements.find((item) => item.name === measurement)) {
            return true;
        }
        return false;
    }

    const checkIfAllSelectedIngredientsHaveInfo = () => {
        let result = true;
        selectedIngredients.forEach((ingredient) => {
            if (!ingredient.units || !ingredient.measurement) {
                result = false;
            }
        });
        recipeMeasurementForNew.forEach((newMeasure) => {
            if (!newMeasure.name || !newMeasure.serving || !newMeasure.value || !newMeasure.unit) {
                result = false;
            }
        })
        return result;
    };
    const checkIfAllFieldsAreFilled = () => {
        if (
            recipeName === '' ||
            recipeType === '' ||
            // recipeDescription === '' ||
            recipeCookingMethod === '' ||
            // recipeTags === '' ||
            recipeMacros === '' ||
            !checkIfAllSelectedIngredientsHaveInfo()
        ) {
            return false;
        }
        return true;
    };

    const handleImageUpload = (e) => {
        const formData = new FormData();
        formData.append('files', e.target.files[0]);
        axios
            .post('admin/nutrition_plans/recipe/image/upload', formData)
            .then((res) => {
                setRecipeImageLink(res.data.data[0].url);
            })
            .catch((err) => {
                alert('Error in uploading image');
            });
    };

    const handleRecipeMeasrementAddition = () => {
        setIsAddingRecipeMeasurement(isAddingRecipeMeasurement ? false : true);
    };

    useEffect(async () => {
        const res = await axios.get(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/base_types`);
        setAllBaseTypes(res.data.data);
    }, []);

    useEffect(async () => {
        if (modalOpen) {
            if (page === 1 && search.length > 3) setLoading(true);
            const ingredients = [];
            axios
                .get(
                    `${process.env.REACT_APP_BASEURL}admin/nutrition_plans/recipe/search/ingredients?search=${search}&page=${page}&size=20`
                )
                .then((res) => {
                    setAllIngredientsCount(res.data.total_count);
                    res.data.data.forEach((ingredient) => {
                        ingredients.push({ ...ingredient, ...{ ingredient_id: ingredient.id } });
                    });
                    setAllIngredients(res.data.data);
                    setLoading(false);
                })
                .then(() => {
                    setAllIngredients(ingredients);
                });
        }
    }, [modalOpen, page, search]);

    const getCalories = (macroNutrients) => {
        let cal = 0;
        macroNutrients.forEach((macro) => {
            if (macro.name === 'Calories') {
                cal = macro.value;
            }
        })
        return cal;
    };

    const updateRecipe = async () => {
        const tagsArray = recipeTags.map(tag => ({
            "name": tag.name
        }));

        const recipe = {
            name: recipeName,
            tags: tagsArray,
            type: recipeTypeForMultiSelect.join(','),
            has_add_on: true,
            cooking_instructions: recipeCookingMethod,
            description: recipeDescription,
            img_url: recipeImageLink,
            ingredients: selectedIngredients.map((ingredient) => {
                console.log(ingredient);
                return {
                    ingredient_id: ingredient.ingredient_id,
                    ingredient_unit: ingredient.units ? Number(ingredient.units, 10) : 0,
                    ingredient_measurement: ingredient.measurement ? ingredient.measurement : '',
                    quantity: ingredient.quantity,
                    unit: 'gram'
                };
            })
        };
        try {
            const res = await axios.patch(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/recipe/${recipeId}`, recipe);
            if (res.status === 200) {
                // alert(res.data.message);
                // navigate('/nutrition/recipe');
                setToastMessage(res.data.message)
                setOpen(true);
            }
            console.log('RECIPE', recipe);
        } catch (error) {
            alert(error.response.data.message);
        }
    };

    const addNewRecipe = async () => {
        const recipe = {
            name: recipeName,
            tags: recipeTags,
            type: recipeTypeForMultiSelect.join(','),
            has_add_on: true,
            cooking_instructions: recipeCookingMethod,
            description: recipeDescription,
            img_url: recipeImageLink,
            servings: recipeMeasurementForNew.map((measurement) => {
                console.log(measurement);
                return {
                    serving: measurement.serving,
                    measurement_unit: measurement.name,
                    value_in_gm: measurement.value,
                    unit: measurement.unit,
                }
            }),
            ingredients: selectedIngredients.map((ingredient) => {
                console.log(ingredient);
                return {
                    ingredient_id: ingredient.id,
                    ingredient_unit: ingredient.units ? Number(ingredient.units) : 0,
                    ingredient_measurement: ingredient.measurement ? ingredient.measurement : '',
                    quantity: ingredient.quantity,
                    unit: 'gram'
                };
            })
        };
        try {
            console.log('RECIPE', recipe);
            const res = await axios.post(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/recipe`, recipe);
            if (res.status === 200) {
                // alert('Recipe Added Successfully');
                // navigate('/nutrition/recipe');
                setToastMessage(res.data.message)
                setOpen(true);
            }
        } catch (error) {
            alert(error.response.data.message);
        }
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };
    const blueCheckboxStyle = {
        color: common.blue,
        '&.Mui-checked': {
            color: common.blue,
        }
    }
    const duplicateRecipe = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/recipe/${recipeId}/duplicate`, {
                name: duplicateRecipeName.trim()
            });
            if (res.status === 200) {
                setDialogOpen(false);
                // alert('Recipe Duplicated Successfully');
                // navigate('/nutrition/recipe');
            }
        } catch (error) {
            alert(error.response.data.message);
        }
    };

    const addRecipeMeasurement = async (recipeMeasurementName, recipeMeasurementValue, recipeMeasurementServing, measurementUnit) => {
        if (isEdit) {
            try {
                const body = {
                    recipe_id: recipeId,
                    measurement_unit: recipeMeasurementName[0],
                    value_in_gm: recipeMeasurementValue,
                    serving: recipeMeasurementServing,
                    unit: measurementUnit
                };
                const res = await axios.post(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/recipe/measurement`, body);
                const list = res.data.data;
                const newList = [];
                list.forEach((item) => {
                    newList.push({ ...item, ...{ name: item.measurement_unit, value: item.value_in_gm, id: item.id, serving: item.serving, unit: item.unit } });
                });
                setRecipeMeasurementList(newList);
                setMeasurementName([])
                setMeasurementServing(1)
                setMeasurementUnit('')
                setMeasurementValue('')
                 if (defaultTags === '') alert('Please enter tags');
        else {
            let tagsArray = [];
            if (isEdit) {
                tagsArray = defaultTags.map(tag => ({
                    "name": tag
                }));
            }
            else {
                tagsArray = defaultTags;
            }
        }
            } catch (error) {
                alert(error.response.data.message);
            }
        } else {
            setRecipeMeasurementList([...recipeMeasurementList, { name: measurementName, value: measurementValue, serving: measurementServing, unit: measurementUnit }]);
            setMeasurementName([]);
            setMeasurementValue('');
            setMeasurementUnit('')
            setMeasurementServing(1);
        }
    };

    const updateRecipeMeasurement = async (id, measurementName, value, serving, unitName) => {
        try {
            const body = {
                measurement_unit: measurementName,
                value_in_gm: value,
                unit: unitName,
                serving
            };
            const res = await axios.patch(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/recipe/measurement/${id}`, body);
            const list = res.data.data;
            const newList = [];
            list.forEach((item) => {
                newList.push({ ...item, ...{ name: item.measurement_unit, value: item.value_in_gm, id: item.id, serving: item.serving, unit: item.unit } });
            });
            setRecipeMeasurementList(newList);
            // if(res.status === 200){
            //     alert('Recipe Measurement Updated Successfully');
            // }
        } catch (error) {
            alert(error.response.data.message);
        }
    };

    const deleteRecipeMeasurement = async (id) => {
        try {
            const res = await axios.delete(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/recipe/measurement/${id}`);
            const list = res.data.data;
            const newList = [];
            list.forEach((item) => {
                newList.push({ ...item, ...{ name: item.measurement_unit, value: item.value_in_gm, id: item.id, serving: item.serving, unit: item.unit } });
            });
            setRecipeMeasurementList(newList);
            if (res.status === 200) {
                // alert('Recipe Measurement Deleted Successfully');
            }
        } catch (error) {
            alert(error.response.data.message);
        }
    };

    const classes = useStyles();
    const renderDialog = () => (
        <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ padding: '20px' }}
        >
            <DialogTitle id="alert-dialog-title" sx={{ fontSize: '1.2rem', fontWeight: '600' }}>
                Duplicate Recipe
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <p>Are you sure you want to duplicate the entire recipe <span style={{ fontWeight: '600', color: 'black' }}>{recipeName}</span>?</p>
                <p>If so, what will be the name of the duplicated recipe?</p>

                <div className="text-field-container">
                    <p className="text-field-label">Recipe Name</p>
                    <TextField
                        size="small"
                        id="outlined-basic"
                        label="Recipe Name"
                        variant="outlined"
                        sx={{ textAlign: 'center' }}
                        value={duplicateRecipeName}
                        onChange={(e) => setDuplicateRecipeName(e.target.value)}
                    />
                </div>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1rem' }}>
                <Button
                    onClick={handleDialogClose}
                    size="small"
                    variant="outlined"
                    sx={{
                        width: '100%'
                    }}
                >
                    Cancel
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        color: 'white',
                        width: '100%'
                    }}
                    onClick={() => {
                        duplicateRecipe();
                    }}
                    autoFocus
                    color="primary"
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );

    const sumOfMacroNutrients = (arr) => {
        let sum = 0;
        arr.forEach((item) => {
            sum += item.value;
        });
        return Number(sum);
    }

    const renderModal = () => {
        console.log('Modal Rendered');
        return (
            <Modal open={modalOpen} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={modalBoxStyle}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}
                    >
                        <h2>Select Ingredients</h2>
                        {/* Add Search Box */}
                        <div className='search-bar' >
                            <TextField
                                autoComplete="off"
                                key="search"
                                autoFocus="autoFocus"
                                className={classes.root}
                                id="standard-basic"
                                placeholder='Search'
                                variant="outlined"
                                inputProps={{ style: { borderRadius: "50px" } }}
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                }}
                            />
                            <div className='search-icon-container' >
                                <SearchRoundedIcon />
                                <span>Search</span>
                            </div>
                        </div>
                    </div>
                    <Divider variant="middle" />
                    {loading ? (
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <ReactLoading type="spin" color="#000" height="5%" width="5%" sx={{ alignSelf: 'center' }} />
                        </div>
                    ) : allIngredients.length < 1 ? (
                        <h2>No Ingredients Found</h2>
                    ) : (
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            {allIngredients.map((ingredient, index) => (
                                <Grid item xs={2} sm={4} md={3} key={index}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={tempIngredients.some((tempIngredient) => tempIngredient.name === ingredient.name)}
                                                sx={blueCheckboxStyle}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        console.log('NEW INGREDIENT', ingredient);
                                                        const newIngredient = ingredient;
                                                        newIngredient.measurement = ""
                                                        setTempIngredients([...tempIngredients, ingredient]);
                                                        // setMacroNutrients([...macroNutrients, { ...macro, value: 0 }])
                                                    } else {
                                                        setTempIngredients(
                                                            tempIngredients.filter(
                                                                (tempIngredient) => tempIngredient.name !== ingredient.name
                                                            )
                                                        );
                                                        // setMacroNutrients(macroNutrients.filter((m) => m.id !== macro.id))
                                                    }
                                                }}
                                            />
                                        }
                                        label={ingredient.name}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    )}
                    {allIngredientsCount > 20 ? (
                        <div
                            width="100%"
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '1rem'
                            }}
                        >
                            <Pagination
                                count={Math.ceil(allIngredientsCount / 20)}
                                page={page}
                                onChange={handlePageChange}
                                variant="outlined"
                                shape="rounded"
                                size="small"
                            />
                        </div>
                    ) : null}
                    <Divider />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '1vw'
                        }}
                    >
                        <Button
                            size="small"
                            variant="outlined"
                            onClick={() => {
                                setTempIngredients(selectedIngredients)
                                setModalOpen(false);
                                setSearch('');
                            }}
                        >
                            Cancel
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            sx={{
                                color: 'white'
                            }}
                            onClick={() => {
                                // setSelectedIngredients(tempIngredients);
                                updateIngredients();
                                setModalOpen(false);
                                setSearch('');
                                setDisable(false);
                            }}
                        >
                            {isEdit ? 'Update' : 'Add'}
                        </Button>
                    </div>
                </Box>
            </Modal>
        );
    };

    const updateIngredients = async () => {
        const promises = tempIngredients.map(async (ingredient) => {
            const response = await axios.get(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/ingredient/measurement/${ingredient.ingredient_id
                }`)
            return response.data.data
        });
        const ings = tempIngredients;
        Promise.all(promises)
            .then((data) => {
                data.forEach((item, index) => {
                    if (item.length > 0) {
                        ings[index].measurementList = item
                    } else {
                        ings[index].measurementList = measurements
                    }
                })
                console.log("measurements from ing 2", ings);
                setSelectedIngredients(ings);
                setTrigger(trigger + 1);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const clubMacros = (ingred) => {
        const macros = ingred?.macro_nutrients?.map((item, index) => item.name).join(', ');
        return macros;
    };

    const handleIngredientUnitsChange = (e, ingred) => {
        if (e.target.value < 0) {
            alert('Units cannot be less than 0');
        } else {
            const newSelectedIngredients = selectedIngredients.map((item) => {
                if (item.id === ingred.id) {
                    item.units = e.target.value;
                    if (ingred.measurement) {
                        const weight = measurements.find((item) => item.name === ingred.measurement).value;
                        item.quantity = Number(e.target.value) * weight;
                    }
                }
                return item;
            });
            setSelectedIngredients(newSelectedIngredients);
        }
    };

    const handleNutritionValueChange = (e, ingred) => {
        if (e.target.value < 0) {
            alert('Value cannot be less than 0');
        } else {
            const newRecipeMacros = recipeMacros.map((item) => {
                if (item.name === ingred.name) {
                    item.value = e.target.value;
                }
                return item;
            })
            setRecipeMacros(newRecipeMacros);
        }
    }


    const handleIngredientMeasurementChange = (e, ingred) => {
        // find the ingredient in the selectedIngredients array with same id and add the value as a new property
        // console.log("Changing Measurement", ingred)
        console.log('Changing Measurement', ingred);
        const newSelectedIngredients = selectedIngredients.map((item) => {
            if (item.id === ingred.id) {
                item.measurement = e.target.value;
                if (isCustom(e.target.value)) {
                    // const newList = customIngredientMeasurement;
                    // newList.push(item.id);
                    // setCustomIngredientMeasurement(newList);
                    // $('measurement-' + item.id).val('');
                    // $('#outlined-basic-' + item.id).removeAttr('disabled');
                    setOpenCustomMeasurementModal(true);
                } else {
                    const weight = measurements.find((item) => item.name === ingred.measurement).value;
                    if (ingred.units) item.quantity = weight * parseInt(ingred.units, 10);
                    $('#outlined-basic-' + item.id).val(item.quantity);
                }

            }
            return item;
        });
        console.log('New Selected Ingredients', newSelectedIngredients);
        setIsIngredientChange(true)
        setSelectedIngredients(newSelectedIngredients);
    };

    const isCustom = (name) => {
        let flag = true;
        if (name === "Custom Value") {
            flag = true;
        } else {
            measurements.forEach((item) => {
                if (item.name === name) {
                    flag = false;
                }
            })
        }
        return flag;
    }

    const handleCustomIngredientMeasurementChange = (e, ingred) => {
        // find the ingredient in the selectedIngredients array with same id and add the value as a new property
        // console.log("Changing Measurement", ingred)

        console.log('Changing Measurement', ingred);
        const newSelectedIngredients = selectedIngredients.map((item) => {
            if (item.id === ingred.id) {
                item.measurement = e.target.value;
            }
            $('#outlined-basic-' + item.id).removeAttr('disabled');
            return item;
        });
        console.log('New Selected Ingredients', newSelectedIngredients);
        setIsIngredientChange(true)
        setSelectedIngredients(newSelectedIngredients);

    };

    const handleIngredientMeasurementChangeQuantity = (e, ingred) => {
        // find the ingredient in the selectedIngredients array with same id and add the value as a new property
        // console.log("Changing Measurement", ingred)
        console.log('Changing Measurement', ingred);
        if (e.target.value < 0) {
            alert('Value cannot be less than 0');
            $('#outlined-basic-' + ingred.id).val(e.target.value);
        }
        else {
            const newSelectedIngredients = selectedIngredients.map((item) => {
                if (item.id === ingred.id) {
                    // $('#outlined-basic-'+item.id).removeAttr('disabled');
                    item.quantity = e.target.value;
                    // const weight = measurements.find((item) => item.name === ingred.measurement).weight
                    // if (ingred.units) item.quantity = weight * parseInt(ingred.units, 10)
                    $('#outlined-basic-' + item.id).val(e.target.value);
                }
                return item;
            });
            setIsIngredientChange(true)
            setSelectedIngredients(newSelectedIngredients);
        }
    };

    console.log(allBaseTypes, 'allBaseTypes 23');

    console.log(recipeMacros, 'recipeMacros 123');

    useEffect(() => {
        handleValueChange(measurementName[0]);
    }, [measurementServing])

    useEffect(() => {
        handleEditValueChange(editMeasurementName);
    }, [
        editMeasurementName, editMeasurementServing
    ])


    const handleValueChange = (name) => {

        let unit = '';
        measurements.forEach((item) => {
            if (item.name === name) {
                unit = item.value * measurementServing;
            }
        })
        if (!isCustomMeasurement)
            setMeasurementValue(unit);
    }

    const handleValueForUnit = (name) => {
        let unit = '';
        measurements.forEach((item) => {
            if (item.name === name) {
                unit = item.unit;
            }
        })
        console.log('nameforUnit', unit);
        setMeasurementUnit(unit)
    }


    const handleValueChangeForNew = (name, newServe, id) => {
        let unitValue = '';
        let newValue = '';
        measurements.forEach((item) => {
            if (item.name === name) {
                unitValue = item.value * newServe;
                newValue = item.unit;
            }
        });
        if (!isCustomMeasurement) {
            setRecipeMeasurementForNew((prevList) =>
            prevList.map((prevItem) =>
                prevItem.id === id ? { ...prevItem, value: unitValue, unit: newValue } : prevItem
            ));
        }
      };

    const handleEditValueChange = (name) => {
        let unit = '';
        measurements.forEach((item) => {
            if (item.name === name[0]) {

                unit = item.value * editMeasurementServing;
            }

        }
        )
        console.log(name, unit, 'name');
        if (!isCustomRecipeMeasurement(name[0])) {
            setEditMeasurementValue(unit);
        }
    }

    const handleEditNutrients = async () => {
        console.log("Edit Nutrients")
        const editList = [];
        setLoading(true);
        axios.get(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/recipe/${recipeId}`).then(async (res) => {
            const recipeData = res.data.data[0];
            const originalMarcoList = recipeData.macros_nutrients;
            recipeMacros.forEach((item) => {
                const found = originalMarcoList.find((element) => {
                    let flag = false;
                    if (element.name === item.name && element.value !== item.value) {
                        flag = true;
                    }
                    return flag;
                });
                if (found) {
                    editList.push({
                        id: found.id,
                        name: item.name,
                        value: item.value
                    });
                }
            })
            try {
                console.log(editList, 'editList');
                if (editList.length > 0) {
                    let status = 200;
                    console.log(editList, 'editList');
                    editList.forEach(async (item) => {
                        const data = {
                            name: item.name,
                            value: item.value
                        }
                        try {
                            const res = await axios.patch(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/recipe/${recipeId}/macros/${item.id}`, data);
                            console.log(res, 'res');
                        }
                        catch (err) {
                            status = 400;
                            console.log(err, 'err');
                            alert("Error in updating the Nutrients List");
                            window.location.reload();
                        }

                    })
                    // window.location.reload();
                } else {
                    alert('No changes made to the Nutrients List');
                    setLoading(false);
                }
            }
            catch (err) {
                alert(err);
            }
        })

    }

    const handleCustomMeasurementModalClose = () => {
        setCustomEditFieldIndex(-1)
        setCustomMeasurementName('')
        setCustomMeasurementValue('')
        setCustomMeasurementUnit('')
        setCustomMeasurementIsMultipliable(false)
        setOpenCustomMeasurementModal(false);
    };

    const handleCustomMeasurementSave = async () => {
        if (Number(customMeasurementValue) <= 0) {
            alert("Value should be greater than 0")
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/units`, {
                name: customMeasurementName,
                value: Number(customMeasurementValue),
                unit: customMeasurementUnit,
                is_multiply: customMeasurementIsMultipliable
            })
            if (res.data.result === "success") {
                setMeasurements(res.data.data)
                setMeasurementName([`${res.data.data[0].name}`]);
                // alert(res.data.message)
                handleCustomMeasurementModalClose();
                setRecipeMeasurementForNew((prevList) =>
                prevList.map((prevItem, index) =>
                    prevItem.id === indexToEdit ? { ...prevItem, name: res.data.data[0].name,serving: 1, value: res.data.data[0].value, unit: res.data.data[0].unit  } : prevItem
                ));
                if (!IndexForCustom.custom) {
                    setCustomEditFieldIndex(-1);
                    setCustomMeasurementName('');
                    setCustomMeasurementValue('');
                    setCustomMeasurementUnit('');
                    setCustomMeasurementIsMultipliable(false);
                }
                else {
                    addCustomMeasurementInIngredients();
                }
                setTrigger(trigger + 1)
            } else {
                alert(res.data.message)
            }
        } catch (err) {
            alert(err.response.data.message)
        }
    }

    const addCustomMeasurementInIngredients = () => {
        const body = {
            ingredient_id: IndexForCustom.ing_id,
            name: customMeasurementName,
            value: Number(customMeasurementValue),
            unit: customMeasurementUnit,
        }
        axios.post(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/ingredient/measurement`, body).then(res => {
            if (res.data.result === "success") {
                const newSelectedIngredients = [...selectedIngredients]
                newSelectedIngredients[customMeasurementIndex].measurementList = res.data.data
                newSelectedIngredients[customMeasurementIndex].measurement = res.data.data[0].name
                newSelectedIngredients[customMeasurementIndex].quantity = res.data.data[0].value
                newSelectedIngredients[customMeasurementIndex].units = 1
                setSelectedIngredients(newSelectedIngredients)
                setCustomEditFieldIndex(-1);
                setCustomMeasurementName('');
                setCustomMeasurementValue('');
                setCustomMeasurementUnit('');
                setCustomMeasurementIsMultipliable(false);
            } else {
                alert(res.data.message);
            }
        }
        ).catch(err => {
            console.log(err, "Error in adding ingredient measurement");
            alert(err.response.data.message);
        }
        )
    }


    useEffect(() => {
        console.log('custom edit value', editMeasurementValue);
    }, [editMeasurementValue])

    const units = [
        {
            "display_value": "Mililitre",
            "map_value": "ml"
        },
        {
            "display_value": "Litre",
            "map_value": "l"
        },
        {
            "display_value": "Miligram",
            "map_value": "mg"
        },
        {
            "display_value": "Gram",
            "map_value": "g"
        },
        {
            "display_value": "Kilogram",
            "map_value": "kg"
        },
        {
            "display_value": "Inch",
            "map_value": "inch"
        }
    
    ]

    const renderCustomMeasurementDialog = () => {
        console.log('customBaseNameno');
        return (
            <Dialog
                open={openCustomMeasurementModal}
                onClose={handleCustomMeasurementModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <span
                            style={{
                                fontSize: 15,
                                fontWeight: 500,
                                color: '#313131',
                                paddingLeft: 0,
                                paddingRight: 0
                            }}
                        >
                            <div
                                style={{
                                    paddingLeft: 0,
                                    paddingBottom: 20,
                                    paddingTop: 0,
                                    borderBottom: '1px solid #919191',
                                    fontSize: 20,
                                    marginBottom: 20,
                                    color: '#313131'
                                }}
                            >
                                Custom Measurement
                            </div>
                            <div
                                style={{
                                    paddingLeft: 0,
                                    paddingBottom: 30,
                                    paddingTop: 0,
                                    fontSize: 18,
                                    lineHeight: '30px',
                                    marginBottom: 0,
                                    color: '#616161'
                                }}
                            >
                                Add details about the custom measurement.
                            </div>
                            <div
                                style={{
                                    paddingLeft: 0,
                                    paddingBottom: 15,
                                    paddingTop: 0,
                                    display: 'flex',
                                    color: '#313131',
                                }}
                            >
                                <Text
                                    style={{
                                        width: '50%',
                                        color: '#313131',
                                        fontSize: 18,
                                    }}
                                >
                                    Name
                                </Text>
                                <TextField
                                    variant="outlined"
                                    placeholder="Cup"
                                    sx={{ m: 0, width: '30ch' }}
                                    value={customMeasurementName}
                                    onChange={(e) => {
                                        setCustomMeasurementName(e.target.value);
                                    }
                                    }
                                />
                            </div>
                            <div
                                style={{
                                    paddingLeft: 0,
                                    paddingBottom: 15,
                                    paddingTop: 0,
                                    display: 'flex',
                                    color: '#313131'
                                }}
                            >
                                <Text
                                    style={{
                                        width: '50%',
                                        color: '#313131',
                                        fontSize: 18,
                                    }}
                                >
                                    <span>Value</span>
                                </Text>
                                <TextField
                                    type='number'
                                    variant="outlined"
                                    placeholder="250"
                                    sx={{ m: 0, width: '30ch' }}
                                    value={customMeasurementValue}
                                    onChange={(e) => {
                                        setMeasurementValue(e.target.value);
                                        setCustomMeasurementValue(e.target.value);
                                    }
                                    }
                                />
                            </div>
                            <div
                                style={{
                                    paddingLeft: 0,
                                    paddingBottom: 15,
                                    paddingTop: 0,
                                    display: 'flex',
                                    color: '#313131'
                                }}
                            >
                                <Text
                                    style={{
                                        width: '50%',
                                        color: '#313131',
                                        fontSize: 18,
                                    }}
                                >
                                    <span>Unit</span>
                                </Text>
                                <Select
                                    value={customMeasurementUnit}
                                    sx={{width:'70%'}}
                                    style={{ boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px" }}
                                    onChange={(e) => {
                                        setCustomMeasurementUnit(e.target.value);
                                        setMeasurementUnit(e.target.value);
                                    }}
                                >
                                    {
                                        units.map((item, index) => (
                                            <MenuItem value={item.map_value}>{item.display_value}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </div>
                            <div
                                style={{
                                    paddingLeft: 0,
                                    paddingBottom: 15,
                                    paddingTop: 0,
                                    display: 'flex',
                                    color: '#313131'
                                }}
                            >
                                <Text
                                    style={{
                                        width: '70%',
                                        color: '#313131',
                                        fontSize: 18,
                                    }}
                                >
                                    <span>Is the unit multipliable ? </span>
                                </Text>
                                <div style={
                                    {
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'row'
                                    }
                                }>
                                    <Checkbox
                                        checked={customMeasurementIsMultipliable}
                                        onChange={(e) => {
                                            setCustomMeasurementIsMultipliable(e.target.checked);
                                        }
                                        }
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <Text>Yes</Text>
                                </div>

                            </div>
                        </span>
                        <p
                            style={{
                                fontSize: 14,
                                color: '#D32C2C',
                                textAlign: 'center',
                                padding: 0,
                                margin: 0
                            }}
                        >
                            {/* {failureMessage} */}
                        </p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            borderTop: '1px solid #919191',
                            paddingTop: 20,
                            paddingBottom: 10
                        }}
                    >
                        <Button
                            onClick={handleCustomMeasurementModalClose}
                            style={{
                                border: '1px solid #1B99DF',
                                background: '#FFFFFF',
                                borderRadius: 10,
                                color: '#1B99DF',
                                fontSize: 15,
                                fontWeight: 500,
                                padding: '6px 75px',
                                margin: '0 5px'
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                console.log('confirm clicked');
                                handleCustomMeasurementSave();
                            }}
                            style={{
                                border: '1px solid #1B99DF',
                                background: '#1B99DF',
                                borderRadius: 10,
                                color: '#ffffff',
                                fontSize: 15,
                                fontWeight: 500,
                                padding: '6px 75px',
                                margin: '0 5px'
                            }}
                        >
                            Confirm
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        )
    };

    useEffect(() => {
        const getTagSuggestions = async () => {
            try {
                const apiUrl = `${process.env.REACT_APP_BASEURL}admin/nutrition_plans/recipe/tags?search=${tagsearch}`;
                const response = await axios.get(apiUrl);

                setTagSuggestions(response.data.data);
                console.log("##ingredients", response.data);
                //  setIsLoading(false); 
            } catch (error) {
                console.error('There was a problem with the Axios request:', error);
                // setIsLoading(false);
            }
        };
        getTagSuggestions();
    }, [tagsearch]);

    const handleTagsChange = (event, finalTags) => {
        // setTagsArrayObj(finalTags)
        // console.log(finalTags, '##finaltags')
        setDisable(false)
        const tagsMap = new Map();
        finalTags.forEach(tag => {
          const trimmedTag = tag.trim().toLowerCase(); // For case-insensitive comparison
          if (trimmedTag.length > 0 && !tagsMap.has(trimmedTag)) {
            tagsMap.set(trimmedTag, tag.trim());
          }
        });
        const tagsArray = Array.from(tagsMap.values()).map(tag => ({ name: tag }));
        setRecipeTags(tagsArray);
    };


    const defaultTags = recipeTags.map((defaultTag => defaultTag.name));


    return (
        <MainCard>
            <Header>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        fontSize: '1.5rem',
                    }}
                >
                    <ArrowCircleLeftIcon
                        onClick={() => {
                            navigate(-1);
                        }}
                        sx={{ cursor: 'pointer', color: '#02a9f4' }}
                    />
                    &nbsp;&nbsp;
                    <span> {isEdit ? 'Edit Recipe' : 'Add New Recipe'} </span>
                </div>
                <div>
                    {isEdit && (
                        <Button
                            startIcon={<ContentCopyIcon />}
                            color="primary"
                            variant="contained"
                            sx={{
                                color: 'white'
                            }}
                            onClick={() => {
                                setDialogOpen(true);
                            }}
                        >
                            Duplicate Recipe
                        </Button>
                    )}
                    &nbsp;&nbsp;
                    {
                        !isEdit ?
                            (
                                <Button
                                    // disabled={!checkIfAllFieldsAreFilled()}
                                    startIcon={!isEdit && <AddIcon />}
                                    color="primary"
                                    variant="contained"
                                    sx={{
                                        color: 'white'
                                    }}
                                    onClick={addNewRecipe}
                                >
                                    Add Recipe
                                </Button>
                            ) :
                            (
                                <Button
                                    disabled={Disable}
                                    startIcon={!isEdit && <AddIcon />}
                                    color="primary"
                                    variant="contained"
                                    sx={{
                                        color: 'white'
                                    }}
                                    onClick={updateRecipe}
                                >
                                    Update Recipe
                                </Button>
                            )
                    }


                    {renderDialog()}
                </div>
            </Header>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <GrayBox>
                <div className="recipe-basics">
                    <div className={windowWidth > 700 ? 'row' : 'column'}>
                        <div style={{ width: '100%' }}>
                            <p style={{ color: 'black', fontWeight: '500' }}>Recipe Name</p>
                            <TextField
                                id="outlined-basic"
                                placeholder="Recipe Name"
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: '25vw', width: '100%' }}
                                value={recipeName}
                                onChange={(e) => {
                                    setRecipeName(e.target.value);
                                    setDisable(false);
                                }}
                            />
                        </div>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <div style={{ width: '100%' }}>
                            <p style={{ color: 'black', fontWeight: '500' }}>Recipe Image</p>
                            <TextField
                                InputProps={{
                                    startAdornment: (
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            sx={{
                                                color: 'white'
                                            }}
                                            size="small"
                                            component="label"
                                        >
                                            Browse
                                            <input hidden accept="image/*" type="file" onChange={handleImageUpload} />
                                        </Button>
                                    )
                                }}
                                id="outlined-basic"
                                placeholder="Paste Image Link"
                                variant="outlined"
                                size="small"
                                sx={{
                                    minWidth: '25vw',
                                    width: '100%',
                                    // shift the placeholder a little bit to the right
                                    '& .MuiInputBase-input::placeholder': {
                                        paddingLeft: '0.5rem'
                                    }
                                }}
                                value={recipeImageLink}
                                onChange={(e) => {
                                    setRecipeImageLink(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className={windowWidth > 700 ? 'row' : 'column'}>
                        <div style={{ width: '49%' }}>
                            <p style={{ color: 'black', fontWeight: '500' }}>Recipe Type</p>
                            <FormControl sx={{ minWidth: '36vw', width: '100%' }} size="small">
                                <InputLabel id="demo-simple-select-label">Recipe Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    multiple
                                    value={recipeTypeForMultiSelect}
                                    label="Results per Page"
                                    onChange={(event) => {
                                        handleRecipeTypeChange(event);
                                        setDisable(false);
                                    }}
                                    renderValue={(recipeTypeForMultiSelect) => recipeTypeForMultiSelect.join(',')}
                                >
                                    {allBaseTypes?.map(
                                        (item, index) => item.name !== 'All' && <MenuItem value={item.name}>{item.name}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                        &nbsp;&nbsp;&nbsp;&nbsp;

                            <div style={{ width: '50%' }}>
                                <FormControl sx={{ minWidth: '36vw', width: '100%' }} size="small">
                                <p style={{ color: 'black', fontWeight: '500' }}>Recipe Tags</p>
                               <Autocomplete
                                    multiple
                                    id="tags-filled"
                                    options={tagSuggestions.map((option => option.name))}
                                    value={recipeTags.map((tag) => tag.name)}
                                    size="small"
                                    sx={{ minWidth: '25vw', width: '100%' }}
                                    disableClearable
                                    freeSolo
                                    renderTags={(value, getTagProps) =>
                                        value
                                          .filter(option => option.trim()).map((option, index) => (
                                                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                        ))
                                    }
                                    onInputChange={(event, newInputValue)=>{
                                        const value = newInputValue.trim()
                                        settagsearch(value);
                                    }}
                                    onChange={handleTagsChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Tags (Press enter to add custom tags)"
                                        />
                                    )}
                                    renderOption={(props, option, { inputValue, selected }) => (
                                        <li {...props}>
                                            {console.log('first',option)}
                                            <span style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                                {option} {selected && <CheckIcon fontSize="small" />}
                                            </span>
                                        </li>
                                    )}         
                                />
                                </FormControl>
                            </div>
                        {/* <div style={{ width: '100%' }}>
                            <p style={{ color: 'black', fontWeight: '500' }}>Tags</p>
                            <TextField
                                id="outlined-basic"
                                placeholder="Tags (Add tags separated by comma)"
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: '25vw', width: '100%' }}
                                value={recipeTags}
                                onChange={(e) => {
                                    setRecipeTags(e.target.value);
                                    setDisable(false);
                                }}
                        />
                        </div> */}
                    </div>
                    <Divider
                        sx={{
                            marginY: '1rem',
                            backgroundColor: '#d3d3d3',
                            height: '3px'
                        }}
                    />
                    {isEdit ? (
                        <div>
                            <div className='recipe-measurement' style={!(isAddingRecipeMeasurement || recipeMeasurementList.length > 0) ? { paddingBottom: 'none' } : { paddingBottom: '10' }} >
                                <div className={windowWidth > 700 ? 'row ' : 'column'} style={((isAddingRecipeMeasurement || recipeMeasurementList.length > 0)) ? { borderBottom: 'none' } : { borderBottom: '1px solid #d3d3d3' }}>
                                    <span className='heading'>Recipe Measurement</span>
                                    <Button
                                        startIcon={<AddIcon />}
                                        color="primary"
                                        variant="contained"
                                        sx={{
                                            color: 'white'
                                        }}
                                        onClick={() => {
                                            handleRecipeMeasrementAddition();
                                            setDisable(false);
                                        }}
                                    >
                                        Add
                                    </Button>
                                </div>
                                {recipeMeasurementList.map((item, index) => (
                                    <div>
                                        <div
                                            className={
                                                windowWidth > 700
                                                    ? measurementEditIndex === index
                                                        ? 'measurement-row-input'
                                                        : 'measurement-row'
                                                    : 'column'
                                            }
                                        >
                                            {measurementEditIndex !== index ? (
                                                <p
                                                    className="measurement-name"
                                                    style={{
                                                        color: 'black',
                                                        background: '#eaeaea',
                                                        minWidth: '28vw',
                                                        width: '30%',
                                                        marginRight: '1rem',
                                                        borderRadius: '10px',
                                                        padding: '10px'
                                                    }}
                                                >
                                                    {item.name}
                                                </p>
                                                ) : (
                                                <div>
                                                    <FormControl sx={{ minWidth: '28vw', width: '30%' }} size="small">
                                                        <InputLabel id="demo-simple-select-label">Measurement</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="Measurement"
                                                            value={editMeasurementName}
                                                            sx={{ minWidth: '28vw', width: '30%', marginRight: '1rem' }}
                                                            onChange={(event) => {
                                                                console.log('helllooo',event.target.value)
                                                                const newName = event.target.value;
                                                                setDisable(false);
                                                                if (newName === 'Custom Value') {
                                                                    setOpenCustomMeasurementModal(true);
                                                                    // setCustomEditFieldIndex(index);
                                                                } else {
                                                                    setDisable(false);
                                                                    handleEditMeasurementChange(event);
                                                                }
                                                            }}
                                                            >
                                                            {measurements?.map(
                                                                (item, index) =>
                                                                item.name !== 'All' && (
                                                                    <MenuItem key={index} value={item.name}>
                                                                    {item.name}
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                            <MenuItem value="Custom Value">Custom Value</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    {/* <FormControl sx={customEditFieldIndex === index ? { minWidth: '120', width: '80%' } : { minWidth: '120', width: '80%', display: 'none' }} size="small">
                                                        <InputLabel id="demo-simple-select-label">Measurement</InputLabel>
                                                        <TextField
                                                            type="text"
                                                            id="outlined-basic"
                                                            placeholder="Measurement"
                                                            label="Measurement"
                                                            variant="outlined"
                                                            size="small"
                                                            sx={{ minWidth: '28vw', width: '30%', marginRight: '1rem', marginTop: '0.2rem' }}
                                                            value={editMeasurementName}
                                                            onChange={(event) => {
                                                                setDisable(false);
                                                                handleEditMeasurementChange(event);
                                                            }}
                                                        />
                                                    </FormControl> */}
                                                </div>
                                            )}
                                            <p>Serving</p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            {measurementEditIndex !== index ? (
                                                <p
                                                    className="measurement-value"
                                                    style={{
                                                        color: 'black',
                                                        background: '#eaeaea',
                                                        minWidth: '10vw',
                                                        width: '20%',
                                                        marginRight: '1rem',
                                                        borderRadius: '10px',
                                                        padding: '10px'
                                                    }}
                                                >
                                                    {item.serving}
                                                </p>
                                            ) : (
                                                <div>
                                                    <TextField
                                                        type="number"
                                                        id="outlined-basic"
                                                        placeholder="Serving"
                                                        variant="outlined"
                                                        size="small"
                                                        sx={{ minWidth: '10vw', width: '20%', marginRight: '1rem' }}
                                                        value={editMeasurementServing}
                                                        onChange={(e) => {
                                                            setDisable(false);
                                                            setEditMeasurementServing(e.target.value)
                                                            handleEditValueChange(editMeasurementName)
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            {measurementEditIndex !== index ? (
                                                <p
                                                    className="measurement-value"
                                                    style={{
                                                        color: 'black',
                                                        background: '#eaeaea',
                                                        minWidth: '10vw',
                                                        width: '20%',
                                                        marginRight: '1rem',
                                                        borderRadius: '10px',
                                                        padding: '10px'
                                                    }}
                                                >
                                                    {item.value}
                                                </p>
                                            ) : (
                                                <TextField
                                                    // disabled={index === customEditFieldIndex ? false : true}
                                                    type="number"
                                                    id="outlined-basic"
                                                    placeholder="Value"
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{ minWidth: '10vw', width: '13%', marginLeft:'0.8rem' , marginRight: '1rem' }}
                                                    value={editMeasurementValue}
                                                    onChange={(e) => {
                                                        setDisable(false);
                                                        setEditMeasurementValue(e.target.value);
                                                    }}
                                                />
                                            )}
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            {measurementEditIndex !== index ? (
                                                <p
                                                    className="measurement-value"
                                                    style={{
                                                        color: 'black',
                                                        background: '#eaeaea',
                                                        minWidth: '4vw',
                                                        width: '5%',
                                                        marginRight: '1rem',
                                                        borderRadius: '10px',
                                                        padding: '10px'
                                                    }}
                                                >
                                                    {item.unit}
                                                </p>
                                            ) : ( 
                                                <div>
                                                    <TextField
                                                        id="outlined-basic"
                                                        placeholder="Value"
                                                        variant="outlined"
                                                        size="small"
                                                        sx={{ minWidth: '4vw', width: '5%', marginRight: '1rem'}}
                                                        value={editMeasurementUnit}
                                                        onChange={(e) => {
                                                            setDisable(false);
                                                            setEditMeasurementUnit(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <div className='measurement-button-container'>
                                                {measurementEditIndex !== index ? (
                                                    <IconButton
                                                        // disabled={measurementEditIndex !== index ? true : false}
                                                        color="primary"
                                                        aria-label='create'
                                                        sx={{
                                                            color: "primary"
                                                        }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            if (measurementEditIndex !== index) {
                                                                setMeasurementEditIndex(index);
                                                                setEditMeasurementName([item.name]);
                                                                setEditMeasurementValue(item.value);
                                                                setEditMeasurementUnit(item.unit);
                                                                setEditMeasurementServing(item.serving);
                                                                if (isCustomRecipeMeasurement(item.name)) {
                                                                    setCustomEditFieldIndex(index);
                                                                    setEditMeasurementValue(item.value);
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        <CreateIcon />
                                                    </IconButton>
                                                ) : (
                                                    <IconButton
                                                        // disabled={measurementEditIndex !== index ? true : false}
                                                        color="primary"
                                                        variant="contained"
                                                        sx={{
                                                            color: 'primary'
                                                        }}
                                                        onClick={(e) => {
                                                            const measurementName = Array.isArray(editMeasurementName) && editMeasurementName.length > 0 && editMeasurementName[0]
                                                                ? editMeasurementName[0]
                                                                : editMeasurementName;
                                                            if (isEdit) {
                                                                if (
                                                                    recipeMeasurementList[index].name === measurementName &&
                                                                    recipeMeasurementList[index].value === editMeasurementValue &&
                                                                    recipeMeasurementList[index].serving === editMeasurementServing && 
                                                                    recipeMeasurementList[index].serving === editMeasurementUnit
                                                                ) {
                                                                    setMeasurementEditIndex(-1);
                                                                } else {
                                                                    updateRecipeMeasurement(item.id, measurementName, editMeasurementValue, editMeasurementServing, editMeasurementUnit);
                                                                }
                                                            } else {
                                                                e.preventDefault();
                                                                const temp = recipeMeasurementList;
                                                                temp[index].name = editMeasurementName;
                                                                temp[index].value = editMeasurementValue;
                                                                temp[index].serving = editMeasurementServing;
                                                                temp[index].unit = editMeasurementUnit;
                                                                setRecipeMeasurementList(temp);
                                                            }
                                                            setMeasurementEditIndex(-1);
                                                        }}
                                                    >
                                                        <DoneIcon />
                                                    </IconButton>
                                                )}
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <IconButton
                                                    //  disabled={measurementEditIndex !== index ? true : false}
                                                    aria-label='delete'
                                                    sx={{
                                                        color: 'red',
                                                    }}
                                                    onClick={(e) => {
                                                        if (isEdit) {
                                                            deleteRecipeMeasurement(item.id);
                                                        } else {
                                                            e.preventDefault();
                                                            const temp = recipeMeasurementList.filter((item, i) => i !== index);
                                                            setRecipeMeasurementList(temp);
                                                        }
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {isAddingRecipeMeasurement && (
                                    <div className={windowWidth > 700 ? 'measurement-row-input' : 'column'}>
                                        {!isCustomMeasurement ? (
                                            <FormControl sx={{ minWidth: '28vw', width: '30%', marginRight: '1rem' }} size="small">
                                                <InputLabel id="demo-simple-select-label">Measurement</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Measurement"
                                                    value={measurementName}
                                                    sx={{ minWidth: '28vw', width: '30%', marginRight: '1rem' }}
                                                    onChange={(event) => {
                                                        console.log('helllooo',event.target.value)
                                                        const newName = event.target.value;
                                                        if (newName === 'Custom Value') {
                                                            setOpenCustomMeasurementModal(true);
                                                            // setCustomEditFieldIndex(index);
                                                        } else {
                                                            setMeasurementName([event.target.value])
                                                            handleValueChange(event.target.value)
                                                            handleValueForUnit(event.target.value)
                                                        }
                                                    }}
                                                    >
                                                    {measurements?.map(
                                                        (item, index) =>
                                                        item.name !== 'All' && (
                                                            <MenuItem key={index} value={item.name}>
                                                            {item.name}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                    <MenuItem value="Custom Value">Custom Value</MenuItem>
                                                </Select>
                                            </FormControl>
                                        ) : (
                                            <FormControl sx={{ minWidth: '28vw', width: '30%' }} size="small">
                                                <TextField
                                                    type="text"
                                                    label="Measurement"
                                                    disabled={!isAddingRecipeMeasurement}
                                                    id="outlined-basic"
                                                    placeholder="Measurement"
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{ minWidth: '28vw', width: '30%', marginRight: '1rem', marginTop: '0.2rem' }}
                                                    value={measurementName}
                                                    onChange={(event) => {
                                                        setMeasurementName([event.target.value]);
                                                    }}
                                                />
                                            </FormControl>
                                        )}
                                        <p>Serving</p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <TextField
                                            type="number"
                                            disabled={!isAddingRecipeMeasurement}
                                            id="outlined-basic"
                                            placeholder="Serving"
                                            variant="outlined"
                                            size="small"
                                            sx={{ minWidth: '10vw', width: '5%', marginRight: '1rem' }}
                                            value={measurementServing}
                                            onChange={(e) => {
                                                setMeasurementServing(e.target.value);
                                                handleValueChange(measurementName[0])
                                            }}
                                        />
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <TextField
                                            disabled={!isCustomMeasurement}
                                            type="number"
                                            id="outlined-basic"
                                            placeholder="Value"
                                            variant="outlined"
                                            size="small"
                                            sx={{ minWidth: '10vw', width: '13%', marginRight: '2rem' }}
                                            value={measurementValue}
                                            onChange={(e) => {
                                                setMeasurementValue(e.target.value);
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            placeholder="Value"
                                            variant="outlined"
                                            size="small"
                                            sx={{ minWidth: '4vw', width: '5%', marginRight: '1rem' }}
                                            value={measurementUnit}
                                            onChange={(e) => {
                                                setMeasurementUnit(e.target.value);
                                            }}
                                        />
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <div className='measurement-button-container'>
                                            <IconButton
                                                disabled={!isAddingRecipeMeasurement}
                                                color="primary"
                                                variant="contained"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (isEdit) {
                                                        addRecipeMeasurement(measurementName, measurementValue, measurementServing, measurementUnit);
                                                    } else {
                                                        setRecipeMeasurementList([
                                                            ...recipeMeasurementList,
                                                            { name: measurementName, value: measurementValue, serving: measurementServing, unit: measurementUnit }
                                                        ]);
                                                        setMeasurementName([]);
                                                        setMeasurementValue('');
                                                        setMeasurementServing('');
                                                        setMeasurementUnit('');
                                                    }
                                                }}
                                            >
                                                <DoneIcon />
                                            </IconButton>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <IconButton
                                                disabled={!isAddingRecipeMeasurement}
                                                variant="contained"
                                                sx={{
                                                    color: 'red',
                                                }}
                                                onClick={() => {handleClearMeasurementValues();}}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className="recipe-measurement" style={{ paddingBottom: 'none' }} >
                                <div className={windowWidth > 700 ? 'row ' : 'column'} style={{ borderBottom: 'none' }}>
                                    <span className='heading'>Recipe Measurement</span>
                                    <Button
                                        // disabled={!checkIfAllFieldsAreFilled()}
                                        startIcon={<AddIcon />}
                                        color="primary"
                                        variant="contained"
                                        sx={{
                                            color: 'white'
                                        }}
                                        onClick={() => {
                                            handleRecipeMeasurementAddition();
                                        }}
                                    >
                                        Add
                                    </Button>
                                </div>
                                    {recipeMeasurementForNew.map((item, index) => (
                                    <div>   
                                        <div key={item.id} className='measurement-row-input'>
                                            <div>
                                            <FormControl sx={{ minWidth: '120', width: '80%' }} size="small">
                                                <InputLabel id="demo-simple-select-label">Measurement</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Measurement"
                                                value={item.name}
                                                sx={{ minWidth: '28vw', width: '30%', marginRight: '1rem' }}
                                                onChange={(event) => {
                                                    const newName = event.target.value;
                                                    setindexToEdit(item.id)
                                                    if (newName === 'Custom Value') {
                                                        setOpenCustomMeasurementModal(true);
                                                        // setCustomEditFieldIndex(index);
                                                    } else {
                                                        setRecipeMeasurementForNew((prevList) =>
                                                        prevList.map((prevItem) =>
                                                            prevItem.id === item.id ? { ...prevItem, name: newName, serving: 1 } : prevItem
                                                        ));
                                                        handleValueChangeForNew(newName, 1, item.id);
                                                    }
                                                  }}
                                                >
                                                {measurements?.map(
                                                    (item, index) =>
                                                    item.name !== 'All' && (
                                                        <MenuItem key={index} value={item.name}>
                                                        {item.name}
                                                        </MenuItem>
                                                    )
                                                )}
                                                <MenuItem value="Custom Value">Custom Value</MenuItem>
                                                </Select>
                                            </FormControl>
                                            </div>
                                            <p style={{margin:'1rem'}}>Serving</p>
                                            <div style={{ display: 'flex', alignItems: 'center', width: 'min-content' }}>
                                                <TextField
                                                    type="number"
                                                    id="outlined-basic"
                                                    placeholder="Serving"
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{ minWidth: '10vw', width: '20%', marginRight: '1rem' }}
                                                    value={item.serving}
                                                    onChange={(event) => {
                                                        const newServe = parseInt(event.target.value, 10);
                                                        setRecipeMeasurementForNew((prevList) =>
                                                        prevList.map((prevItem) =>
                                                            prevItem.id === item.id ? { ...prevItem, serving: newServe } : prevItem
                                                        ));
                                                        handleValueChangeForNew(item.name, newServe, item.id);
                                                    }}
                                                />
                                            </div>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <div  style={{ display: 'flex', alignItems: 'center', width: 'min-content', marginRight:'1rem' }}>
                                            <TextField
                                                type="number"
                                                id="outlined-basic"
                                                placeholder="Value"
                                                variant="outlined"
                                                size="small"
                                                sx={{ minWidth: '10vw', width: '20%', marginRight: '1rem' }}
                                                value={item.value}
                                                onChange={(event) => {
                                                    const newServe = parseInt(event.target.value, 10);
                                                    setRecipeMeasurementForNew((prevList) =>
                                                    prevList.map((prevItem) =>
                                                        prevItem.id === item.id ? { ...prevItem, value: newServe } : prevItem
                                                    ));
                                                }}
                                            />
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', width: 'min-content', marginRight:'0.5rem' }}>
                                                <TextField
                                                    id="outlined-basic"
                                                    placeholder="Value"
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{ minWidth: '4vw', width: '10%', marginRight: '0.5rem' }}
                                                    value={item.unit}
                                                    onChange={(event) => {
                                                        const newServe = event.target.value;
                                                        setRecipeMeasurementForNew((prevList) =>
                                                        prevList.map((prevItem) =>
                                                            prevItem.id === item.id ? { ...prevItem, unit: newServe } : prevItem
                                                        ));
                                                    }}
                                                />
                                            </div>
                                            <div style={{marginRight:'auto'}}>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            {index !== 0 && (<IconButton
                                                variant="contained"
                                                sx={{
                                                    color: 'red',
                                                }}
                                                onClick={() => {handleRemoveRow(item.id)}}
                                                >
                                                <ClearIcon />
                                            </IconButton>)}
                                          </div>  
                                            
                                        </div>  
                                    </div>      
                                    ))}
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                            <Divider
                                sx={{
                                    marginY: '1rem',
                                    backgroundColor: '#d3d3d3',
                                    height: '3px'
                                }}
                            />
                        </div>
                    )}
                    <div className={windowWidth > 700 ? 'row' : 'column'}>
                        <div style={{ width: '100%' }}>
                            <p style={{ color: 'black', fontWeight: '500', fontSize: '1rem' }}>Description</p>
                            <TextField
                                id="outlined-multiline-flexible"
                                placeholder="Describe your Recipe"
                                multiline
                                rows={4}
                                value={recipeDescription}
                                onChange={(e) => {
                                    setDisable(false);
                                    setRecipeDescription(e.target.value);
                                }}
                                sx={{ minWidth: '25vw', width: '100%' }}
                            />
                        </div>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <div style={{ width: '100%' }}>
                            <p style={{ color: 'black', fontWeight: '500', fontSize: '1rem' }}>Cooking Method</p>
                            <TextField
                                id="outlined-multiline-flexible"
                                placeholder="How should I cook my meal?"
                                multiline
                                rows={4}
                                value={recipeCookingMethod}
                                onChange={(e) => {
                                    setDisable(false);
                                    setRecipeCookingMethod(e.target.value);
                                }}
                                sx={{ minWidth: '25vw', width: '100%' }}
                            />
                        </div>
                    </div>
                    <Divider
                        sx={{
                            marginY: '1rem',
                            backgroundColor: '#d3d3d3',
                            height: '3px'
                        }}
                    />
                    {(recipeMacros.length > 0 && selectedIngredients.length > 0) && (
                        <>
                            <div className='recipe-data-container' >
                                <div className={windowWidth > 700 ? 'row header' : 'column'} style={{ borderBottom: '1px solid #d3d3d3' }}>
                                    <span className='heading'>Nutrition Profile</span>
                                    <div style={{ display: 'flex' }} >
                                        <p>
                                            <span style={{
                                                fontSize: '1rem',
                                                fontWeight: '500',
                                                backgroundColor: '#eaeaea',
                                                color: '#919191',
                                                padding: '10px 20px',
                                                borderRadius: '10px',
                                                marginRight: '10px'
                                            }} >
                                                {calories}
                                            </span>
                                            <span>
                                                kcal
                                            </span>
                                        </p>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Button
                                            disabled
                                            startIcon={isEditingNutritionProfile ? <DoneIcon /> : <EditIcon />}
                                            color="primary"
                                            variant="contained"
                                            sx={{
                                                color: 'white'
                                            }}
                                            onClick={() => {
                                                if (!isEditingNutritionProfile)
                                                    setIsEditingNutritionProfile(true);
                                                else {
                                                    setIsEditingNutritionProfile(false);
                                                    setLoading(true);
                                                    handleEditNutrients();
                                                }
                                            }}
                                        >
                                            {isEditingNutritionProfile ? 'Save Nutritients' : 'Edit Nutritients'}
                                        </Button>
                                    </div>

                                </div>
                                &nbsp;
                                <Grid
                                    container
                                    spacing={{ xs: 2, md: 3 }}
                                    columns={{ xs: 4, sm: 8, md: 12 }}
                                    sx={{
                                        padding: '1rem',
                                        overflow: 'auto',
                                        maxHeight: '50vh',
                                        overflowX: 'hidden'
                                    }}
                                >
                                    {recipeMacros?.map((macro, index) => (
                                        <Grid item xs={2} sm={4} md={3} key={index}>
                                            <div style={{
                                                display: 'flex',
                                                width: '100%',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }} >
                                                <div
                                                    style={{ width: '50%' }}
                                                >{macro.name}</div>
                                                <div
                                                    style={{
                                                        width: '50%',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <TextField
                                                        disabled={isEditingNutritionProfile ? false : true}
                                                        id="outlined-basic"
                                                        placeholder="Ingredient Name"
                                                        variant="outlined"
                                                        size="small"
                                                        className={!isEditingNutritionProfile ? classes.disabledButton : ''}
                                                        sx={{ width: '50%' }}
                                                        value={macro.value}
                                                        onChange={(e) => { handleNutritionValueChange(e, macro) }}
                                                    />
                                                    &nbsp;&nbsp;
                                                    <span>{macro.unit === 'g' ? 'gm' : macro.unit}</span>
                                                </div>
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                            </div>
                            <Divider
                                sx={{
                                    marginY: '1rem',
                                    backgroundColor: '#d3d3d3',
                                    height: '3px'
                                }}
                            />
                        </>
                    )}
                    <div className='recipe-data-container'>
                        <div className={windowWidth > 700 ? 'row header' : 'column'} style={{ borderBottom: '1px solid #d3d3d3' }}>
                            <span className='heading'>Add Ingredients</span>
                            <div style={{display: 'flex'}}>
                            <p>
                                <span style={{
                                    fontSize: '1rem',
                                    fontWeight: '500',
                                    backgroundColor: '#eaeaea',
                                    color: '#919191',
                                    padding: '10px 20px',
                                    borderRadius: '10px',
                                    marginRight: '10px'
                                }} >
                                    {TotalGm}
                                </span>
                                <span>
                                    gm
                                </span>
                            </p>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Button
                                startIcon={<AddIcon />}
                                color="primary"
                                variant="contained"
                                sx={{
                                    color: 'white'
                                }}
                                onClick={() => {
                                    setModalOpen(true);
                                }}
                            >
                                Add Ingredients
                            </Button>
                            </div>
                            {renderModal()}
                        </div>
                        {selectedIngredients?.length > 0 && (
                            <table>
                                <thead>
                                    <tr className="header">
                                        <th>Ingredient</th>
                                        &nbsp;
                                        {/* <th>Macro-Nutrients</th>
                                    &nbsp; */}
                                        <th>No. of Units</th>
                                        &nbsp;
                                        <th>Measurement</th>
                                        &nbsp;
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedIngredients?.map((item, index) => (
                                            <tr>
                                                <td style={{
                                                    width: '35%',
                                                }} >
                                                    <div style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                        width: '100%'

                                                    }}>
                                                        <div>
                                                            <FiberManualRecordIcon sx={item.is_active ? { color: 'green', fontSize: '1rem' } : { color: 'red', fontSize: '1rem' }} />
                                                        </div>
                                                        <div style={{
                                                            borderRadius: '8px',
                                                            border: '1px solid #c0c0c0',
                                                            width: "93%",
                                                            margin: '0 2%',
                                                            padding: '10px 15px',
                                                            cursor: 'pointer',
                                                            hover: {
                                                                border: '1px solid #black',
                                                            }
                                                        }}
                                                            onClick={() => {
                                                                const item1 = {
                                                                    ...item,
                                                                    id: item.ingredient_id,
                                                                }

                                                                navigate("/nutrition/ingredients/add", { state: { item: item1 } })
                                                            }}
                                                        >
                                                            <span style={{ fontSize: '0.9rem', color: "black" }} >{item.name}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                &nbsp;
                                                <td>
                                                    <TextField
                                                        type="number"
                                                        id="outlined-basic"
                                                        placeholder="No. of Units"
                                                        variant="outlined"
                                                        size="small"
                                                        sx={{ width: '100%' }}
                                                        value={item.units ? item.units : ''}
                                                        inputProps={{
                                                            min: 0
                                                        }}
                                                        onChange={(e) => {
                                                            setDisable(false);
                                                            let value = parseFloat(e.target.value);
                                                            if (value < 0) value = 0;
                                                            handleIngredientMeasurementChangeV1(
                                                                { target: { value } },
                                                                index,
                                                                "units",
                                                                item.measurementList
                                                            );
                                                        }}
                                                    />
                                                </td>
                                                &nbsp;
                                                <td>
                                                    <div className="row">
                                                        <FormControl sx={{ width: '100%' }} size="small">
                                                            {/* <InputLabel id="demo-simple-select-label">Measurement</InputLabel> */}
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id={`measurement-${item.id}`}
                                                                value={item.measurement}
                                                                // label="Measurement"
                                                                renderValue={(value) => value}
                                                                onChange={(event) => {
                                                                    setDisable(false);
                                                                    // handleIngredientMeasurementChange(event, item);
                                                                    handleIngredientMeasurementChangeV1(event, index, "measurement", item.measurementList, item.ingredient_id)
                                                                }}
                                                            >
                                                                {
                                                                    (item.measurementList.length > 0 ? item.measurementList : measurements).map((mes, index) => (
                                                                        <MenuItem key={mes.id} value={mes.name}>{mes.name}</MenuItem>
                                                                    ))
                                                                }
                                                                <MenuItem value="Custom Value">Custom Value</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        &nbsp;
                                                        <TextField
                                                            disabled
                                                            type="number"
                                                            id={'outlined-basic-' + item.id}
                                                            placeholder="Weight in grams"
                                                            variant="outlined"
                                                            size="small"
                                                            sx={{ width: '100%' }}
                                                            value={item.quantity ? item.quantity : ''}
                                                            onChange={(event) => {
                                                                setDisable(false);
                                                                if (event.target.value < 0) {
                                                                    alert('Quantity cannot be negative');
                                                                    event.target.value = 0;
                                                                } else {
                                                                    handleIngredientMeasurementChangeQuantity(event, item)
                                                                }
                                                            }}
                                                        />
                                                        &nbsp;
                                                        <p style={{ width: "30%", textAlign: "center" }} >{getUnitFromMeasuremetArray(item.measurement, item.measurementList)}</p>
                                                    </div>
                                                </td>
                                                &nbsp;
                                                <td>
                                                    <IconButton
                                                        aria-label="delete"
                                                        sx={{
                                                            color: 'red'
                                                        }}
                                                        onClick={() => {
                                                            setDisable(false);
                                                            setIsIngredientChange(true);
                                                            // remove the ingredient from selected ingredients
                                                            const temp = selectedIngredients.filter((ing) => ing.name !== item.name);
                                                            setSelectedIngredients([...temp]);
                                                            setTotalGm(temp.length === 0 ? '' : TotalGm)
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </GrayBox>
            {renderCustomMeasurementDialog()}
            <Snackbar open={open} autoHideDuration={4000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity="success" sx={{ width: '100%' }}>
                {toastMessage}
                </Alert>
            </Snackbar>
        </MainCard >
    );
};

export default AddNewRecipe;