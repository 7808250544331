/* eslint-disable global-require */
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import styled from 'styled-components';
import { Button, TextareaAutosize, TextField, FormLabel, Input } from '@mui/material';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useNavigate } from 'react-router-dom';

const AddBarcode = () => {
    const [toggle, setToggle] = React.useState(false);
    const [file, SetFile] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const imageChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            SetFile(e.target.files[0]);
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleAlert = () => {
        setOpen(true);
    };

    const onSubmit = (data) => {
        if (file) {
            const formData = new FormData();
            formData.append('files', file);
            console.log('FILE DATA TRIGGRED');
            axios.post(`${process.env.REACT_APP_BASEURL}admin/user/add_dna_code_csv2`, formData).then((response) => {
                if (response.data.result === 'success') {
                    handleAlert();
                    setTimeout(() => {
                        navigate('/barcode/view');
                    }, 2000);
                } else {
                    console.log('error');
                }
            });
        } else {
            console.log('NORMAl DATA TRIGGRED');
            data.recipients = [...data.recipients.split(',')];
            axios.post(`${process.env.REACT_APP_BASEURL}admin/user/add_dna_code_csv1`, data).then((response) => {
                if (response.data.result === 'success') {
                    handleAlert();
                    setTimeout(() => {
                        navigate('/barcode/view');
                    }, 2000);
                } else {
                    console.log('error');
                }
            });
        }
    };
    console.log(errors);

    const Formdiv = styled.div`
        label {
            color: grey;
            font-size: 1rem;
        }
    `;

    const Form = styled.form`
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: center;
        width: 20vw;
        padding: 20px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 5px;

        Button {
            width: 4vw;
        }
    `;

    const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

    return (
        <MainCard>
            <Formdiv>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Button style={{ width: '10rem' }} variant="outlined" onClick={() => setToggle(!toggle)}>
                        {toggle ? <>Switch To Manual</> : <>Switch To CSV</>}
                    </Button>
                    <br />

                    {toggle ? (
                        <>
                            <FormLabel>Add Barcode Through CSV File</FormLabel>
                            <br />
                            <br />
                            <Input type="file" className="form-control-file" id="exampleFormControlFile1" onChange={imageChange} />
                            <br />
                        </>
                    ) : (
                        <>
                            <FormLabel>Add Barcode Manually</FormLabel>
                            <br />
                            <TextareaAutosize
                                style={{ width: '60%', minHeight: '20%' }}
                                placeholder="Enter multiple comma separated barcodes"
                                {...register('recipients', { required: true })}
                            />
                            <br />
                        </>
                    )}

                    <Button variant="contained" type="submit">
                        Add
                    </Button>
                </Form>
            </Formdiv>

            <Snackbar open={open} autoHideDuration={6000} style={{ alignItems: 'center' }} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Barcode Added Successfully
                </Alert>
            </Snackbar>
        </MainCard>
    );
};

export default AddBarcode;
