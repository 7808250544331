import React, { useState, useEffect } from "react";
import MainCard from "ui-component/cards/MainCard";
import { useNavigate, useLocation } from "react-router";
import {
    Button, Divider, 
    Tooltip,
} from "@mui/material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { Header, GrayBox } from './style';
import './style.css'
import axios from "axios";
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import SlotDashboard from "./SlotDashboard";


const SlotSelection = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [id, setId] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [qualification, setQualification] = useState('');
    const [experience, setExperience] = useState('');
    const [availability, setAvailability] = useState('');

    const [selectedMonthId, setSelectedMonthId] = useState(new Date().getMonth());
    const [selectedYearId, setSelectedYearId] = useState(new Date().getFullYear());

    const [open, setOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastSeverity, setToastSeverity] = useState("success");
    const [weekData, setWeekData] = useState([])
    const [initWeekData, setInitWeekData] = useState([])
    const [isSlotSelection, setIsSlotSelection] = useState(false)

    const success = (message) => {
        setToastSeverity("success");
        setToastMessage(message);
        setOpen(true);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setToastMessage("");
        setOpen(false);
    }

    const errorToast = (message) => {
        setToastSeverity("error");
        setToastMessage(message);
        setOpen(true);
    }




    useEffect(() => {
        if (location.state.item) {

            console.log("location.state.item ", location.state.item);
            setName(location.state.item.name);
            setEmail(location.state.item.email)
            setQualification(location.state.item.educational_qualification);
            setExperience(location.state.item.year_of_experience);
            setAvailability(location.state.item.consultation_time);
            setId(location.state.item.id);
 
        }
    }, [])

   




    const updateConsultantSlots = async () => {
        
        const unSelectedSlots = []
        const selectedSlots = []

        weekData.forEach((weeks,weeksIndex)=>{
            weeks.data.forEach((data, dataIndex)=>{
                data.slots.forEach((slots, slotsIndex)=>{

                    const initWeekStatus = initWeekData[weeksIndex].data[dataIndex].slots[slotsIndex].is_available
                    if(initWeekStatus!== slots.is_available){
                        if(initWeekStatus){ 
                           let currentUnSelectedSlot = unSelectedSlots.find((ele)=>ele.date === slots.date)
                           if(!currentUnSelectedSlot) {
                            currentUnSelectedSlot = {
                                date:slots.date,
                                slot_ids: []
                            }
                           }
                           currentUnSelectedSlot.slot_ids.push(slots.id)
                           unSelectedSlots.push(currentUnSelectedSlot)
                        } else {
                            let currentSelectedSlot = selectedSlots.find((ele)=>ele.date === slots.date)
                            if(!currentSelectedSlot) {
                                currentSelectedSlot = {
                                 date:slots.date,
                                 slot_ids: []
                             }
                            }
                            currentSelectedSlot.slot_ids.push(slots.id)
                            selectedSlots.push(currentSelectedSlot)
                        }
                    }

                })

            })

        })

        const data = {
            admin_user_id: id,
            month_id: selectedMonthId+1,
            unselected_slots: unSelectedSlots,
            selected_slots: selectedSlots
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASEURL}admin/v1/consultation/save_slots`, data)
            console.log("response ", response);
            success(response.data.message);
            setTimeout(() => {
                navigate(-1)
            }, 1000);
        } catch (error) {
            errorToast(error.response.data.message)
            console.log(error)
            alert(error.response.data.message);
        }
    }




    const styles = {
        divider: {
            marginTop: '10px',
            marginBottom: '10px',
            height: '2px',
            backgroundColor: '#ADADAD',
        },
        tab: {
            fontSize: 14,
            fontWeight: 500,
            color: '#1B99DF',
            background: '#FFFFFF',
            border: '1px solid #1B99DF',
            borderRadius: 8,
            paddingBottom: 5,
            paddingTop: 5,
            paddingLeft: 15,
            paddingRight: 15,
            marginLeft: 5,
            marginRight: 5,
            minHeight: 40
        },
        tablist: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        }
    }


    const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);
    return (
        <>
            <MainCard>
                <Header>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <ArrowCircleLeftIcon
                            onClick={() => {
                                navigate(-1);
                            }}
                            sx={{ cursor: 'pointer', color: '#02a9f4' }}
                        />
                        &nbsp;&nbsp;
                        <span> Slot Selection </span>
                    </div>
                    <Button
                        disabled={!isSlotSelection}
                        color="primary"
                        variant="contained"
                        sx={{
                            color: 'white'
                        }}
                        onClick={updateConsultantSlots}
                    >
                        Update
                    </Button>
                </Header>
                <GrayBox>
                    <div className="selection-row" >
                        <h4 className="heading">Experts Information</h4>
                        <Divider style={styles.divider} />
                        <div className="content-container expert-info" >
                            <span className="info-box" >{name}</span>
                            <span className="info-box" >{email}</span>
                            <span className="info-box" >{experience ? experience : "NA"} Years</span>
                            {
                                qualification.length > 20 ? (
                                    <Tooltip title={qualification ? qualification : "Not Available"} placement="top">
                                        <span className="info-box" >
                                            {qualification ? qualification.length > 20 ? qualification.slice(0, 20) + "..." : qualification : "Not Available"}
                                        </span>
                                    </Tooltip>
                                ) : (
                                    <span className="info-box" >
                                        {qualification ? qualification.length > 20 ? qualification.slice(0, 20) + "..." : qualification : "Not Available"}
                                    </span>
                                )
                            }
                        </div>
                        <Divider style={styles.divider} />
                    </div>
                    <div className="selection-row" style={{ paddingBottom: "0px",paddingTop: "0px", marginTop:"-20px" }} >
                        <h4 className="heading">Select Slots</h4>
                        <Divider style={styles.divider} />
   
                    </div>
                    <SlotDashboard data={location.state}  isSlotSelection={isSlotSelection} setIsSlotSelection={setIsSlotSelection} success={success} errorToast={errorToast} initWeekData={initWeekData} setInitWeekData={setInitWeekData} weekData={weekData} setWeekData={setWeekData} selectedMonthId={selectedMonthId} setSelectedMonthId={setSelectedMonthId} selectedYearId={selectedYearId} setSelectedYearId={setSelectedYearId}/>
  
                </GrayBox>
            </MainCard>
            <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={toastSeverity} sx={{ width: '100%' }}>
                    {toastMessage}
                </Alert>
            </Snackbar>
        </>
    );
}

export default SlotSelection;
