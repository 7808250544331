import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import Toaster from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import Logo from 'ui-component/Logo';
import { Authenticated } from 'utils/checkerfunction';

// ==============================|| APP ||============================== //

const ErrorComponent = () => (
      <div style={{display:'flex', justifyContent:'center',alignItems:'center', flexDirection:'column', height:'100vh'}}>
        <Logo />
        <p style={{paddingTop:10}}>This site is not accessible on mobile devices</p>
      </div>
    );

const App = () => {
    const customization = useSelector((state) => state.customization);
    const userAgent = window.navigator.userAgent;
    const [isError, setIsError] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
          const isMobileView = window.innerWidth <= 1000;
          setIsMobile(isMobileView);
        };
      
        const checkUserAgent = () => {
          if (/Android/.test(window.navigator.userAgent) || /iPhone|iPad|iPod/.test(window.navigator.userAgent)) {
            setIsError(true);
          }
        };
      
        handleResize(); // Initial check
        checkUserAgent(); // Initial check
      
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
      
    if(isMobile) {
        return <ErrorComponent />
    }

    if (isError) {
        return <ErrorComponent />
    }

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        Authenticated();
      }
    });    

    return (
        <StyledEngineProvider injectFirst>
            <Toaster />
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
