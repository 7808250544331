import React, { useEffect, useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MaterialTable, { MTableToolbar } from 'material-table';
import excell from "../../../assets/images/excell.svg";

import tableIcons from 'themes/MaterialTableIcons';
import axios from 'axios';
import { Authenticated } from '../../../utils/checkerfunction';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { Pagination, Select, FormControl, MenuItem, InputLabel, Button, TextField, InputAdornment, Tooltip, Snackbar, Alert } from '@mui/material';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import MuiAlert from '@mui/material/Alert';
import ReactLoading from 'react-loading';
import CircularProgress from '@material-ui/core/CircularProgress';
import './style.css';
import { UserData } from 'utils/userData';



const CustomerField = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [loadingsearch, setLoadingsearch] = useState(false);
    const [open, setOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastSeverity, setToastSeverity] = useState("success");
    const [filterBodyData, setFilterBodyData]=useState()
    const finalPermission = UserData().permissions
    const dna = finalPermission?.find(permission => permission.name === "DNA Report");


    const errorToast = (message) => {
        setToastSeverity("error");
        setToastMessage(message);
        setOpen(true);
    }
    const buttonStyle = {
        color: 'white',
        fontSize: '0.9rem',
        padding: '5px 45px',
        marginTop: '0px',
        margin: '0px 5px'
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setToastMessage("");
        setOpen(false);
    }

    const success = (message) => {
        setToastSeverity("success");
        setToastMessage(message);
        setOpen(true);
    }
    const exportExcel = async () => {
        const body = {
            ...filterBodyData
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASEURL}admin/customer/dna_test_report/export`, body);
            const excelUrl = response.data.data[0].excel_url
            window.open(excelUrl,"_Blank")
        } catch(error) {
            errorToast("No data to export");
        }
       
       
    }

    const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

    const deleteUser = (id) => {
        try {
            axios.delete(`${process.env.REACT_APP_BASEURL}admin/customer?user_id=${id}`).then((response) => {
                if (response.data.result === "success") {
                    success(response.data.message);
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                } else {
                    errorToast(response.data.message);
                }
            }).catch((err) => {
                console.log(err);
                errorToast(err.response.data.message);
            });
        }
        catch (err) {
            console.log(err);
            errorToast("Could not delete the user");
        }
    }

    const navigate = useNavigate();

    const columns = [
        { title: 'S.No', field: 'sno', width: 40 },
        { title: 'Name', field: 'name', width: 200 },
        { title: 'Customer Id', field: 'customer_id', render: (rowData) => {
            if (rowData && rowData.customer_id) {
              return rowData.customer_id;
            }
            return 'null';
          },
          width: 200
        },
        { title: 'Organization', field: 'organization', width: 200 },
        { title: 'Phone Number', field: 'phone_number', width: 200 },
        { title: 'Email Address', field: 'email_address', width: 200 },
        { title: 'Employee Id', field: 'employee_id', width: 200 },
        { title: 'Payment Method', field: 'payment_method', sorting: false, width: 200 },
        { title: 'Status', field: 'status', sorting: false, width: 100 },
        { title: 'Active Plan Name', field: 'active_plan_name', sorting: false, width: 200 },
        { title: 'Address For Courier', field: 'address_for_courier', width: 200 },
        { title: 'Family Status', field: 'family_status', width: 200 },
        { title: 'DNA Test Kit', field: 'dna_test_kit', width: 200 },
        { title: 'Blood Report', field: 'blood_report', width: 200 },
        { title: 'DNA Sample', field: 'dna_sample', width: 200 },
        { title: 'DNA Report', field: 'dna_report', width: 200 },
        { title: 'NAF', field: 'naf', width: 200 },
        { title: 'Login Status', field: 'is_logged_in', width: 200 },
        { title: 'Doctor Counselling', field: 'doctor_counselling', width: 200 },
        { title: 'Doctor Prescription', field: 'doctor_prescription', width: 200 },
        {title: "Nutrigenetic Counselling", field: "nutrigenetic_counselling", width: 200 },
        { title: 'Nutritionist Counselling', field: 'nutritionist_counselling', width: 300 },
        { title: 'Diet Plan', field: 'diet_plan', width: 200 },
        { title: 'Barcode', field: 'barcode', width: 200 },

        dna?.can_create && {
            title: 'Action', field: 'action', sorting: false,
            render: (item) => (
                <>
                    <div style={{ display: "flex" }}>
                        {dna?.can_create && (
                        <Tooltip title="Edit Customer Field">
                            <EditIcon sx={{ cursor: 'pointer', color: '#02a9f4' }} onClick={() => navigate(`/update_customer_field/${item.user_id}`)} />
                        </Tooltip>)}
                    </div>
                </>
            )
        },
    ].filter(Boolean);;

    const [data, setData] = useState(null);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(100);
    const [searchKey, setSearchKey] = useState('email');
    const [filterKey, setFilterKey] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [filterData, setFilterData] = useState([])
    const [filterDataObj, setFilterDataObj] = useState([])

    const baseURL = `${process.env.REACT_APP_BASEURL}admin/customer`;
    axios.defaults.headers.common = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    };

    const array = [];
    const getData = async (filters) => {
        const body = {
            page,
            size: limit,
            search_key: searchKey,
            search_value: searchValue,
            ...(Object.keys(filters).length > 0 && { filters })
        }
        setFilterBodyData({ search_key: searchKey,
            search_value: searchValue,
            filters})
        const response = await axios.post(`${process.env.REACT_APP_BASEURL}admin/customer`, body);
        console.log(response);
        setTotal(response.data.total_count);
        setIsLoading(false);
        setLimit(response.data.size);
        setData(response.data.data);

        response.data.data.forEach((element) => {
            array.push(element);
        });
        setData(array);
    };

    const handleFilter = async (value) => {
        setFilterKey(value)
        const arr = []
        if (value === 'org_name') {
            const response = await axios.get(`${process.env.REACT_APP_BASEURL}admin/user/getOrgList`)
            response?.data?.data.map((item, index) => (
                arr.push(item.org_name)
            ))
            setFilterData(arr)
        } else if (value === 'naf') {
            arr.push('Filled', 'Not Filled')
            setFilterData(arr)
        }
        else if (value === 'logged_in') {
            const arrObj = [{
                "name": "Yes",
                "value": true
            }, {
                "name": "No",
                "value": false
            }]
            setFilterDataObj(arrObj)
            console.log(arrObj)
        }
        else if (value === 'plan_name') {
            arr.push('Blood Test', 'DNA Test')
            setFilterData(arr)
        }
        else {
            const arrObj = [{
                "name": "Active",
                "value": true
            }, {
                "name": "Inactive",
                "value": false
            }]
            setFilterDataObj(arrObj)
        }

    }

    const handleFilterValue = async (value) => {
        setFilterValue(value)
        const filter = {}
        setPage(1)
        filter[filterKey] = value
        getData(filter).then(() => {
            setIsLoading(false);
        });

    }

    let searchTimeout;

    React.useEffect(() => {
        // if (searchValue === '') setIsLoading(true);
        clearTimeout(searchTimeout);
        setIsLoading(true);
        
        searchTimeout = setTimeout(() => {
            Authenticated();
            if (searchValue) {
                const filter = filterValue ? { [filterKey]: filterValue } : {};
                getData(filter).then(() => {
                    setIsLoading(false);
                });
            }            
        }, 2000);
        
        return () => {
            clearTimeout(searchTimeout);
        };
    }, [page, limit, searchValue, searchKey]);

    useEffect(() => {
        if (!searchValue) {
            const filter = filterValue ? { [filterKey]: filterValue } : {};
            getData(filter).then(() => {
                setIsLoading(false);
            });
        }
    }, [page, limit, searchValue, searchKey])
    

    const arr = [];

    const handlePageChange = (event, value) => {
        setPage(value);
        // getData();
    };



    // console.log('hello', data);
    // eslint-disable-next-line array-callback-return
    data?.map((item, i) => {
        if (item.is_approved !== 1 && item.is_approved !== 2) {
            arr.push({
                sno: (limit * (page - 1)) + i + 1,
                name: item.name,
                customer_id: item.customer_id,
                organization: item.organization,
                phone_number: item.phone_number,
                email_address: `${item.email_address}`,
                employee_id: item.employee_id,
                payment_method: item.payment_method,
                status: item.status,
                address_for_courier: item.address_for_courier,
                active_plan_name: item.active_plan_name,
                family_status: item.family_status,
                dna_test_kit: item.dna_test_kit,
                blood_report: item.blood_report,
                dna_sample: item.dna_sample,
                dna_report: item.dna_report,
                naf: item.naf,
                is_logged_in: item.is_logged_in,
                doctor_counselling: item.doctor_counselling,
                doctor_prescription: item.doctor_prescription,
                nutrigenetic_counselling: item.nutrigenetic_counselling,
                nutritionist_counselling: item.nutritionist_counselling,
                diet_plan: item.diet_plan,
                barcode: item.barcode,
                user_id: item.user_id,
            });
        }
    });

    return (
        <>
            <MainCard>
                <div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            margin: "0 10px",
                        }}
                    >
                        <div style={{ display: "flex", gap: "30px" }}>
                            <FormControl sx={{ minWidth: 120 }} size="small">
                                <InputLabel id="select-search-key-label">Filter by</InputLabel>
                                <Select
                                    labelId="select-search-key-label"
                                    id="select-search-key"
                                    value={filterKey}
                                    label="Filter by"
                                    onChange={(event) => { handleFilter(event.target.value) }}
                                >
                                    <MenuItem value="" disabled>Select an Option</MenuItem>
                                    <MenuItem value='org_name'>Organization</MenuItem>
                                    <MenuItem value='naf'>NAF Status</MenuItem>
                                    <MenuItem value='logged_in'>Logged In Status</MenuItem>
                                    <MenuItem value='is_plan_active'>Plan Status</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl sx={{ minWidth: 120 }} size="small">
                                <InputLabel id="select-search-key-label">Filter Value</InputLabel>
                                <Select
                                    labelId="select-search-key-label"
                                    id="select-search-key"
                                    value={filterValue}
                                    label="Filter Value"
                                    disabled={filterKey === ""}
                                    onChange={(event) => { handleFilterValue(event.target.value) }}
                                >
                                    <MenuItem value="" disabled>
                                        Select {filterKey==="org_name"?"an Organization": filterKey==="naf" ? "NAF Status" : filterKey==="logged_in" ? "Logged in status" : filterKey==="plan_name" ? "Plan name" : "Plan Status"}
                                    </MenuItem>
                                    {
                                        filterKey === "logged_in" || filterKey === "is_plan_active" ?
                                            filterDataObj?.map((item) => (
                                                <MenuItem value={item.value}>{item.name}</MenuItem>
                                            ))
                                            :
                                            filterData?.map((item) => (
                                                <MenuItem value={item}>{item}</MenuItem>
                                            ))
                                    }
                                </Select>
                            </FormControl>

                        </div>

                        <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                            <div>
                            <Button
                        size="small"
                        variant="contained"
                        startIcon={<img src={excell} alt=" " />}
                        sx={buttonStyle}
                        disabled={!dna?.can_create}
                        onClick={() => exportExcel()}
                    >
                        Export
                    </Button>
                            </div>
                            <div>
                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                <InputLabel id="select-search-key-label">Search by</InputLabel>
                                <Select
                                    labelId="select-search-key-label"
                                    id="select-search-key"
                                    value={searchKey}
                                    label="Search by"
                                    onChange={(event) => { setSearchKey(event.target.value) }}
                                >
                                    <MenuItem value='customer_id'>Customer Id</MenuItem>
                                    <MenuItem value='name'>Name</MenuItem>
                                    <MenuItem value='email'>Email</MenuItem>
                                    <MenuItem value='phone'>Phone</MenuItem>
                                </Select>
                            </FormControl>
                            </div>
                            &nbsp;&nbsp;
                            <div>
                            <TextField
                                autoFocus
                                sx={{ m: 1 }}
                                autoComplete="off"
                                key="search"
                                id="standard-basic"
                                placeholder='Search'
                                variant="standard"
                                // style={{ marginBottom: '1rem' }}
                                InputProps={{
                                    startAdornment: (
                                        <div style={{ display: 'flex', alignItems: 'center', marginLeft:7 }}>
                                            <InputAdornment position="end">
                                                <SearchRoundedIcon />
                                            </InputAdornment>
                                        </div>
                                    ),
                                }}
                                value={searchValue}
                                onChange={(e) => {
                                    if (searchKey === '') alert('Please select search key first');
                                    else setSearchValue(e.target.value);
                                }}
                            />
                            </div>
                        </div>
                    </div>
                            <div>
                                <MaterialTable
                                isLoading={isLoading}
                                    title={null}
                                    style={{ width: "100%", padding: "6px", boxShadow: "none" }}
                                    icons={tableIcons}
                                    columns={columns}
                                    data={arr}
                                    options={{
                                        toolbar: false,
                                        // pageSize: 20,
                                        search: false,
                                        paging: false,
                                        maxBodyHeight: '700px',
                                        headerStyle: {
                                            backgroundColor: '#01579b',
                                            color: '#FFF',
                                            fontWeight: 100,
                                        },
                                        columnStyle: {
                                            width: '100%',
                                        },

                                        // eslint-disable-next-line consistent-return
                                        rowStyle: (x) => {
                                            if (x.tableData.id % 2) {
                                                return { backgroundColor: '#eef9ff', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px' };
                                            }
                                        }
                                    }}
                                />
                            <div
                                width="100%"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '1rem',
                                    width: '100%'
                                }}
                            >
                                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                    <InputLabel id="demo-simple-select-label">Results per Page</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={limit}
                                        label="Results per Page"
                                        onChange={(event) => { setLimit(event.target.value) }}
                                    >
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                        <MenuItem value={100}>100</MenuItem>
                                    </Select>
                                </FormControl>
                                &nbsp;&nbsp;
                                <Pagination
                                    count={Math.ceil(total / limit)}
                                    page={page}
                                    onChange={handlePageChange}
                                    variant="outlined"
                                    shape="rounded"
                                    size="small"
                                />
                            </div>
                            </div>
                </div>
            </MainCard>

            <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={toastSeverity} sx={{ width: '100%' }}>
                    {toastMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default CustomerField;