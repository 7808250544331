import React, { useState, useEffect } from "react";
import MainCard from "ui-component/cards/MainCard";
import { useNavigate } from "react-router";
import { Button, MenuItem, Select, TextField, Divider } from "@mui/material";
import { Header, GrayBox} from './style';
import './style.css';
import axios from "axios";

const SlotSettings = () => {

    const navigate = useNavigate();
    const [slotConfiguration, setSlotConfiguration] = useState('');

    const weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    useEffect (() => {
        axios.get(`${process.env.REACT_APP_BASEURL}admin/consultation/slot_configuration`)
        .then((res) => {
            setSlotConfiguration(res.data.data);
        })
    }, []);

    const updateSlotConfiguration = () => {
        axios.patch(`${process.env.REACT_APP_BASEURL}admin/consultation/slot_configuration`, {
            configuration:slotConfiguration
        })
        .then((res) => {
           if(res.data.result === 'success'){
               alert(res.data.message);
           }
        }).catch((err) => {
            console.log("err ", err);
            alert(err.response.data.message);
        })
    }

    const handleWorkingDaysChange = (e, index) => {
        console.log("e.target.value ", e.target.value);
        const data = [...slotConfiguration];
        data[index].selected_days = e.target.value;
        setSlotConfiguration(data);
    }
    
    const handleSlotDurationChange = (e, index) => {
        const data = [...slotConfiguration];
        data[index].default_slot_duration = e.target.value;
        setSlotConfiguration(data);
    }

    const handleBreakBetweenSlotsChange = (e, index) => {
        const data = [...slotConfiguration];
        data[index].default_break_duration = e.target.value;
        setSlotConfiguration(data);
    }

    console.log("SlotSettings ", slotConfiguration);
    return (
        <MainCard>
            <Header>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <span> Configure Slots</span>
                </div>
                <Button
                    color="primary"
                    variant="contained"
                    sx={{
                        color: 'white'
                    }}
                    onClick={() => updateSlotConfiguration()}
                >
                    Update
                </Button>
            </Header>
            <GrayBox>
                {slotConfiguration && slotConfiguration.map((item, index) => (
                    <div>
                    <div className="box-row" style={{ padding: '0px 20px' }}>
                        <h3 className="heading" >{`Configure Slots For ${item.role_name}`}</h3>
                    </div>
                    <Divider variant="middle" />
                    <div className="box-row-slots" style={{ marginBottom: '20px' }} >
                        <div className="input-container1" >
                            <p>Slot Duration (mins)</p>
                            <TextField
                                fullWidth
                                type='number'
                                variant="outlined"
                                size="small"
                                value={item.default_slot_duration}
                                onChange={(e) => handleSlotDurationChange(e, index)}
                            />
                        </div>
                        <div className="input-container1">
                            <p>Break Between Slots (mins)</p>
                            <TextField
                                fullWidth
                                type='number'
                                variant="outlined"
                                size="small"
                                value={item.default_break_duration}
                                onChange={(e) => handleBreakBetweenSlotsChange(e, index)}
                            />
                        </div>
                        <div className="input-container2" >
                            <p>Working Days</p>
                            <div className="workingDays" >
                                <div className="weekDay" >
                                    <Select
                                        fullWidth
                                        multiple
                                        variant="outlined"
                                        size="small"
                                        value={item.selected_days}
                                        onChange={(e) => handleWorkingDaysChange(e, index)}
                                    >
                                        {weekDays.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                ))}
                <div className="box-row">
                    <div className="note" >
                        <p>Note:</p>
                        <p>Slot Duration - It is the duration of the session between the expert and the patient.</p>
                        <p>Break Between Slots - It is the duration between the end of previous session and start of a new session for the expert.</p>
                        <p>Working Days - It is the duration of the working days of the experts.</p>
                    </div>
                </div>
            </GrayBox>
        </MainCard>
    );
}

export default SlotSettings;