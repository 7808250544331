import React, { useState, useEffect, forwardRef } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { Button, Checkbox, Chip, FormGroup, FormControl, FormControlLabel, Input, MenuItem, InputLabel, Select, TextField, Modal, Divider, Grid, Radio, RadioGroup, Pagination, Snackbar } from '@mui/material';
import { common } from '@mui/material/colors';
import axios from 'axios';
import Box from '@mui/material/Box';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import InputAdornment from '@mui/material/InputAdornment';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import styled from 'styled-components';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import "./style.css";
import MuiAlert from '@mui/material/Alert';
import ReactLoading from "react-loading";


// ============================|| StyleZone ||============================ //
const NotificationPageBanner = styled.div`
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 10px 10px 0px 0px;
    padding: 10px;
`;

const MainCardBackground = styled.div`
    background-color: #f5f5f5;
    borderRadius: 10px;
`;

const AddPersonalizedUser = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

const UserChip = styled.div`
    display: flex;
    border: 1px solid #1B99DF;
    flex-direction: row; 
    justify-content: space-between;
    align-items: center;
    padding: 0 0.7rem;
    border-radius: 0.7rem;
`;

const MessageBox = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border: 1px solid #ADADAD;
    border-radius: 10px;
    padding: 1rem;
    width: 100%;
`;
const MessageBox1 = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border: 1px solid #ADADAD;
    border-radius: 10px;
    ${'' /* padding: 1rem; */}
    width: 100%;
    overflow: hidden;
`;

const NotificationType = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    `;

const Attachment = styled.div`
    display: flex;
    align-items: center;
    margin: 20px
    padding: 10px 20px;
    border : 1px solid #ADADAD;
    border-radius: 10px;
    width: 15%;
    justify-content: center;
`;

const Notification = ({notification}) => {
    const [orgsList, setOrgsList] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState('');
    const [isPersonalizedUser, setIsPersonalizedUser] = useState(false);
    const [orgUsers, setOrgUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [message, setMessage] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [inputTextMessage, setInputTextMessage] = useState('');
    const [hours, setHours] = useState('');
    const [minutes, setMinutes] = useState('');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [title, setTitle] = useState('');
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [open, setOpen] = useState(false);
    const [notificationType, setNotificationType] = useState('');
    const [attachments, setAttachments] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(10);
    const [searchText, setSearchText] = useState('');
    const [limit, setLimit] = useState(10);
    const [trigger, setTrigger] = useState(1);
    const [whatsappData, setWhatsappData] = useState([]);
    const [templateIndex, setTemplateIndex] = useState('');
    const [replaceData, setReplaceData] = useState([{
        replace_in_keys: [],
        text_data: ""
    }]);

    const handleOpen = (e) => {
        if (selectedOrg === '') {
            setAlertMessage('Please select an organization');
            handleFailureAlert()
        } else {
            setOpen(true);
        }
    };
    const handleClose = () => { setOpen(false) };

    const [openAlert, setOpenAlert] = useState(false);
    const [openAlert1, setOpenAlert1] = useState(false);
    const [openAlert2, setOpenAlert2] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
        setOpenAlert1(false);
        setOpenAlert2(false);
    };

    const handleSuccessAlert = () => {
        setOpenAlert(true);
    };

    const handleFailureAlert = () => {
        setOpenAlert1(true);
    };
    const handleNetworkErrorAlert = (errorMsg) => {
        setOpenAlert2(true);
    };

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize)
    })

    useEffect(() => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_BASEURL}admin/user/getOrgList`).then((res) => {
            setOrgsList(res.data.data);
            setLoading(false);
        });
    }, [])


    useEffect(() => {
        const getUsers = async () => {
            const res = await axios.get(`${process.env.REACT_APP_BASEURL}admin/user/getOrgnizationUserListV1?size=${limit}&page=${page}&id=${selectedOrg}&search=${searchText}`);
            setOrgUsers(res.data.data);
            setTotal(res.data.total_count);
            setTrigger(trigger + 1);
        }
        if (selectedOrg !== '') {
            getUsers();
        }
    }, [page, searchText, selectedOrg])

    useEffect(() => {
        setPage(1);
    }, [searchText])

    const handleChange = (event) => {
        setSelectedOrg(event.target.value);
        setSelectedUsers([]);
        // axios.get(`${process.env.REACT_APP_BASEURL}admin/user/getOrgnizationUserList?id=${event.target.value}`).then((res) => {
        //     setOrgUsers(res.data.result);
        //     setFilteredUsers(res.data.result);
        // });
    }

    const handlePageChange = (event, value) => {
        setPage(value);
    };


    function returnTextOnly(str) {
        const text = str.replace(/(<([^>]+)>)/ig, "").replace(/\n/g, '');
        const newText = text.split(' ').filter((word) => word[0] !== '$').join(' ');
        return newText;
    }

    const shareNotification = () => {
        setLoading(true);
        const finalTime = new Date()
        finalTime.setHours(hours, minutes)
        if (selectedOrg === '' && message === '' && title === '') {
            setAlertMessage('Please fill all the fields')
            handleFailureAlert();
        } else if (notificationType === '') {
            setAlertMessage('Please select a notification type');
            handleFailureAlert();
        }
        else {
            let channelType = '';
            if (notificationType === 'App Notification') {
                channelType = 'app_notification';
            } else if (notificationType === 'Email') {
                channelType = 'email';
            } else if (notificationType === 'WhatsApp') {
                channelType = 'whatsapp';
            }
            let users = [];
            if (isPersonalizedUser) {
                users = selectedUsers.map((user) => user.id)
            }
            const postData = [];
            if (notificationType === "WhatsApp") {
                postData.push({
                    "template": `${whatsappData[templateIndex].name}`,
                    "components": replaceData[0].replace_in_keys,
                })
            } else if (notificationType === "Email") {
                postData.push({
                    type: 'text',
                    title,
                    value: message,
                    attachments
                })
            } else if (notificationType === "App Notification") {
                postData.push({
                    type: 'text',
                    title,
                    value: returnTextOnly(message),
                })
            }
            const data = {
                channel_type: channelType,
                is_remainder: false,
                is_recurring: false,
                is_notify_all: !isPersonalizedUser,
                is_instant: true,
                timestamp: null,
                timer: false,
                org_id: selectedOrg,
                user_ids: users,
                post_data: postData
            }
            console.log("DATA", data);
            axios.post(`${process.env.REACT_APP_BASEURL}admin/notify/users`, data).then((res) => {
                if (res.status === 200) {
                    setAlertMessage(res.data.message);
                    handleSuccessAlert();
                    // window.location.reload();
                } else {
                    setAlertMessage(res.data.message);
                    handleNetworkErrorAlert();
                }
                setLoading(false);
            }).catch((err) => {
                setAlertMessage(err.response.data.message)
                handleNetworkErrorAlert();
                setLoading(false);
            });
        }
    }

    const handleUpload = (e) => {
        const files = e.target.files;
        const formData = new FormData();
        formData.append('files', files[0]);
        console.log("FILES", files);
        axios.post(`${process.env.REACT_APP_BASEURL}admin/notify/upload`, formData).then((res) => {
            if (res.status === 200) {
                setAttachments(res.data.data);
                setAlertMessage(res.data.message);
                handleSuccessAlert();
            } else {
                setAlertMessage(res.data.message);
                handleNetworkErrorAlert()
            }
        });
    }


    useEffect(() => {
        console.log("SELECTED USERS", selectedUsers);
    }, [selectedUsers])

    const isSelected = (id) => selectedUsers.findIndex((user) => user.id === id) !== -1;

    const modalBoxStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: windowWidth > 600 ? '60vw' : '80vw',
        maxHeight: '80vh',
        bgcolor: 'background.paper',
        borderCollapse: "separate",
        borderRadius: "0.7rem",
        boxShadow: 24,
        p: 4,
    };

    const blackCheckboxStyle = {
        color: common.black,
        '&.Mui-checked': {
            color: common.black,
        }
    }

    const renderModal = () => {
        console.log("Modal Rendered!")
        return (
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalBoxStyle}>
                    <div style={{ marginTop:"-10px" }} ><ArrowBackIcon onClick={handleClose}/></div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginTop:"5px"
                        }}
                    >
                        <h2>Add Members</h2>
                        {/* Add Search Box */}
                        <TextField
                            id="outlined-basic"
                            placeholder="Search Members"
                            variant="outlined"
                            // style={{ marginBottom: '1rem' }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="end">
                                        <SearchRoundedIcon />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(e) => {
                                setSearchText(e.target.value);
                            }}
                        />
                    </div>
                    <Divider variant="middle"sx={{marginTop:"10px",marginBottom:"20px"}} />
                    {
                        orgUsers.length > 0 ? (
                            <>
                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 5, sm: 10, md: 14 }} >
                                    {orgUsers.map((user, index) => (
                                        <Grid item xs={8} sm={6} md={6} key={index}>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    checked={isSelected(user.id)}
                                                    sx={blackCheckboxStyle}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setSelectedUsers([...selectedUsers, user])

                                                        } else {
                                                            setSelectedUsers(selectedUsers.filter((u) => u.id !== user.id))

                                                        }
                                                    }
                                                    } />}
                                                label={user.name + " (" + user.email + ")"}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                                <div className='pagination-container' >
                                    <Pagination
                                        count={Math.ceil(total / limit) || 10}
                                        page={page}
                                        onChange={handlePageChange}
                                        variant="outlined"
                                        shape="rounded"
                                        size="small"
                                    />
                                </div>
                            </>
                        ) : (<h2>No Users Found</h2>)
                    }
                </Box>
            </Modal>
        )
    }

    useEffect(() => {
        fetchWhatsappData()
    }, [])

    const fetchWhatsappData = async () => {
        const response = await axios.get(`${process.env.REACT_APP_BASEURL}admin/notify/templates`)
        setWhatsappData(await response.data.data)
    }

    const handleInputBox = async (value) => {
        try {
            const arr = []
            const response = await axios.post(`${process.env.REACT_APP_BASEURL}admin/notify/input_box`, whatsappData[value])
            // console.log(response.data)
            arr.push({
                replace_in_keys: response.data.replace_in_keys,
                text_data: response.data.text_data
            })
            // console.log(arr)
            setReplaceData(arr)
            const enabledArray = arr[0].replace_in_keys.find((ele) => ele.is_enabled)
            if (!enabledArray) {
                setInputTextMessage(response.data.message);
            }

        } catch (error) {
            setInputTextMessage(error.response.data.message);
        }
    }

    const handleInputChange = (value, index) => {
        const tempReplaceData = [...replaceData]
        tempReplaceData[0].replace_in_keys[index].replace_value = value
        setReplaceData(tempReplaceData)
    }
    useEffect(() => {
        console.log(replaceData, "Changed")
    }, [replaceData])

    const handleWhatsapp = (value) => {
        setNotificationType(value)
        console.log(whatsappData)
    }

    const handleTemplate = (value) => {
        setTemplateIndex(value)
        handleInputBox(value)
    }

    const replacePlaceholdersWithArrayValues = (str, arr) =>
        str.replace(/\{\{(\d+)\}\}/g, ((match, index) => {
            console.log(match, index, arr[index - 1]?.replace_value || match)
            return arr[index - 1]?.replace_value || "_ "
        }
        ));

    const Alert = forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

    return (
        <>
            <Snackbar open={openAlert} autoHideDuration={6000} style={{ alignItems: 'center' }} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity="success" sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>

            <Snackbar open={openAlert1} autoHideDuration={6000} style={{ alignItems: 'center' }} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity="warning" sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>

            <Snackbar open={openAlert2} autoHideDuration={6000} style={{ alignItems: 'center' }} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            {
                loading ? (
                    <div style={{
                        width: '100%',
                        height: '50vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }} >
                    <ReactLoading type='spin' color='#000000' height='8%' width='8%' />
                    </div>
                ) : (
                    <MainCard>
                <MainCardBackground>
                    <NotificationPageBanner>Add Notification Message</NotificationPageBanner>
                    <div
                        style={{
                            padding: '20px',
                        }}
                    >
                        <div>
                            <h4>Share with:</h4>
                            <p>Select the organization you want to share the message with</p>

                            <FormControl fullWidth size='medium'>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedOrg}
                                    onChange={handleChange}
                                    style={{ width: '60%', paddingLeft: "10px" }}
                                    displayEmpty
                                >
                                     <MenuItem value='' disabled >Select organizations</MenuItem>
                                    {orgsList && orgsList.map((item) => <MenuItem value={item.id}>{item.org_name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </div>
                        <AddPersonalizedUser>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="small"
                                            sx={blackCheckboxStyle}
                                        />
                                    }
                                    label=<h4>Personalized User</h4>
                                    onChange={(e) => setIsPersonalizedUser(e.target.checked)}
                                />
                            </FormGroup>
                            {
                                isPersonalizedUser && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        style={{
                                            marginLeft: '20px',
                                            color: 'white'
                                        }}
                                        onClick={(e) => handleOpen(e)}
                                    >
                                        Add
                                    </Button>
                                )
                            }
                        </AddPersonalizedUser>
                        <div>
                            {isPersonalizedUser &&
                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} style={{
                                    marginTop: '10px',
                                }} >
                                    {selectedUsers.map((user, index) => (
                                        <Grid item xs={2} sm={4} md={3} key={index}>
                                            <UserChip>
                                                <p
                                                    style={{
                                                        color: common.black,
                                                    }}
                                                >{user.name}</p>
                                                <DisabledByDefaultRoundedIcon
                                                    sx={{
                                                        color: common.black,
                                                        fontSize: '1.2rem',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => {
                                                        setSelectedUsers(selectedUsers.filter((u) => u.id !== user.id))
                                                    }}
                                                />
                                            </UserChip>
                                        </Grid>
                                    ))}
                                </Grid>
                            }
                        </div>
                        <NotificationType>
                            <h4>Select Type of Notification</h4>&nbsp;&nbsp;&nbsp;&nbsp;
                            {/* three radio buttons with options as WhatsApp, Email and App nNotification */}
                            <FormControl component="fieldset">
                                <RadioGroup row aria-label="position" name="position" defaultValue="top">

                                    <FormControlLabel
                                        value="WhatsApp"
                                        control={<Radio disabled={!selectedOrg} color="primary" />}
                                        label="WhatsApp"
                                        labelPlacement="end"
                                        onChange={(e) => {
                                            handleWhatsapp(e.target.value)
                                            // setNotificationType(e.target.value)
                                        }}
                                    />
                                    <FormControlLabel
                                        value="Email"
                                        control={<Radio color="primary" />}
                                        label="Email"
                                        labelPlacement="end"
                                        onChange={(e) => {
                                            setNotificationType(e.target.value)
                                        }}
                                    />
                                    <FormControlLabel
                                        value="App Notification"
                                        control={<Radio color="primary" />}
                                        label="App Notification"
                                        labelPlacement="end"
                                        onChange={(e) => {
                                            setNotificationType(e.target.value)
                                        }}
                                    />
                                </RadioGroup>
                            </FormControl>

                        </NotificationType>
                        <div style={{ width: "100%" }}>
                            {notificationType === "WhatsApp" ?
                                <>
                                    <div style={{ display: "flex", flexDirection: "row", gap: "10px", marginTop: "15px" }}>
                                        <div style={{ width: "100%", display: "flex", flexWrap: "wrap", flexDirection: "column", gap: "10px", flex: 0.6 }}>
                                            <div style={{ width: "100%", display: "flex", flexWrap: "wrap", flexDirection: "column", alignItems: "center" }}>
                                                <div style={{ width: "100%", display: "flex", flexWrap: "wrap", flexDirection: "row", alignItems: "center" }}>
                                                    <FormControl sx={{ m: 1, minWidth: "200px", flex: 1.5 }} size="small">
                                                        <InputLabel id="name">Template Name</InputLabel>
                                                        <Select
                                                            labelId="name"
                                                            id="name"
                                                            value={templateIndex}
                                                            label="Template Name"
                                                            onChange={(event) => { handleTemplate(event.target.value) }}
                                                        >
                                                            <MenuItem value='' disabled>Select Template</MenuItem>
                                                            {whatsappData && whatsappData.map((whatsappData, index) => (
                                                                <MenuItem value={index}>{whatsappData.name}</MenuItem>
                                                            ))}

                                                        </Select>
                                                    </FormControl>
                                                    <div style={{ flex: 0.5, padding: "8px", minWidth: "120px" }}>
                                                        <TextField
                                                            disabled
                                                            id="status"
                                                            placeholder="Status"
                                                            variant="outlined"
                                                            size="small"
                                                            label="Status"
                                                            fullWidth
                                                            value={whatsappData[templateIndex]?.status || 'Status'}
                                                            multiline
                                                        />


                                                    </div>

                                                </div>
                                                <div style={{ padding: "8px",flex: 1, minWidth: "200px", width: "100%" }}>

                                                    {replaceData[0].replace_in_keys && replaceData[0].replace_in_keys.map((data, index) => (
                                                        data.is_enabled === true &&

                                                        <TextField
                                                            // id="status"
                                                            style={{marginBottom: "8px"}}
                                                            multiline
                                                            placeholder={data.replace_in}
                                                            variant="outlined"
                                                            size="small"
                                                            label={data.replace_in}
                                                            fullWidth
                                                            // value={data.replace_in}
                                                            onChange={(e) => {
                                                                handleInputChange(e.target.value, index);

                                                            }}
                                                        />
                                                    ))}

                                                </div>
                                            </div>

                                        </div>
                                        <div style={{ height: "inherit", marginTop: "-20px", flex: 1.4 }}>
                                            <div style={{ fontSize: "12px", marginBottom: "8px" }}>Message Preview</div>
                                            <div className='msg'>
                                                {replacePlaceholdersWithArrayValues(replaceData[0].text_data, replaceData[0].replace_in_keys)}
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ padding: "14px" }}>{inputTextMessage}</div>
                                </> :

                                notificationType === "" ? <></> :
                                    <>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: windowWidth > 700 ? 'row' : 'column',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    marginRight: '5rem',
                                                }}
                                            >
                                                <h4>{notificationType === 'Email' ? "Subject" : "Title"}</h4>
                                                <TextField id="outlined-basic" variant="outlined"
                                                    sx={{
                                                        width: '100%'
                                                    }}
                                                    placeholder={notificationType === 'Email' ? "Subject" : "Title"}
                                                    value={title}
                                                    onChange={(e) => setTitle(e.target.value)}
                                                />
                                                <h4>{notificationType === 'Email' ? "Body" : "Message"}</h4>
                                                <MessageBox1>

                                                    <ReactQuill
                                                        value={message}
                                                        onChange={(e) => setMessage(e)}
                                                        placeholder="Enter your text here"
                                                        style={{ height: '300px', width: "560px" }}
                                                        modules={{
                                                            toolbar: [
                                                                [{ header: [1, 2, false] }],
                                                                ['bold', 'italic', 'underline', 'strike'],
                                                                [{ color: [] }, { background: [] }],
                                                                [{ align: [] }],
                                                                [{ list: 'ordered' }, { list: 'bullet' }],
                                                                [{ indent: '-1' }, { indent: '+1' }],
                                                                ['clean']
                                                            ]
                                                        }}
                                                        formats={[
                                                            'header',
                                                            'bold', 'italic', 'underline', 'strike',
                                                            'color', 'background',
                                                            'align',
                                                            'list', 'bullet',
                                                            'indent'
                                                        ]}
                                                    />

                                                </MessageBox1>
                                                <p>The message can include alphabets, emojis, special characters, and numbers.</p>
                                            </div>
                                        </div>
                                        <div>
                                            {attachments.length > 0 &&
                                                attachments.map((attachment, index) => (
                                                    <Attachment>
                                                        <a href={attachment} style={{
                                                            textDecoration: 'none',
                                                            color: ''
                                                        }}  >
                                                            <p>{"Attachment " + (index + 1)}</p>
                                                        </a>
                                                    </Attachment>
                                                ))
                                            }
                                        </div>

                                    </>

                            }
                        </div>

                        <div
                            style={{
                                width: '100%',
                                textAlign: 'center',
                                margin: '1.5rem 0'
                            }}
                        >
                            {/* upload file button */}
                            {
                                notificationType === "" ? <></> :

                                    (notificationType === 'WhatsApp' || notificationType === 'App Notification' ) ?
                                        <Button
                                            component="label"
                                            variant="contained"
                                            color="primary"
                                            style={{
                                                color: 'white',
                                                width: '10rem'
                                            }}
                                            disabled={!notification?.can_create}
                                            onClick={() => { shareNotification() }}
                                        >
                                            Send
                                            {/* <input hidden type="file" onChange={handleUpload} /> */}
                                        </Button>
                                        :

                                        (
                                            <>
                                                <Button
                                                    component="label"
                                                    variant="contained"
                                                    color="primary"
                                                    style={{
                                                        color: 'white',
                                                        width: '10rem'
                                                    }}
                                                >
                                                    Upload File
                                                    <input hidden type="file" onChange={handleUpload} />
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    style={{
                                                        marginLeft: '20px',
                                                        color: 'white',
                                                        width: '10rem'
                                                    }}
                                                    onClick={shareNotification}
                                                >
                                                    Share
                                                </Button>
                                            </>
                                        )

                            }
                        </div>

                    </div>
                </MainCardBackground>
                {renderModal()}
            </MainCard >
                )
            }
        </>
    )
}

export default Notification