import styled from 'styled-components';
import Select from '@material-ui/core/Button';

export const Container = styled.div`
    display: flex;
    flex-direction: ${props => props.direction};
    height: 100%;
    width: 100%;
    padding: 10px;
    background-color: #f5f5f5;
    overflow-y: auto;
`;

export const LeftWrapper = styled.div`
    flex: 2;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-right: 1px solid #e0e0e0;
    .react-calendar{
        width: 100%;
    }
`;

export const RightContainer = styled.div`
    flex: 3;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px;
    padding-top: 20px;
    h2 {
        padding: 5px;
    }
`;

export const Content = styled.div`
    display: flex;
    width: 90%;
    height: 70%;
    flex-wrap: wrap;
    padding: 12px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 10%;
    padding: 12px;

    button {
        color: white;
    }
`;

export const BottomContainer = styled.div`
    width: 100%;
    //height: 300px;
    padding: 10px;
`;

export const ListSchedule = styled.div``;

export const Data = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    color: grey;
    padding: 10px;
    margin: 10px;
    border-radius: 20px;
    background-color: whitesmoke;
    h3, button, input{
        margin: 10px;
    }
`;
