/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */

// ============================|| Imports ||============================ //
import MainCard from 'ui-component/cards/MainCard';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { TextareaAutosize, Radio, RadioGroup, FormControl } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useState, useEffect, forwardRef } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import { Authenticated } from '../../../utils/checkerfunction';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from 'react-router';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Switch from '@mui/material/Switch';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Paper from '@mui/material/Paper';
import "./style.css"

const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase': {
        color: '#1B99DF',
        '&.Mui-checked': {
            color: '#1B99DF',
            '& + .MuiSwitch-track': {
                backgroundColor: '#1B99DF',
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            boxShadow: `0 0 0 3px rgba(27, 153, 223, 0.16)`,
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: "#a8a9ad",
        },
        '&.Mui-disabled .MuiSwitch-track': {
            backgroundColor: "#cccccc",
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: '#1B99DF',
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: "#a8a9ad",
        },
    },
    '& .MuiSwitch-track': {
        backgroundColor: "#b5b7bb",
    },
}));

const Alert = forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

// ============================|| StyleZone ||============================ //
const Formdiv = styled.div`
    border-radius: 15px;
    label {
        color: grey;
        font-size: 1rem;
    }
    #PriceLabel {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
    #AssignFeatures {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
`;

const GstPriceContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    button {
        margin: ''.5rem;
        width: 30%;
    }
`;

const Option = styled.div`
    display: flex;
    max-width: 95%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
`;

const Content = styled.div`
    flex: 1;
    height: 100%;
`;

const baseURL = `${process.env.REACT_APP_BASEURL}admin/user/getOrgList/`;

// Function Starts here
const AddProducts = () => {
    // ============================|| States ||============================ //
    const [org, setOrg] = useState('');
    const [Data, setData] = useState(null);
    const [checked, setChecked] = useState(false);
    const [upload, setUpload] = useState(false);
    const [standardPriceWithoutGst, setStandardPriceWithoutGst] = useState('');
    const handleClick = () => setChecked(!checked);
    const submitButton = () => setChecked(!checked);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [standardPrice, setStandardPrice] = useState('');
    const [spcPrice, setSpcPrice] = useState('');
    const [spcPriceGst, setSpcPriceGst] = useState('');
    const [month, setMonth] = useState(null);
    const [monthWise, setMonthWise] = useState(false);
    const [formData, setFormData] = useState({ is_duration_required: false, durations: [], tags_price: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''], tags_special_price: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''], tags_gst: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false], tags_percentage: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] });
    const [one, setOne] = useState({ tags_price: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''], tags_special_price: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''], tags_gst: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false], tags_percentage: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] });
    const [three, setThree] = useState({ tags_price: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''], tags_special_price: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''], tags_gst: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false], tags_percentage: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] });
    const [six, setSix] = useState({ tags_price: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''], tags_special_price: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''], tags_gst: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false], tags_percentage: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] });
    const [twelve, setTwelve] = useState({ tags_price: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''], tags_special_price: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''], tags_gst: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false], tags_percentage: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] });
    const [roles, setRoles] = useState([]);
    const [isPlanWise, setIsPlanWise] = useState(false);
    const [consultationConfig, setConsultationConfig] = useState([]);
    const [consultationConfigMonthWise, setConsultationConfigMonthWise] = useState([]);
    const [expandSelectConsultants, setExpandSelectConsultants] = useState(false);
    const [isMonthWise, setIsMonthWise] = useState(false);
    const [trigger, setTrigger] = useState(1);
    const [isAddOn, setIsAddOn] = useState(false);
    const [packageIds, setPackageIds] = useState([]);
    const [durationIds, setDurationIds] = useState([]);
    const [productList, setProductList] = useState([]);
    const [messageType, setMessageType] = useState('success');
    const [message, setMessage] = useState('');

    const showToast = (type, message) => {
        setMessageType(type)
        setMessage(message)
        setOpen(true)
    }
    const toggleIsAddOn = () => {
        setIsAddOn(!isAddOn);
    };

    const Sucess = () => {
        setOpen(true);
    };

    const CloseSucess = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    axios.defaults.headers.common = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    };

    const getMonth = (month) => {
        if (month === "1") {
            return one
        }
        if (month === "3") {
            return three
        }
        if (month === "6") {
            return six
        }
        return twelve
    }

    const calculatePrice = (e) => {
        console.log(e);
        let priceChannel = [];
        let specialPriceChannel = [];
        let gstChannel = [];
        const tagChannel = [];
        let total = 0;
        let specialTotalWithoutGst = 0;
        let specialTotalWithGst = 0;
        let CalculatedPrice = 0;
        let CalculatedSpecialPrice = 0;
        let totalWihoutGst = 0;
        let PriceWithoutGst = 0;
        let SpecialPriceWithoutGst = 0;
        const finalArray = [];
        const finalArray1 = [];
        const finalArray2 = [];
        const finalArray3 = [];
        const finalArray4 = [];

        tagChannel.push(e.tag);
        tagChannel.push(e.tag1);
        tagChannel.push(e.tag2);
        tagChannel.push(e.tag3);
        tagChannel.push(e.tag4);
        tagChannel.push(e.tag5);
        tagChannel.push(e.tag6);
        tagChannel.push(e.tag7);
        tagChannel.push(e.tag8);
        tagChannel.push(e.tag9);
        tagChannel.push(e.tag10);
        tagChannel.push(e.tag11);
        tagChannel.push(e.tag12);
        tagChannel.push(e.tag13);
        tagChannel.push(e.tag14);
        tagChannel.push(e.tag15);
        tagChannel.push(e.tag16);
        tagChannel.push(e.tag17);
        tagChannel.push(e.tag18);
        tagChannel.push(e.tag19);
        tagChannel.push(e.tag20);

        if (formData.is_duration_required) {
            // console.log(one, three, six, twelve, formData)
            for (let j = 0; j < formData.durations.length; j += 1) {
                total = 0;
                specialTotalWithoutGst = 0;
                specialTotalWithGst = 0;
                CalculatedPrice = 0;
                CalculatedSpecialPrice = 0;
                totalWihoutGst = 0;
                PriceWithoutGst = 0;
                SpecialPriceWithoutGst = 0;
                for (let i = 0; i < tagChannel.length; i += 1) {
                    if (tagChannel[i] === true) {
                        if (getMonth(formData.durations[j].life).tags_price[i] !== '') {
                            let gstPersentage = 0;
                            if (getMonth(formData.durations[j].life).tags_gst[i] === true) {
                                gstPersentage = 18;
                                CalculatedPrice = parseInt(getMonth(formData.durations[j].life).tags_price[i], 10) + (parseInt(getMonth(formData.durations[j].life).tags_price[i], 10) * gstPersentage) / 100;
                                CalculatedSpecialPrice =
                                    parseInt(getMonth(formData.durations[j].life).tags_special_price[i], 10) + (parseInt(getMonth(formData.durations[j].life).tags_special_price[i], 10) * gstPersentage) / 100;
                                PriceWithoutGst = parseInt(getMonth(formData.durations[j].life).tags_price[i], 10);
                                SpecialPriceWithoutGst = parseInt(getMonth(formData.durations[j].life).tags_special_price[i], 10);
                            } else {
                                gstPersentage = 0;
                                CalculatedPrice = parseInt(getMonth(formData.durations[j].life).tags_price[i], 10);
                                PriceWithoutGst = parseInt(getMonth(formData.durations[j].life).tags_price[i], 10);
                                CalculatedSpecialPrice = parseInt(getMonth(formData.durations[j].life).tags_special_price[i], 10);
                                SpecialPriceWithoutGst = parseInt(getMonth(formData.durations[j].life).tags_special_price[i], 10);
                            }
                            if (!finalArray.includes(i + 1)) {
                                finalArray.push(i + 1);
                            }
                            finalArray1.push(getMonth(formData.durations[j].life).tags_price[i]);
                            finalArray2.push(getMonth(formData.durations[j].life).tags_gst[i]);
                            finalArray3.push(gstPersentage);
                            finalArray4.push(getMonth(formData.durations[j].life).tags_special_price[i]);

                            // for testing purpose
                            total += CalculatedPrice;
                            totalWihoutGst += PriceWithoutGst;
                            specialTotalWithGst += CalculatedSpecialPrice;
                            specialTotalWithoutGst += SpecialPriceWithoutGst;
                        }
                    }
                }
                console.log("CALCULATED", total, specialTotalWithoutGst)
                if (getMonth(formData.durations[j].life) === one) {
                    setOne({
                        ...one,
                        standard_price_gst: total,
                        standard_price: totalWihoutGst,
                        special_price: specialTotalWithoutGst,
                        special_price_gst: specialTotalWithGst
                    })
                } else if (getMonth(formData.durations[j].life) === three) {
                    setThree({
                        ...three,
                        standard_price_gst: total,
                        standard_price: totalWihoutGst,
                        special_price: specialTotalWithoutGst,
                        special_price_gst: specialTotalWithGst
                    })
                } else if (getMonth(formData.durations[j].life) === six) {
                    setSix({
                        ...six,
                        standard_price_gst: total,
                        standard_price: totalWihoutGst,
                        special_price: specialTotalWithoutGst,
                        special_price_gst: specialTotalWithGst
                    })
                } else if (getMonth(formData.durations[j].life) === twelve) {
                    setTwelve({
                        ...twelve,
                        standard_price_gst: total,
                        standard_price: totalWihoutGst,
                        special_price: specialTotalWithoutGst,
                        special_price_gst: specialTotalWithGst
                    })
                }
            }
            setUpload(true);
            return {
                finalArray,
                finalArray1,
                finalArray2,
                finalArray3,
                finalArray4
            };
        }
        gstChannel = formData.tags_gst
        priceChannel = formData.tags_price
        specialPriceChannel = formData.tags_special_price
        setUpload(true);


        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tagChannel.length; i++) {
            if (tagChannel[i] === true) {
                if (priceChannel[i] !== '') {
                    let gstPersentage = 0;
                    if (gstChannel[i] === true) {
                        gstPersentage = 18;
                        CalculatedPrice = parseInt(priceChannel[i], 10) + (parseInt(priceChannel[i], 10) * gstPersentage) / 100;
                        CalculatedSpecialPrice =
                            parseInt(specialPriceChannel[i], 10) + (parseInt(specialPriceChannel[i], 10) * gstPersentage) / 100;
                        PriceWithoutGst = parseInt(priceChannel[i], 10);
                        SpecialPriceWithoutGst = parseInt(specialPriceChannel[i], 10);
                    } else {
                        gstPersentage = 0;
                        CalculatedPrice = parseInt(priceChannel[i], 10);
                        PriceWithoutGst = parseInt(priceChannel[i], 10);
                        CalculatedSpecialPrice = parseInt(specialPriceChannel[i], 10);
                        SpecialPriceWithoutGst = parseInt(specialPriceChannel[i], 10);
                    }

                    finalArray.push(i + 1);
                    finalArray1.push(priceChannel[i]);
                    finalArray2.push(gstChannel[i]);
                    finalArray3.push(gstPersentage);
                    finalArray4.push(specialPriceChannel[i]);

                    // for testing purpose
                    total += CalculatedPrice;
                    totalWihoutGst += PriceWithoutGst;
                    specialTotalWithGst += CalculatedSpecialPrice;
                    specialTotalWithoutGst += SpecialPriceWithoutGst;
                }
            }
        }

        console.log('CalculatedPrice', total);

        setStandardPrice(total);
        setStandardPriceWithoutGst(totalWihoutGst);
        setSpcPrice(specialTotalWithGst);
        setSpcPriceGst(specialTotalWithoutGst);
        return {
            finalArray,
            finalArray1,
            finalArray2,
            finalArray3,
            finalArray4
        };
    };

    // eslint-disable-next-line consistent-return
    const submitForm = (data) => {
        console.log(calculatePrice(data));
        const { finalArray, finalArray1, finalArray2, finalArray3, finalArray4 } = calculatePrice(data);

        if (formData.is_duration_required) {
            console.log("finalArray", finalArray)
            delete data.tag;
            delete data.tag1;
            delete data.tag2;
            delete data.tag3;
            delete data.tag4;
            delete data.tag5;
            delete data.tag6;
            delete data.tag7;
            delete data.tag8;
            delete data.tag9;
            delete data.tag10;
            delete data.tag11;
            delete data.tag12;
            delete data.tag13;
            delete data.tag14;
            delete data.tag15;
            delete data.tag16;
            delete data.tag17;
            delete data.tag18;
            delete data.tag19;
            delete data.tag20;
            data.tags = finalArray;
            data.is_duration_required = formData.is_duration_required;
            data.durations = [];
            formData.durations.forEach(duration => {
                if (duration.life === "1") {

                    const config = [];
                    consultationConfigMonthWise[duration.life].forEach(item => {
                        if (item.booking_per_month !== "") {
                            config.push({
                                role_id: item.role_id,
                                booking_per_month: item.booking_per_month,
                                is_plan_wise: item.isPlanWise,
                            })
                        }
                    })
                    const tagsPrice = one.tags_price.filter(tagPrice => tagPrice !== '');
                    const tagsSpecialPrice = one.tags_special_price.filter(tagPrice => tagPrice !== '');
                    const tagsGst = finalArray.map(item =>
                        one.tags_gst[item - 1]
                    )
                    duration.tags_price = tagsPrice;
                    duration.tags_special_price = tagsSpecialPrice;
                    duration.consultation_config = config;
                    duration.tags_gst = tagsGst
                    duration.standard_price = one.standard_price
                    duration.standard_price_gst = one.standard_price_gst
                    duration.special_price = one.special_price
                    duration.special_price_gst = one.special_price_gst
                    data.durations.push(duration);
                } else if (duration.life === "3") {

                    const config = [];
                    consultationConfigMonthWise[duration.life].forEach(item => {
                        if (item.booking_per_month !== "") {
                            config.push({
                                role_id: item.role_id,
                                booking_per_month: item.booking_per_month,
                                is_plan_wise: item.isPlanWise,
                            })
                        }
                    })
                    const tagsPrice = three.tags_price.filter(tagPrice => tagPrice !== '');
                    const tagsSpecialPrice = three.tags_special_price.filter(tagPrice => tagPrice !== '');
                    const tagsGst = finalArray.map(item =>
                        three.tags_gst[item - 1]
                    )
                    duration.tags_price = tagsPrice;
                    duration.tags_special_price = tagsSpecialPrice;
                    duration.consultation_config = config;
                    duration.tags_gst = tagsGst
                    duration.standard_price = three.standard_price
                    duration.standard_price_gst = three.standard_price_gst
                    duration.special_price = three.special_price
                    duration.special_price_gst = three.special_price_gst
                    data.durations.push(duration);
                } else if (duration.life === "6") {
                    const config = [];
                    consultationConfigMonthWise[duration.life].forEach(item => {
                        if (item.booking_per_month !== "") {
                            config.push({
                                role_id: item.role_id,
                                booking_per_month: item.booking_per_month,
                                is_plan_wise: item.isPlanWise,
                            })
                        }
                    })
                    const tagsPrice = six.tags_price.filter(tagPrice => tagPrice !== '');
                    const tagsSpecialPrice = six.tags_special_price.filter(tagPrice => tagPrice !== '');
                    const tagsGst = finalArray.map(item =>
                        six.tags_gst[item - 1]
                    )
                    duration.tags_price = tagsPrice;
                    duration.tags_special_price = tagsSpecialPrice;
                    duration.consultation_config = config;
                    duration.tags_gst = tagsGst
                    duration.standard_price = six.standard_price
                    duration.standard_price_gst = six.standard_price_gst
                    duration.special_price = six.special_price
                    duration.special_price_gst = six.special_price_gst
                    data.durations.push(duration);
                } else if (duration.life === "12") {
                    const config = [];
                    consultationConfigMonthWise[duration.life].forEach(item => {
                        if (item.booking_per_month !== "") {
                            config.push({
                                role_id: item.role_id,
                                booking_per_month: item.booking_per_month,
                                is_plan_wise: item.isPlanWise,
                            })
                        }
                    })
                    const tagsPrice = twelve.tags_price.filter(tagPrice => tagPrice !== '');
                    const tagsSpecialPrice = twelve.tags_special_price.filter(tagPrice => tagPrice !== '');
                    const tagsGst = finalArray.map(item =>
                        twelve.tags_gst[item - 1]
                    )
                    duration.tags_price = tagsPrice;
                    duration.tags_special_price = tagsSpecialPrice;
                    duration.consultation_config = config;
                    duration.tags_gst = tagsGst
                    duration.standard_price = twelve.standard_price
                    duration.standard_price_gst = twelve.standard_price_gst
                    duration.special_price = twelve.special_price
                    duration.special_price_gst = twelve.special_price_gst
                    data.durations.push(duration);
                }
            })
        } else {
            const config = [];
            consultationConfig.forEach(item => {
                if (item.booking_per_month !== "") {
                    config.push({
                        role_id: item.role_id,
                        booking_per_month: item.booking_per_month,
                        is_plan_wise: item.isPlanWise,
                    })
                }
            })
            data.tags = finalArray;
            data.is_duration_required = formData.is_duration_required;
            data.durations = [];
            data.tags_price = finalArray1;
            data.tags_gst = finalArray2;
            data.tags_percentage = finalArray3;
            data.tags_special_price = finalArray4;
            data.consultation_config = config;
            console.log('standardPrice', standardPrice);
            data.standard_price_gst = standardPrice;
            data.standard_price = standardPriceWithoutGst;
            // data.special_price_gst = spcPrice;
            data.special_price = spcPriceGst;
            data.special_price_gst = spcPrice;

            delete data.gst;
            delete data.gst1;
            delete data.gst2;
            delete data.gst3;
            delete data.gst4;
            delete data.gst5;
            delete data.gst6;
            delete data.gst7;
            delete data.gst8;
            delete data.gst9;
            delete data.gst10;
            delete data.gst11;
            delete data.gst12;
            delete data.gst13;
            delete data.gst14;
            delete data.gst15;
            delete data.gst16;
            delete data.gst17;
            delete data.gst18;
            delete data.gst19;
            delete data.gst20;

            delete data.price;
            delete data.price1;
            delete data.price2;
            delete data.price3;
            delete data.price4;
            delete data.price5;
            delete data.price6;
            delete data.price7;
            delete data.price8;
            delete data.price9;
            delete data.price10;
            delete data.price11;
            delete data.price12;
            delete data.price13;
            delete data.price14;
            delete data.price15;
            delete data.price16;
            delete data.price17;
            delete data.price18;
            delete data.price19;
            delete data.price20;

            delete data.tag;
            delete data.tag1;
            delete data.tag2;
            delete data.tag3;
            delete data.tag4;
            delete data.tag5;
            delete data.tag6;
            delete data.tag7;
            delete data.tag8;
            delete data.tag9;
            delete data.tag10;
            delete data.tag11;
            delete data.tag12;
            delete data.tag13;
            delete data.tag14;
            delete data.tag15;
            delete data.tag16;
            delete data.tag17;
            delete data.tag18;
            delete data.tag19;
            delete data.tag20;

            delete data.specialPrice;
            delete data.specialPrice1;
            delete data.specialPrice2;
            delete data.specialPrice3;
            delete data.specialPrice4;
            delete data.specialPrice5;
            delete data.specialPrice6;
            delete data.specialPrice7;
            delete data.specialPrice8;
            delete data.specialPrice9;
            delete data.specialPrice10;
            delete data.specialPrice11;
            delete data.specialPrice12;
            delete data.specialPrice13;
            delete data.specialPrice14;
            delete data.specialPrice15;
            delete data.specialPrice16;
            delete data.specialPrice17;
            delete data.specialPrice18;
            delete data.specialPrice19;
            delete data.specialPrice20;
        }
        data.is_add_on = isAddOn;
        data.package_ids = packageIds;
        data.duration_ids = durationIds;
        console.log("I am data", data)
        axios.defaults.headers.common = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        };
        axios.post(`${process.env.REACT_APP_BASEURL}admin/user/addSubscriptionPlanV1`, data).then((response) => {
            console.log('response', response);
            if (response.data.result === 'success') {
                setTimeout(() => {
                    navigate('/product/view');
                }, 200);
                showToast("success", "Product Added Successfully")
            } else {
                showToast("error", "Something went wrong")
            }
        }).catch((err) => {
            console.log('err', err);
            showToast("error", err.response.data.message)
        });
    };

    console.log(errors);

    // ============================|| Event Handelers ||============================ //

    const handleChange = (event) => {
        setOrg(event.target.value);
    };

    const handleDuration = (event) => {
        if (event.target.checked) {
            setFormData(newState => ({
                ...newState, is_duration_required: event.target.checked
            }))
            setIsMonthWise(!isMonthWise);
        } else {
            setFormData(newState => ({
                ...newState, is_duration_required: event.target.checked, durations: []
            }))
            setIsMonthWise(!isMonthWise);
        }
    }

    const handleMonthSelect = (event) => {
        if (event.target.checked) {
            setFormData(newState => ({
                ...newState,
                durations: [...newState.durations, { life: event.target.value, type: event.target.value === "1" ? "month" : "months", tags_price: [], tags_gst: [], tags_percentage: [0, 0, 0, 0, 0], tags_special_price: [] }]
            }))
        } else {
            const durationsFiltered = formData.durations.filter(duration => duration.life !== event.target.value);
            setFormData(newState => ({
                ...newState,
                durations: durationsFiltered
            }))
        }
    }

    const handleTagPrice = (e, label) => {
        if (e.target.value < 0) {
            return showToast("error", "Price can't be negative")
        }
        if (label === 0) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_price: [e.target.value, ...newState.tags_price.slice(1)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_price: [e.target.value, ...newState.tags_price.slice(1)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_price: [e.target.value, ...newState.tags_price.slice(1)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_price: [e.target.value, ...newState.tags_price.slice(1)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_price: [e.target.value, ...newState.tags_price.slice(1)]
                }))
            }
        } else if (label === 1) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 1), e.target.value, ...newState.tags_price.slice(2)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 1), e.target.value, ...newState.tags_price.slice(2)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 1), e.target.value, ...newState.tags_price.slice(2)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 1), e.target.value, ...newState.tags_price.slice(2)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 1), e.target.value, ...newState.tags_price.slice(2)]
                }))
            }
        } else if (label === 2) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 2), e.target.value, ...newState.tags_price.slice(3)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 2), e.target.value, ...newState.tags_price.slice(3)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 2), e.target.value, ...newState.tags_price.slice(3)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 2), e.target.value, ...newState.tags_price.slice(3)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 2), e.target.value, ...newState.tags_price.slice(3)]
                }))
            }
        } else if (label === 3) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 3), e.target.value, ...newState.tags_price.slice(4)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 3), e.target.value, ...newState.tags_price.slice(4)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 3), e.target.value, ...newState.tags_price.slice(4)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 3), e.target.value, ...newState.tags_price.slice(4)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 3), e.target.value, ...newState.tags_price.slice(4)]
                }))
            }
        } else if (label === 4) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 4), e.target.value, ...newState.tags_price.slice(5)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 4), e.target.value, ...newState.tags_price.slice(5)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 4), e.target.value, ...newState.tags_price.slice(5)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 4), e.target.value, ...newState.tags_price.slice(5)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 4), e.target.value, ...newState.tags_price.slice(5)]
                }))
            }
        } else if (label === 5) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 5), e.target.value, ...newState.tags_price.slice(6)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 5), e.target.value, ...newState.tags_price.slice(6)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 5), e.target.value, ...newState.tags_price.slice(6)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 5), e.target.value, ...newState.tags_price.slice(6)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 5), e.target.value, ...newState.tags_price.slice(6)]
                }))
            }
        } else if (label === 6) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 6), e.target.value, ...newState.tags_price.slice(7)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 6), e.target.value, ...newState.tags_price.slice(7)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 6), e.target.value, ...newState.tags_price.slice(7)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 6), e.target.value, ...newState.tags_price.slice(7)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 6), e.target.value, ...newState.tags_price.slice(7)]
                }))
            }
        } else if (label === 7) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 7), e.target.value, ...newState.tags_price.slice(8)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 7), e.target.value, ...newState.tags_price.slice(8)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 7), e.target.value, ...newState.tags_price.slice(8)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 7), e.target.value, ...newState.tags_price.slice(8)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 7), e.target.value, ...newState.tags_price.slice(8)]
                }))
            }
        } else if (label === 8) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 8), e.target.value, ...newState.tags_price.slice(9)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 8), e.target.value, ...newState.tags_price.slice(9)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 8), e.target.value, ...newState.tags_price.slice(9)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 8), e.target.value, ...newState.tags_price.slice(9)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 8), e.target.value, ...newState.tags_price.slice(9)]
                }))
            }
        } else if (label === 9) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 9), e.target.value, ...newState.tags_price.slice(10)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 9), e.target.value, ...newState.tags_price.slice(10)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 9), e.target.value, ...newState.tags_price.slice(10)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 9), e.target.value, ...newState.tags_price.slice(10)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 9), e.target.value, ...newState.tags_price.slice(10)]
                }))
            }
        } else if (label === 10) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 10), e.target.value, ...newState.tags_price.slice(11)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 10), e.target.value, ...newState.tags_price.slice(11)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 10), e.target.value, ...newState.tags_price.slice(11)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 10), e.target.value, ...newState.tags_price.slice(11)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 10), e.target.value, ...newState.tags_price.slice(11)]
                }))
            }
        } else if (label === 11) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 11), e.target.value, ...newState.tags_price.slice(12)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 11), e.target.value, ...newState.tags_price.slice(12)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 11), e.target.value, ...newState.tags_price.slice(12)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 11), e.target.value, ...newState.tags_price.slice(12)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 11), e.target.value, ...newState.tags_price.slice(12)]
                }))
            }
        } else if (label === 12) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 12), e.target.value, ...newState.tags_price.slice(13)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 12), e.target.value, ...newState.tags_price.slice(13)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 12), e.target.value, ...newState.tags_price.slice(13)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 12), e.target.value, ...newState.tags_price.slice(13)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 12), e.target.value, ...newState.tags_price.slice(13)]
                }))
            }
        } else if (label === 13) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 13), e.target.value, ...newState.tags_price.slice(14)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 13), e.target.value, ...newState.tags_price.slice(14)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 13), e.target.value, ...newState.tags_price.slice(14)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 13), e.target.value, ...newState.tags_price.slice(14)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 13), e.target.value, ...newState.tags_price.slice(14)]
                }))
            }
        } else if (label === 14) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 14), e.target.value, ...newState.tags_price.slice(15)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 14), e.target.value, ...newState.tags_price.slice(15)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 14), e.target.value, ...newState.tags_price.slice(15)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 14), e.target.value, ...newState.tags_price.slice(15)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 14), e.target.value, ...newState.tags_price.slice(15)]
                }))
            }
        } else if (label === 15) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 15), e.target.value, ...newState.tags_price.slice(16)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 15), e.target.value, ...newState.tags_price.slice(16)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 15), e.target.value, ...newState.tags_price.slice(16)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 15), e.target.value, ...newState.tags_price.slice(16)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 15), e.target.value, ...newState.tags_price.slice(16)]
                }))
            }
        } else if (label === 16) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 16), e.target.value, ...newState.tags_price.slice(17)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 16), e.target.value, ...newState.tags_price.slice(17)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 16), e.target.value, ...newState.tags_price.slice(17)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 16), e.target.value, ...newState.tags_price.slice(17)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 16), e.target.value, ...newState.tags_price.slice(17)]
                }))
            }
        } else if (label === 17) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 17), e.target.value, ...newState.tags_price.slice(18)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 17), e.target.value, ...newState.tags_price.slice(18)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 17), e.target.value, ...newState.tags_price.slice(18)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 17), e.target.value, ...newState.tags_price.slice(18)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 17), e.target.value, ...newState.tags_price.slice(18)]
                }))
            }
        } else if (label === 18) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 18), e.target.value, ...newState.tags_price.slice(19)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 18), e.target.value, ...newState.tags_price.slice(19)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 18), e.target.value, ...newState.tags_price.slice(19)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 18), e.target.value, ...newState.tags_price.slice(19)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 18), e.target.value, ...newState.tags_price.slice(19)]
                }))
            }
        } else if (label === 19) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 19), e.target.value, ...newState.tags_price.slice(20)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 19), e.target.value, ...newState.tags_price.slice(20)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 19), e.target.value, ...newState.tags_price.slice(20)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 19), e.target.value, ...newState.tags_price.slice(20)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 19), e.target.value, ...newState.tags_price.slice(20)]
                }))
            }
        } else if (label === 20) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 20), e.target.value]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 20), e.target.value]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 20), e.target.value]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 20), e.target.value]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_price: [...newState.tags_price.slice(0, 20), e.target.value]
                }))
            }
        }
    }

    const handleSpecialTagPrice = (e, label) => {
        if (e.target.value < 0) {
            return showToast("error", "Price can't be negative")
        }
        if (label === 0) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_special_price: [e.target.value, ...newState.tags_special_price.slice(1)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_special_price: [e.target.value, ...newState.tags_special_price.slice(1)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_special_price: [e.target.value, ...newState.tags_special_price.slice(1)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_special_price: [e.target.value, ...newState.tags_special_price.slice(1)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_special_price: [e.target.value, ...newState.tags_special_price.slice(1)]
                }))
            }
        } else if (label === 1) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 1), e.target.value, ...newState.tags_special_price.slice(2)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 1), e.target.value, ...newState.tags_special_price.slice(2)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 1), e.target.value, ...newState.tags_special_price.slice(2)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 1), e.target.value, ...newState.tags_special_price.slice(2)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 1), e.target.value, ...newState.tags_special_price.slice(2)]
                }))
            }
        } else if (label === 2) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 2), e.target.value, ...newState.tags_special_price.slice(3)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 2), e.target.value, ...newState.tags_special_price.slice(3)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 2), e.target.value, ...newState.tags_special_price.slice(3)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 2), e.target.value, ...newState.tags_special_price.slice(3)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 2), e.target.value, ...newState.tags_special_price.slice(3)]
                }))
            }
        } else if (label === 3) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 3), e.target.value, ...newState.tags_special_price.slice(4)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 3), e.target.value, ...newState.tags_special_price.slice(4)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 3), e.target.value, ...newState.tags_special_price.slice(4)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 3), e.target.value, ...newState.tags_special_price.slice(4)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 3), e.target.value, ...newState.tags_special_price.slice(4)]
                }))
            }
        } else if (label === 4) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 4), e.target.value, ...newState.tags_special_price.slice(5)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 4), e.target.value, ...newState.tags_special_price.slice(5)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 4), e.target.value, ...newState.tags_special_price.slice(5)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 4), e.target.value, ...newState.tags_special_price.slice(5)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 4), e.target.value, ...newState.tags_special_price.slice(5)]
                }))
            }
        } else if (label === 5) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 5), e.target.value, ...newState.tags_special_price.slice(6)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 5), e.target.value, ...newState.tags_special_price.slice(6)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 5), e.target.value, ...newState.tags_special_price.slice(6)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 5), e.target.value, ...newState.tags_special_price.slice(6)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 5), e.target.value, ...newState.tags_special_price.slice(6)]
                }))
            }
        } else if (label === 6) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 6), e.target.value, ...newState.tags_special_price.slice(7)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 6), e.target.value, ...newState.tags_special_price.slice(7)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 6), e.target.value, ...newState.tags_special_price.slice(7)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 6), e.target.value, ...newState.tags_special_price.slice(7)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 6), e.target.value, ...newState.tags_special_price.slice(7)]
                }))
            }
        } else if (label === 7) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 7), e.target.value, ...newState.tags_special_price.slice(8)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 7), e.target.value, ...newState.tags_special_price.slice(8)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 7), e.target.value, ...newState.tags_special_price.slice(8)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 7), e.target.value, ...newState.tags_special_price.slice(8)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 7), e.target.value, ...newState.tags_special_price.slice(8)]
                }))
            }
        } else if (label === 8) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 8), e.target.value, ...newState.tags_special_price.slice(9)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 8), e.target.value, ...newState.tags_special_price.slice(9)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 8), e.target.value, ...newState.tags_special_price.slice(9)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 8), e.target.value, ...newState.tags_special_price.slice(9)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 8), e.target.value, ...newState.tags_special_price.slice(9)]
                }))
            }
        } else if (label === 9) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 9), e.target.value, ...newState.tags_special_price.slice(10)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 9), e.target.value, ...newState.tags_special_price.slice(10)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 9), e.target.value, ...newState.tags_special_price.slice(10)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 9), e.target.value, ...newState.tags_special_price.slice(10)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 9), e.target.value, ...newState.tags_special_price.slice(10)]
                }))
            }
        } else if (label === 10) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 10), e.target.value, ...newState.tags_special_price.slice(11)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 10), e.target.value, ...newState.tags_special_price.slice(11)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 10), e.target.value, ...newState.tags_special_price.slice(11)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 10), e.target.value, ...newState.tags_special_price.slice(11)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 10), e.target.value, ...newState.tags_special_price.slice(11)]
                }))
            }
        } else if (label === 11) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 11), e.target.value, ...newState.tags_special_price.slice(12)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 11), e.target.value, ...newState.tags_special_price.slice(12)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 11), e.target.value, ...newState.tags_special_price.slice(12)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 11), e.target.value, ...newState.tags_special_price.slice(12)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 11), e.target.value, ...newState.tags_special_price.slice(12)]
                }))
            }
        } else if (label === 12) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 12), e.target.value, ...newState.tags_special_price.slice(13)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 12), e.target.value, ...newState.tags_special_price.slice(13)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 12), e.target.value, ...newState.tags_special_price.slice(13)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 12), e.target.value, ...newState.tags_special_price.slice(13)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 12), e.target.value, ...newState.tags_special_price.slice(13)]
                }))
            }
        } else if (label === 13) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 13), e.target.value, ...newState.tags_special_price.slice(14)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 13), e.target.value, ...newState.tags_special_price.slice(14)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 13), e.target.value, ...newState.tags_special_price.slice(14)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 13), e.target.value, ...newState.tags_special_price.slice(14)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 13), e.target.value, ...newState.tags_special_price.slice(14)]
                }))
            }
        } else if (label === 14) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 14), e.target.value, ...newState.tags_special_price.slice(15)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 14), e.target.value, ...newState.tags_special_price.slice(15)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 14), e.target.value, ...newState.tags_special_price.slice(15)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 14), e.target.value, ...newState.tags_special_price.slice(15)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 14), e.target.value, ...newState.tags_special_price.slice(15)]
                }))
            }
        } else if (label === 15) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 15), e.target.value, ...newState.tags_special_price.slice(16)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 15), e.target.value, ...newState.tags_special_price.slice(16)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 15), e.target.value, ...newState.tags_special_price.slice(16)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 15), e.target.value, ...newState.tags_special_price.slice(16)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 15), e.target.value, ...newState.tags_special_price.slice(16)]
                }))
            }
        } else if (label === 16) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 16), e.target.value, ...newState.tags_special_price.slice(17)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 16), e.target.value, ...newState.tags_special_price.slice(17)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 16), e.target.value, ...newState.tags_special_price.slice(17)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 16), e.target.value, ...newState.tags_special_price.slice(17)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 16), e.target.value, ...newState.tags_special_price.slice(17)]
                }))
            }
        } else if (label === 17) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 17), e.target.value, ...newState.tags_special_price.slice(18)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 17), e.target.value, ...newState.tags_special_price.slice(18)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 17), e.target.value, ...newState.tags_special_price.slice(18)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 17), e.target.value, ...newState.tags_special_price.slice(18)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 17), e.target.value, ...newState.tags_special_price.slice(18)]
                }))
            }
        } else if (label === 18) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 18), e.target.value, ...newState.tags_special_price.slice(19)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 18), e.target.value, ...newState.tags_special_price.slice(19)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 18), e.target.value, ...newState.tags_special_price.slice(19)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 18), e.target.value, ...newState.tags_special_price.slice(19)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 18), e.target.value, ...newState.tags_special_price.slice(19)]
                }))
            }
        } else if (label === 19) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 19), e.target.value, ...newState.tags_special_price.slice(20)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 19), e.target.value, ...newState.tags_special_price.slice(20)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 19), e.target.value, ...newState.tags_special_price.slice(20)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 19), e.target.value, ...newState.tags_special_price.slice(20)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 19), e.target.value, ...newState.tags_special_price.slice(20)]
                }))
            }
        } else if (label === 20) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 20), e.target.value]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 20), e.target.value]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 20), e.target.value]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 20), e.target.value]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_special_price: [...newState.tags_special_price.slice(0, 20), e.target.value]
                }))
            }
        }
    }

    const handleGST = (e, label) => {
        if (label === 0) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_gst: [e.target.checked, ...newState.tags_gst.slice(1)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_gst: [e.target.checked, ...newState.tags_gst.slice(1)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_gst: [e.target.checked, ...newState.tags_gst.slice(1)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_gst: [e.target.checked, ...newState.tags_gst.slice(1)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_gst: [e.target.checked, ...newState.tags_gst.slice(1)]
                }))
            }
        } else if (label === 1) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 1), e.target.checked, ...newState.tags_gst.slice(2)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 1), e.target.checked, ...newState.tags_gst.slice(2)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 1), e.target.checked, ...newState.tags_gst.slice(2)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 1), e.target.checked, ...newState.tags_gst.slice(2)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 1), e.target.checked, ...newState.tags_gst.slice(2)]
                }))
            }
        } else if (label === 2) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 2), e.target.checked, ...newState.tags_gst.slice(3)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 2), e.target.checked, ...newState.tags_gst.slice(3)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 2), e.target.checked, ...newState.tags_gst.slice(3)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 2), e.target.checked, ...newState.tags_gst.slice(3)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 2), e.target.checked, ...newState.tags_gst.slice(3)]
                }))
            }
        } else if (label === 3) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 3), e.target.checked, ...newState.tags_gst.slice(4)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 3), e.target.checked, ...newState.tags_gst.slice(4)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 3), e.target.checked, ...newState.tags_gst.slice(4)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 3), e.target.checked, ...newState.tags_gst.slice(4)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 3), e.target.checked, ...newState.tags_gst.slice(4)]
                }))
            }
        } else if (label === 4) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 4), e.target.checked, ...newState.tags_gst.slice(5)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 4), e.target.checked, ...newState.tags_gst.slice(5)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 4), e.target.checked, ...newState.tags_gst.slice(5)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 4), e.target.checked, ...newState.tags_gst.slice(5)]
                }))
            }
            else {
                setFormData(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 4), e.target.checked, ...newState.tags_gst.slice(5)]
                }))
            }
        } else if (label === 5) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 5), e.target.checked, ...newState.tags_gst.slice(6)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 5), e.target.checked, ...newState.tags_gst.slice(6)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 5), e.target.checked, ...newState.tags_gst.slice(6)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 5), e.target.checked, ...newState.tags_gst.slice(6)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 5), e.target.checked, ...newState.tags_gst.slice(6)]
                }))
            }
        } else if (label === 6) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 6), e.target.checked, ...newState.tags_gst.slice(7)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 6), e.target.checked, ...newState.tags_gst.slice(7)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 6), e.target.checked, ...newState.tags_gst.slice(7)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 6), e.target.checked, ...newState.tags_gst.slice(7)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 6), e.target.checked, ...newState.tags_gst.slice(7)]
                }))
            }
        } else if (label === 7) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 7), e.target.checked, ...newState.tags_gst.slice(8)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 7), e.target.checked, ...newState.tags_gst.slice(8)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 7), e.target.checked, ...newState.tags_gst.slice(8)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 7), e.target.checked, ...newState.tags_gst.slice(8)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 7), e.target.checked, ...newState.tags_gst.slice(8)]
                }))
            }
        } else if (label === 8) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 8), e.target.checked, ...newState.tags_gst.slice(9)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 8), e.target.checked, ...newState.tags_gst.slice(9)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 8), e.target.checked, ...newState.tags_gst.slice(9)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 8), e.target.checked, ...newState.tags_gst.slice(9)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 8), e.target.checked, ...newState.tags_gst.slice(9)]
                }))
            }
        } else if (label === 9) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 9), e.target.checked, ...newState.tags_gst.slice(10)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 9), e.target.checked, ...newState.tags_gst.slice(10)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 9), e.target.checked, ...newState.tags_gst.slice(10)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 9), e.target.checked, ...newState.tags_gst.slice(10)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 9), e.target.checked, ...newState.tags_gst.slice(10)]
                }))
            }
        } else if (label === 10) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 10), e.target.checked, ...newState.tags_gst.slice(11)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 10), e.target.checked, ...newState.tags_gst.slice(11)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 10), e.target.checked, ...newState.tags_gst.slice(11)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 10), e.target.checked, ...newState.tags_gst.slice(11)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 10), e.target.checked, ...newState.tags_gst.slice(11)]
                }))
            }
        } else if (label === 11) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 11), e.target.checked, ...newState.tags_gst.slice(12)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 11), e.target.checked, ...newState.tags_gst.slice(12)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 11), e.target.checked, ...newState.tags_gst.slice(12)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 11), e.target.checked, ...newState.tags_gst.slice(12)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 11), e.target.checked, ...newState.tags_gst.slice(12)]
                }))
            }
        } else if (label === 12) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 12), e.target.checked, ...newState.tags_gst.slice(13)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 12), e.target.checked, ...newState.tags_gst.slice(13)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 12), e.target.checked, ...newState.tags_gst.slice(13)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 12), e.target.checked, ...newState.tags_gst.slice(13)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 12), e.target.checked, ...newState.tags_gst.slice(13)]
                }))
            }
        } else if (label === 13) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 13), e.target.checked, ...newState.tags_gst.slice(14)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 13), e.target.checked, ...newState.tags_gst.slice(14)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 13), e.target.checked, ...newState.tags_gst.slice(14)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 13), e.target.checked, ...newState.tags_gst.slice(14)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 13), e.target.checked, ...newState.tags_gst.slice(14)]
                }))
            }
        } else if (label === 14) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 14), e.target.checked, ...newState.tags_gst.slice(15)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 14), e.target.checked, ...newState.tags_gst.slice(15)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 14), e.target.checked, ...newState.tags_gst.slice(15)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 14), e.target.checked, ...newState.tags_gst.slice(15)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 14), e.target.checked, ...newState.tags_gst.slice(15)]
                }))
            }
        } else if (label === 15) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 15), e.target.checked, ...newState.tags_gst.slice(16)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 15), e.target.checked, ...newState.tags_gst.slice(16)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 15), e.target.checked, ...newState.tags_gst.slice(16)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 15), e.target.checked, ...newState.tags_gst.slice(16)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 15), e.target.checked, ...newState.tags_gst.slice(16)]
                }))
            }
        } else if (label === 16) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 16), e.target.checked, ...newState.tags_gst.slice(17)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 16), e.target.checked, ...newState.tags_gst.slice(17)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 16), e.target.checked, ...newState.tags_gst.slice(17)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 16), e.target.checked, ...newState.tags_gst.slice(17)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 16), e.target.checked, ...newState.tags_gst.slice(17)]
                }))
            }
        } else if (label === 17) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 17), e.target.checked, ...newState.tags_gst.slice(18)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 17), e.target.checked, ...newState.tags_gst.slice(18)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 17), e.target.checked, ...newState.tags_gst.slice(18)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 17), e.target.checked, ...newState.tags_gst.slice(18)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 17), e.target.checked, ...newState.tags_gst.slice(18)]
                }))
            }
        } else if (label === 18) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 18), e.target.checked, ...newState.tags_gst.slice(19)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 18), e.target.checked, ...newState.tags_gst.slice(19)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 18), e.target.checked, ...newState.tags_gst.slice(19)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 18), e.target.checked, ...newState.tags_gst.slice(19)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 18), e.target.checked, ...newState.tags_gst.slice(19)]
                }))
            }
        } else if (label === 19) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 19), e.target.checked, ...newState.tags_gst.slice(20)]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 19), e.target.checked, ...newState.tags_gst.slice(20)]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 19), e.target.checked, ...newState.tags_gst.slice(20)]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 19), e.target.checked, ...newState.tags_gst.slice(20)]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 19), e.target.checked, ...newState.tags_gst.slice(20)]
                }))
            }
        } else if (label === 20) {
            if (month === "1") {
                setOne(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 20), e.target.checked]
                }))
            } else if (month === "3") {
                setThree(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 20), e.target.checked]
                }))
            } else if (month === "6") {
                setSix(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 20), e.target.checked]
                }))
            } else if (month === "12") {
                setTwelve(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 20), e.target.checked]
                }))
            } else {
                setFormData(newState => ({
                    ...newState,
                    tags_gst: [...newState.tags_gst.slice(0, 20), e.target.checked]
                }))
            }
        }
    }

    const getPrice = (month) => {
        if (getMonth(month) === one) {
            return one.standard_price ? one.standard_price : "-"
        } if (getMonth(month) === three) {
            return three.standard_price ? three.standard_price : "-"
        } if (getMonth(month) === six) {
            return six.standard_price ? six.standard_price : "-"
        }
        return twelve.standard_price ? twelve.standard_price : "-"
    }

    const getPriceGst = (month) => {
        if (getMonth(month) === one) {
            return one.standard_price_gst ? one.standard_price_gst : "-"
        } if (getMonth(month) === three) {
            return three.standard_price_gst ? three.standard_price_gst : "-"
        } if (getMonth(month) === six) {
            return six.standard_price_gst ? six.standard_price_gst : "-"
        }
        return twelve.standard_price_gst ? twelve.standard_price_gst : "-"
    }

    const getSpecialPrice = (month) => {
        if (getMonth(month) === one) {
            return one.special_price ? one.special_price : "-"
        } if (getMonth(month) === three) {
            return three.special_price ? three.special_price : "-"
        } if (getMonth(month) === six) {
            return six.special_price ? six.special_price : "-"
        }
        return twelve.special_price ? twelve.special_price : "-"
    }

    const getSpecialPriceGst = (month) => {
        if (getMonth(month) === one) {
            return one.special_price_gst ? one.special_price_gst : "-"
        } if (getMonth(month) === three) {
            return three.special_price_gst ? three.special_price_gst : "-"
        } if (getMonth(month) === six) {
            return six.special_price_gst ? six.special_price_gst : "-"
        }
        return twelve.special_price_gst ? twelve.special_price_gst : "-"
    }
    // Fetch Org List
    useEffect(() => {
        Authenticated();
        axios.get(baseURL).then((response) => {
            setData(response.data.data);
        });
    }, []);

    useEffect(() => {
        const getRoles = async () => {
            const response = await axios.get(`${process.env.REACT_APP_BASEURL}admin/consultation/admin_roles`);
            setRoles(response.data.data);
            const config = [];
            response.data.data.map((item) => {
                config.push({
                    isChecked: false,
                    role_id: item.id,
                    name: item.name,
                    booking_per_month: "",
                    isPlanWise: false,
                })
                return null
            })
            setConsultationConfig(config);
            setConsultationConfigMonthWise({
                "1": JSON.parse(JSON.stringify(config)),
                "3": JSON.parse(JSON.stringify(config)),
                "6": JSON.parse(JSON.stringify(config)),
                "12": JSON.parse(JSON.stringify(config)),
            });
            console.log(consultationConfigMonthWise);
        }
        getRoles();
    }, []);

    useEffect(() => {
        if (isMonthWise && formData.durations) {
            if (formData.durations.length > 0) {
                setMonth(formData.durations[0]?.life);
            } else {
                setMonth("1");
            }
        } else {
            setMonth(null);
        }
    }, [isMonthWise,formData]);

    const handleConsultationConfigValue = (e, index, month) => {
        if (parseInt(e.target.value, 10) < 0) return alert("Please enter a valid number");
        if (month === "") {
            const config = [...consultationConfig];
            config[index].booking_per_month = parseInt(e.target.value, 10);
            setConsultationConfig(config);
        } else {
            const config = consultationConfigMonthWise;
            config[month][index].booking_per_month = parseInt(e.target.value, 10);
            setConsultationConfigMonthWise(config);
        }
        setTrigger(trigger + 1);
        return null
    }

    const handleConsultationConfigCheck = (e, index, month) => {
        if (month === "") {
            const config = [...consultationConfig];
            config[index].isChecked = e.target.checked;
            setConsultationConfig(config);
        } else {
            const config = consultationConfigMonthWise;
            config[month][index].isChecked = e.target.checked;
            setConsultationConfigMonthWise(config);
        }
        setTrigger(trigger + 1);
    }

    const handleConsultationConfigToggle = (e, index, month) => {
        if (month === "") {
            const config = [...consultationConfig];
            config[index].isPlanWise = e.target.checked;
            setConsultationConfig(config);
        } else {
            const config = consultationConfigMonthWise;
            config[month][index].isPlanWise = e.target.checked;
            setConsultationConfigMonthWise(config);
        }
        setTrigger(trigger + 1);
    }

    const getProducts = async () => {
        const response = await axios.get(`${process.env.REACT_APP_BASEURL}admin/user/getOrgProduct?org_id=${org}&add_on=false`);
        setProductList(response.data.data); 
        if (isAddOn) {
            if (response.data.data.length === 0) {
                showToast("error", "No Core products found for this organization. Please add core products first.")
            } else {
                CloseSucess();
            }
        }
    }

    useEffect(() => {
        if (org) {
            getProducts();
        }
    }, [org, isAddOn]);

    const handleProductSelectForAddOn = (e) => {
        const index = e.target.value;
        const product = productList[index];
        const newPackageIds = [...packageIds];
        const newDurationIds = [...durationIds];
        const packageIdsSet = new Set(newPackageIds);
        const durationIdsSet = new Set(newDurationIds);

        if (e.target.checked) {
            packageIdsSet.add(product.package_id);
            durationIdsSet.add(product.duration_id);
        }
        else {
            packageIdsSet.delete(product.package_id);
            durationIdsSet.delete(product.duration_id);
        }
        packageIdsSet.delete(null);
        durationIdsSet.delete(null);
        setPackageIds([...packageIdsSet]);
        setDurationIds([...durationIdsSet]);
        console.log("--> Package ids", [...packageIdsSet]);
        console.log("--> Duration ids", [...durationIdsSet]);
    }

    return (
        <MainCard>
            <div style={{ width: '100%' }}>
                <Formdiv style={{ width: '80%', padding: '40px' }}>
                    <div className="productType">
                        <label>Product Type :</label>&nbsp;&nbsp;&nbsp;&nbsp;
                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label="options"
                                name="options"
                                value={isAddOn}
                                onChange={toggleIsAddOn}
                                row
                            >
                                <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label={<span style={isAddOn ? {} : { color: "black" }}>Core-Product</span>}
                                />
                                <FormControlLabel
                                    value
                                    control={<Radio />}
                                    label={<span style={!isAddOn ? {} : { color: "black" }}>Add-On Product</span>}
                                />
                            </RadioGroup>
                        </FormControl>
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                    <label>Product Name :</label>
                    <label style={{ marginLeft: "5.5rem" }}>Choose Organization :</label>
                    <br />
                    <br />
                    <TextField id="ProductName" label="Name" variant="outlined" {...register('plan_name', { required: true })} /> <span />
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        {...register('org_id', { required: true })}
                        value={org}
                        label="Select Organization"
                        onChange={handleChange}
                        style={{ width: '40%' }}
                    >
                        {Data && Data.map((item) => <MenuItem value={item.id}>{item.org_name}</MenuItem>)}
                    </Select><br /><br />
                    <br />
                    {
                        (isAddOn && productList.length > 0) && (
                            <div>
                                <label>Select Products For This Add-On :</label>
                                <br />
                                <br />
                                <div className="productList">
                                    {
                                        // eslint-disable-next-line arrow-body-style
                                        productList.map((product, index) => {
                                            return (
                                                <div className='productName'>
                                                    <FormControlLabel
                                                        control={<Checkbox
                                                            color="primary"
                                                        />}
                                                        label={<span style={{ fontSize: '0.9rem' }}>{product.package_title}</span>}
                                                        onChange={handleProductSelectForAddOn}
                                                        value={index}
                                                    />&nbsp;&nbsp;&nbsp;
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <br />
                            </div>
                        )
                    }
                    <label>Description :</label>
                    <br /><br />
                    <TextareaAutosize style={{ width: '63%', minHeight: '20%', }} {...register('package_description', { required: true })} />
                    <br />
                    <br />
                    {
                        !isAddOn && (
                            <div>
                                <FormControlLabel
                                    control={<Checkbox
                                        color="primary"
                                        onChange={handleDuration}
                                    />}
                                    label='Duration :'
                                />
                                <br />
                                <FormControlLabel
                                    control={<Checkbox
                                        color="primary"
                                    />}
                                    label='1 Month'
                                    disabled={!formData.is_duration_required}
                                    onChange={handleMonthSelect}
                                    value="1"
                                />&nbsp;&nbsp;&nbsp;
                                <FormControlLabel
                                    control={<Checkbox
                                        color="primary"
                                    />}
                                    label='3 Months'
                                    disabled={!formData.is_duration_required}
                                    onChange={handleMonthSelect}
                                    value="3"
                                />&nbsp;&nbsp;&nbsp;
                                <FormControlLabel
                                    control={<Checkbox
                                        color="primary"
                                    />}
                                    label='6 Months'
                                    disabled={!formData.is_duration_required}
                                    onChange={handleMonthSelect}
                                    value="6"
                                />&nbsp;&nbsp;&nbsp;
                                <FormControlLabel
                                    control={<Checkbox
                                        color="primary"
                                    />}
                                    label='12 Months'
                                    disabled={!formData.is_duration_required}
                                    onChange={handleMonthSelect}
                                    value="12"
                                />
                            </div>
                        )
                    }
                    <br />
                    <br />
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" onClick={() => setMonth("1")} sx={{ color: "#989898", borderColor: month === "1" ? "#1A98DE" : "#989898", borderRadius: "1rem", cursor: "pointer", display: formData.durations.filter(duration => duration.life === "1").length === 0 ? "none" : "block" }}>1 Month</Button>
                        <Button variant="outlined" onClick={() => setMonth("3")} sx={{ color: "#989898", borderColor: month === "3" ? "#1A98DE" : "#989898", borderRadius: "1rem", cursor: "pointer", display: formData.durations.filter(duration => duration.life === "3").length === 0 ? "none" : "block" }}>3 Months</Button>
                        <Button variant="outlined" onClick={() => setMonth("6")} sx={{ color: "#989898", borderColor: month === "6" ? "#1A98DE" : "#989898", borderRadius: "1rem", cursor: "pointer", display: formData.durations.filter(duration => duration.life === "6").length === 0 ? "none" : "block" }}>6 Months</Button>
                        <Button variant="outlined" onClick={() => setMonth("12")} sx={{ color: "#989898", borderColor: month === "12" ? "#1A98DE" : "#989898", borderRadius: "1rem", cursor: "pointer", display: formData.durations.filter(duration => duration.life === "12").length === 0 ? "none" : "block" }}>12 Months</Button>
                    </Stack>
                    <form>
                        <GstPriceContainer>
                            <Option>
                                <Content>
                                    <h3>Features</h3>
                                </Content>
                                <Content>
                                    <h3>Standard Price</h3>
                                </Content>
                                <Content>
                                    <h3>Special Price</h3>
                                </Content>
                                <Content>
                                    <h3>GST Applicable</h3>
                                </Content>
                            </Option>
                            <Option>
                                <Content>
                                    <FormControlLabel control={<Checkbox color="primary" {...register('tag')} />} label="Dna Test" />
                                </Content>
                                {
                                    console.log("eneoc", one, three)
                                }
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_price[0] : formData.tags_price[0]} onChange={(e) => handleTagPrice(e, 0)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_special_price[0] : formData.tags_special_price[0]} onChange={(e) => handleSpecialTagPrice(e, 0)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <Checkbox color="primary" value="yes" checked={month ? getMonth(month).tags_gst[0] : formData.tags_gst[0]} onChange={(e) => handleGST(e, 0)} />
                                </Content>
                            </Option>
                            <Option>
                                <Content>
                                    <FormControlLabel control={<Checkbox color="primary" {...register('tag1')} />} label="Blood Test" />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_price[1] : formData.tags_price[1]} onChange={(e) => handleTagPrice(e, 1)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_special_price[1] : formData.tags_special_price[1]} onChange={(e) => handleSpecialTagPrice(e, 1)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <Checkbox color="primary" value="yes" checked={month ? getMonth(month).tags_gst[1] : formData.tags_gst[1]} onChange={(e) => handleGST(e, 1)} />
                                </Content>
                            </Option>
                            <Option>
                                <Content>
                                    <FormControlLabel
                                        control={<Checkbox color="primary" {...register('tag2')} onClick={() => setMonthWise(!monthWise)} />}
                                        label="One on One consultation"
                                    />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_price[2] : formData.tags_price[2]} onChange={(e) => handleTagPrice(e, 2)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_special_price[2] : formData.tags_special_price[2]} onChange={(e) => handleSpecialTagPrice(e, 2)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <Checkbox color="primary" value="yes" checked={month ? getMonth(month).tags_gst[2] : formData.tags_gst[2]} onChange={(e) => handleGST(e, 2)} />
                                </Content>
                            </Option>
                            {
                                monthWise && (
                                    <Option>
                                        <div className='month-wise' >
                                            <div className="consultant-selection-header">
                                                <div style={{ width: "90%", position: "relative" }}>
                                                    <h3>Select Consultant</h3>
                                                </div>
                                                <div className='icon-container'>
                                                    {
                                                        expandSelectConsultants ?
                                                            <KeyboardArrowUpIcon onClick={() => setExpandSelectConsultants(false)} className="icon" />
                                                            :
                                                            <KeyboardArrowDownIcon onClick={() => setExpandSelectConsultants(true)} className="icon" />
                                                    }
                                                </div>
                                            </div>
                                            <div>
                                                {expandSelectConsultants && (
                                                    <p style={{ marginTop: "0px" }} >Note: On selection of month wise customer can book an appointment in every month based on the plan validity.</p>
                                                )}
                                            </div>
                                            {
                                                expandSelectConsultants && (
                                                    !isMonthWise ? (
                                                        <div>
                                                            {
                                                                consultationConfig.map((role, index) => (
                                                                    <div className="row">
                                                                        <Checkbox
                                                                            checked={role.isChecked}
                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                            onChange={(e) => handleConsultationConfigCheck(e, index, "")}
                                                                        />
                                                                        <span style={{ width: "30%", marginLeft: "10px" }} className='wise' >
                                                                            {role.name}
                                                                        </span>
                                                                        <TextField disabled={!role.isChecked} variant="outlined"
                                                                            InputProps={{ min: 1 }} type="number" value={role.booking_per_month
                                                                            }
                                                                            style={(role.isChecked && role.booking_per_month === "") ? { width: "20%", border: "1px solid #D32C2C", borderRadius: "13px" } : { width: "20%" }}
                                                                            onChange={(e) => handleConsultationConfigValue(e, index, "")}
                                                                        />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                        <div className='toggle-container' ><span className='wise' >Month wise</span>
                                                                            {/* <Switch
                                                                    checked={role.isPlanWise}
                                                                    onChange={(e)=>handleConsultationConfigToggle(e, index)}
                                                                    name="toggle"
                                                                    color="primary"
                                                                /> */}
                                                                            <CustomSwitch
                                                                                disabled={!role.isChecked}
                                                                                checked={role.isPlanWise}
                                                                                onChange={(e) => handleConsultationConfigToggle(e, index, "")}
                                                                                inputProps={{ 'aria-label': 'custom checkbox' }}
                                                                            />
                                                                            <span className='wise'>Plan wise</span></div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            {
                                                                consultationConfigMonthWise[month]?.map((role, index) => (
                                                                    <div className="row">
                                                                        <Checkbox
                                                                            checked={role.isChecked}
                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                            onChange={(e) => handleConsultationConfigCheck(e, index, month)}
                                                                        />
                                                                        <span style={{ width: "30%", marginLeft: "10px" }} className='wise' >
                                                                            {role.name}
                                                                        </span>
                                                                        <TextField disabled={!role.isChecked} variant="outlined" type="number" value={role.booking_per_month
                                                                        }
                                                                            style={(role.isChecked && role.booking_per_month === "") ? { width: "20%", border: "1px solid #D32C2C", borderRadius: "13px" } : { width: "20%" }}
                                                                            onChange={(e) => handleConsultationConfigValue(e, index, month)}
                                                                        />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                        <div className='toggle-container' ><span className='wise' >Month wise</span>
                                                                            {/* <Switch
                                                                    checked={role.isPlanWise}
                                                                    onChange={(e)=>handleConsultationConfigToggle(e, index)}
                                                                    name="toggle"
                                                                    color="primary"
                                                                /> */}
                                                                            <CustomSwitch
                                                                                disabled={!role.isChecked}
                                                                                checked={role.isPlanWise}
                                                                                onChange={(e) => handleConsultationConfigToggle(e, index, month)}
                                                                                inputProps={{ 'aria-label': 'custom checkbox' }}
                                                                            />
                                                                            <span className='wise'>Plan wise</span></div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    )
                                                )
                                            }
                                        </div>
                                    </Option>
                                )
                            }
                            <Option>
                                <Content>
                                    <FormControlLabel
                                        control={<Checkbox color="primary" {...register('tag3')} />}
                                        label="Personalized Traning"
                                        checked={checked}
                                        onClick={handleClick}
                                    />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_price[3] : formData.tags_price[3]} onChange={(e) => handleTagPrice(e, 3)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_special_price[3] : formData.tags_special_price[3]} onChange={(e) => handleSpecialTagPrice(e, 3)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <Checkbox color="primary" value="yes" checked={month ? getMonth(month).tags_gst[3] : formData.tags_gst[3]} onChange={(e) => handleGST(e, 3)} />
                                </Content>
                            </Option>

                            <Option>
                                {checked ? (
                                    <>
                                        <br />
                                        <TextField
                                            id="Days"
                                            label="Days"
                                            variant="outlined"
                                            type="number"
                                            {...register('short_desc', { required: true, maxLength: 100 })}
                                        />
                                        <br />
                                    </>
                                ) : null}
                            </Option>
                            <Option>
                                <Content>
                                    <FormControlLabel
                                        control={<Checkbox color="primary" {...register('tag4')} />}
                                        label="Personal Trainer"
                                    />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_price[4] : formData.tags_price[4]} onChange={(e) => handleTagPrice(e, 4)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_special_price[4] : formData.tags_special_price[4]} onChange={(e) => handleSpecialTagPrice(e, 4)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <Checkbox color="primary" value="yes" checked={month ? getMonth(month).tags_gst[4] : formData.tags_gst[4]} onChange={(e) => handleGST(e, 4)} />
                                </Content>
                            </Option>
                            <Option>
                                <Content>
                                    <FormControlLabel
                                        control={<Checkbox color="primary" {...register('tag5')} />}
                                        label="Personalized Nutrition"
                                    />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_price[5] : formData.tags_price[5]} onChange={(e) => handleTagPrice(e, 5)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_special_price[5] : formData.tags_special_price[5]} onChange={(e) => handleSpecialTagPrice(e, 5)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <Checkbox color="primary" value="yes" checked={month ? getMonth(month).tags_gst[5] : formData.tags_gst[5]} onChange={(e) => handleGST(e, 5)} />
                                </Content>
                            </Option>
                            <Option>
                                <Content>
                                    <FormControlLabel control={<Checkbox color="primary" {...register('tag6')} />} label="Live Session" />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_price[6] : formData.tags_price[6]} onChange={(e) => handleTagPrice(e, 6)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_special_price[6] : formData.tags_special_price[6]} onChange={(e) => handleSpecialTagPrice(e, 6)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <Checkbox color="primary" value="yes" checked={month ? getMonth(month).tags_gst[6] : formData.tags_gst[6]} onChange={(e) => handleGST(e, 6)} />
                                </Content>
                            </Option>
                            <Option>
                                <Content>
                                    <FormControlLabel
                                        control={<Checkbox color="primary" {...register('tag7')} />}
                                        label="Thyroid Power Check Blood Test"
                                    />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_price[7] : formData.tags_price[7]} onChange={(e) => handleTagPrice(e, 7)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_special_price[7] : formData.tags_special_price[7]} onChange={(e) => handleSpecialTagPrice(e, 7)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <Checkbox color="primary" value="yes" checked={month ? getMonth(month).tags_gst[7] : formData.tags_gst[7]} onChange={(e) => handleGST(e, 7)} />
                                </Content>
                            </Option>
                            <Option>
                                <Content>
                                    <FormControlLabel
                                        control={<Checkbox color="primary" {...register('tag8')} />}
                                        label="Slim&Smart Analysis Blood Test"
                                    />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_price[8] : formData.tags_price[8]} onChange={(e) => handleTagPrice(e, 8)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_special_price[8] : formData.tags_special_price[8]} onChange={(e) => handleSpecialTagPrice(e, 8)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <Checkbox color="primary" value="yes" checked={month ? getMonth(month).tags_gst[8] : formData.tags_gst[8]} onChange={(e) => handleGST(e, 8)} />
                                </Content>
                            </Option>
                            <Option>
                                <Content>
                                    <FormControlLabel control={<Checkbox color="primary" {...register('tag9')} />} label="Cholesterol Check Blood Test" />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_price[9] : formData.tags_price[9]} onChange={(e) => handleTagPrice(e, 9)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_special_price[9] : formData.tags_special_price[9]} onChange={(e) => handleSpecialTagPrice(e, 9)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <Checkbox color="primary" value="yes" checked={month ? getMonth(month).tags_gst[9] : formData.tags_gst[9]} onChange={(e) => handleGST(e, 9)} />
                                </Content>
                            </Option>
                            <Option>
                                <Content>
                                    <FormControlLabel
                                        control={<Checkbox color="primary" {...register('tag10')} />}
                                        label="Diabetes Defense Check Blood Test"
                                    />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_price[10] : formData.tags_price[10]} onChange={(e) => handleTagPrice(e, 10)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_special_price[10] : formData.tags_special_price[10]} onChange={(e) => handleSpecialTagPrice(e, 10)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <Checkbox color="primary" value="yes" checked={month ? getMonth(month).tags_gst[10] : formData.tags_gst[10]} onChange={(e) => handleGST(e, 10)} />
                                </Content>
                            </Option>
                            <Option>
                                <Content>
                                    <FormControlLabel
                                        control={<Checkbox color="primary" {...register('tag11')} />}
                                        label="Liver Profile Blood Test"
                                    />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_price[11] : formData.tags_price[11]} onChange={(e) => handleTagPrice(e, 11)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_special_price[11] : formData.tags_special_price[11]} onChange={(e) => handleSpecialTagPrice(e, 11)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <Checkbox color="primary" value="yes" checked={month ? getMonth(month).tags_gst[11] : formData.tags_gst[11]} onChange={(e) => handleGST(e, 11)} />
                                </Content>
                            </Option>
                            <Option>
                                <Content>
                                    <FormControlLabel control={<Checkbox color="primary" {...register('tag12')} />} label="Body Blueprint (81+ tests) Blood Test" />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_price[12] : formData.tags_price[12]} onChange={(e) => handleTagPrice(e, 12)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_special_price[12] : formData.tags_special_price[12]} onChange={(e) => handleSpecialTagPrice(e, 12)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <Checkbox color="primary" value="yes" checked={month ? getMonth(month).tags_gst[12] : formData.tags_gst[12]} onChange={(e) => handleGST(e, 12)} />
                                </Content>
                            </Option>
                            <Option>
                                <Content>
                                    <FormControlLabel
                                        control={<Checkbox color="primary" {...register('tag13')} />}
                                        label="Body Blueprint with Vitamin D & B12 (85 tests) Blood Test"
                                    />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_price[13] : formData.tags_price[13]} onChange={(e) => handleTagPrice(e, 13)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_special_price[13] : formData.tags_special_price[13]} onChange={(e) => handleSpecialTagPrice(e, 13)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <Checkbox color="primary" value="yes" checked={month ? getMonth(month).tags_gst[13] : formData.tags_gst[13]} onChange={(e) => handleGST(e, 13)} />
                                </Content>
                            </Option>
                            <Option>
                                <Content>
                                    <FormControlLabel
                                        control={<Checkbox color="primary" {...register('tag14')} />}
                                        label="Hair Health Code for Men (43+ Tests) Blood Test"
                                    />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_price[14] : formData.tags_price[14]} onChange={(e) => handleTagPrice(e, 14)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_special_price[14] : formData.tags_special_price[14]} onChange={(e) => handleSpecialTagPrice(e, 14)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <Checkbox color="primary" value="yes" checked={month ? getMonth(month).tags_gst[14] : formData.tags_gst[14]} onChange={(e) => handleGST(e, 14)} />
                                </Content>
                            </Option>
                            <Option>
                                <Content>
                                    <FormControlLabel
                                        control={<Checkbox color="primary" {...register('tag15')} />}
                                        label="Hair Health Code for Women (47+ Tests) Blood Test"
                                    />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_price[15] : formData.tags_price[15]} onChange={(e) => handleTagPrice(e, 15)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_special_price[15] : formData.tags_special_price[15]} onChange={(e) => handleSpecialTagPrice(e, 15)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <Checkbox color="primary" value="yes" checked={month ? getMonth(month).tags_gst[15] : formData.tags_gst[15]} onChange={(e) => handleGST(e, 15)} />
                                </Content>
                            </Option>
                            <Option>
                                <Content>
                                    <FormControlLabel control={<Checkbox color="primary" {...register('tag16')} />} label="Skin Vitality Check for Men (32+ Tests) Blood Test" />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_price[16] : formData.tags_price[16]} onChange={(e) => handleTagPrice(e, 16)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_special_price[16] : formData.tags_special_price[16]} onChange={(e) => handleSpecialTagPrice(e, 16)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <Checkbox color="primary" value="yes" checked={month ? getMonth(month).tags_gst[16] : formData.tags_gst[16]} onChange={(e) => handleGST(e, 16)} />
                                </Content>
                            </Option>
                            <Option>
                                <Content>
                                    <FormControlLabel
                                        control={<Checkbox color="primary" {...register('tag17')} />}
                                        label="Skin Vitality Check for Women (39+ Tests) Blood Test"
                                    />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_price[17] : formData.tags_price[17]} onChange={(e) => handleTagPrice(e, 17)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_special_price[17] : formData.tags_special_price[17]} onChange={(e) => handleSpecialTagPrice(e, 17)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <Checkbox color="primary" value="yes" checked={month ? getMonth(month).tags_gst[17] : formData.tags_gst[17]} onChange={(e) => handleGST(e, 17)} />
                                </Content>
                            </Option>
                            <Option>
                                <Content>
                                    <FormControlLabel
                                        control={<Checkbox color="primary" {...register('tag18')} />}
                                        label="PCOD Profile Mini (47+ Tests) Blood Test"
                                    />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_price[18] : formData.tags_price[18]} onChange={(e) => handleTagPrice(e, 18)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_special_price[18] : formData.tags_special_price[18]} onChange={(e) => handleSpecialTagPrice(e, 18)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <Checkbox color="primary" value="yes" checked={month ? getMonth(month).tags_gst[18] : formData.tags_gst[18]} onChange={(e) => handleGST(e, 18)} />
                                </Content>
                            </Option>
                            <Option>
                                <Content>
                                    <FormControlLabel control={<Checkbox color="primary" {...register('tag19')} />} label="PCOD Profile Basic (65+ Tests) Blood Test" />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_price[19] : formData.tags_price[19]} onChange={(e) => handleTagPrice(e, 19)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_special_price[19] : formData.tags_special_price[19]} onChange={(e) => handleSpecialTagPrice(e, 19)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <Checkbox color="primary" value="yes" checked={month ? getMonth(month).tags_gst[19] : formData.tags_gst[19]} onChange={(e) => handleGST(e, 19)} />
                                </Content>
                            </Option>
                            <Option>
                                <Content>
                                    <FormControlLabel
                                        control={<Checkbox color="primary" {...register('tag20')} />}
                                        label="PCOD Profile Advanced (69+ Tests) Blood Test"
                                    />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_price[20] : formData.tags_price[20]} onChange={(e) => handleTagPrice(e, 20)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <TextField id="DnaTest" label="₹" variant="outlined" type="number" value={month ? getMonth(month).tags_special_price[20] : formData.tags_special_price[20]} onChange={(e) => handleSpecialTagPrice(e, 20)} inputProps={{ min: 0 }} />
                                </Content>
                                <Content>
                                    <Checkbox color="primary" value="yes" checked={month ? getMonth(month).tags_gst[20] : formData.tags_gst[20]} onChange={(e) => handleGST(e, 20)} />
                                </Content>
                            </Option>
                        </GstPriceContainer>
                    </form>
                    <br />
                    <br />
                    {/* <FormLabel id="PriceLabel">
                        <TextField
                            id="standardPrice"
                            value={standardPrice}
                            disabled
                            label="Standard Price"
                            variant="outlined"
                            type="number"
                        />
                        <span>&nbsp; </span>
                        <TextField id="specialPrice" value={spcPrice} label="Special Price" variant="outlined" type="number" />
                    </FormLabel> */}
                    <Content>
                        <h3>Price Table</h3>
                    </Content>
                    <Table sx={{ maxWidth: 400 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {
                                    formData.is_duration_required ?
                                        <>
                                            <TableCell align='center'>Duration (in Months)</TableCell>
                                            <TableCell align='center'>Standard Price</TableCell>
                                            <TableCell align='center'>Special Price</TableCell>
                                            <TableCell align='center'>Standard Price (with GST)</TableCell>
                                            <TableCell align='center'>Special Price (with GST)</TableCell>
                                        </>
                                        :
                                        <>
                                            <TableCell align='center'>Standard Price</TableCell>
                                            <TableCell align='center'>Special Price</TableCell>
                                            <TableCell align='center'>Standard Price (with GST)</TableCell>
                                            <TableCell align='center'>Special Price (with GST)</TableCell>
                                        </>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                !formData.is_duration_required ?
                                    <TableRow
                                        key="0"
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="center">{standardPriceWithoutGst !== '' ? standardPriceWithoutGst : "-"}</TableCell>
                                        <TableCell align="center">{spcPriceGst !== '' ? spcPriceGst : "-"}</TableCell>
                                        <TableCell align="center">{standardPrice !== '' ? standardPrice : "-"}</TableCell>
                                        <TableCell align="center">{spcPrice !== '' ? spcPrice : "-"}</TableCell>
                                    </TableRow>
                                    :
                                    formData.durations.map(duration => <TableRow
                                        key="1"
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="center">{duration.life}</TableCell>
                                        <TableCell align="center">{getPrice(duration.life)}</TableCell>
                                        <TableCell align="center">{getSpecialPrice(duration.life)}</TableCell>
                                        <TableCell align="center">{getPriceGst(duration.life)}</TableCell>
                                        <TableCell align="center">{getSpecialPriceGst(duration.life)}</TableCell>
                                    </TableRow>)
                            }
                            {/* {rows.map((row) => (
                            <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="right">{row.calories}</TableCell>
                            <TableCell align="right">{row.fat}</TableCell>
                            <TableCell align="right">{row.carbs}</TableCell>
                            <TableCell align="right">{row.protein}</TableCell>
                            </TableRow>
                        ))} */}
                        </TableBody>
                    </Table>
                    <br />
                    <br />
                    <br />
                    <br />
                    <Button variant="contained" onClick={handleSubmit((d) => calculatePrice(d))}>
                        Calculate
                    </Button>
                    <span> </span>
                    {upload ? (
                        <Button variant="contained" onClick={handleSubmit((d) => submitForm(d))}>
                            Add Product
                        </Button>
                    ) : null}
                </Formdiv>
            </div>
            <Snackbar open={open} autoHideDuration={3000} onClose={CloseSucess}>
                <Alert onClose={CloseSucess} severity={messageType} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </MainCard>

        // Hello World hello
    );
};

export default AddProducts;
