import React, { forwardRef, useState } from 'react';
import MainCard from '../../../ui-component/cards/MainCard';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import axios from 'axios';
import { Button } from '@mui/material';
import MaterialTable from 'material-table';
import tableIcons from '../../../themes/MaterialTableIcons';

import { CheckBoxWrapper, Container } from './prescribe.style';
import { useParams } from 'react-router';
import Toaster, { toast } from 'react-hot-toast';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
axios.defaults.baseURL = process.env.REACT_APP_BASEURL;

const Alert = forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

const PrescribeView = () => {
    const navgiate = useNavigate();
    // set Berer token
    const [value, setValue] = React.useState('');
    const [Level, setLevel] = useState(null);
    const [Work, setWork] = useState(null);
    const [tag, setTag] = useState([]);
    const [Category, SetCategory] = useState(null);
    const [data, setData] = useState(null);
    const [show, setShow] = useState(null);
    const [selected, setSelected] = useState(null);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const params = useParams();

    const { id } = params;

    function handleSelectionChanged(id) {
        // treat state as immutable
        // React only does a shallow comparison so we need a new Set
        const newSet = new Set(selected);
        if (newSet.has(id)) newSet.delete(id);
        else newSet.add(id);

        const newArray = Array.from(newSet);
        setSelected(newArray);

        console.log(newArray);
    }

    const handelSubmit = () => {
        axios
            .post(
                'admin/user/assignVideoToUser',
                {
                    user_id: id,
                    video_ids: selected.toString().toString()
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }
            )
            .then((res) => {
                if (res.data.result === 'success') {
                    setTimeout(() => {
                        navgiate('/user/newTest');
                    }, 300);
                    setOpen(true);
                } else {
                    alert('Something went wrong');
                }
            });
    };

    const Arr = [
        { data: 'Neck', tag: 1 },
        { data: 'Shoulder', tag: 2 },
        { data: 'Arm', tag: 3 },
        { data: 'Waist', tag: 4 },
        { data: 'Knee', tag: 5 },
        { data: 'Pelvic', tag: 6 },
        { data: 'Toe', tag: 7 },
        { data: 'Buttocks', tag: 8 },
        { data: 'Trap', tag: 9 },
        { data: 'Triceps', tag: 10 },
        { data: 'Thighs', tag: 11 },
        { data: 'Lower Back', tag: 12 },
        { data: 'Side', tag: 13 },
        { data: 'Thigh', tag: 14 },
        { data: 'Chest', tag: 15 },
        { data: 'Stomach', tag: 16 },
        { data: 'Abs', tag: 17 },
        { data: 'Back', tag: 18 },
        { data: 'Biceps', tag: 19 },
        { data: 'Forearm', tag: 20 },
        { data: 'Shoulders', tag: 21 }
    ];

    const handleChange = (event) => {
        const levels = ['Beginner', 'Intermediate', 'Advance'];
        const workouts = ['Endurance', 'Strength', 'Mix'];
        if (levels.includes(event.target.value)) {
            setLevel(event.target.value);
        } else if (workouts.includes(event.target.value)) {
            setWork(event.target.value);
        } else {
            SetCategory(event.target.value);
        }
    };

    const FetchVideos = () => {
        axios
            .post(
                'admin/user/getAssignVideoList2',
                {
                    levels: Level,
                    workout_type: Work,
                    tag
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }
            )
            .then((res) => {
                console.log('Filtered Video', res.data.data);
                setData(res.data.data);
                setShow(true);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const columns = [
        { title: 'Day', field: 'day_id' },
        { title: 'Activity', field: 'video_title' },
        { title: 'Level', field: 'levels' },
        { title: 'Category', field: 'category' },
        { title: 'Workout Type', field: 'workout_type' },
        { title: 'Pressure Points', field: 'tag' },
        {
            title: 'Video',
            field: 'download_url',
            render: (rowData) => (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <video width="100%" controls controlsList="nodownload" style={{ width: 200 }}>
                    <source src={rowData.download_url} type="video/mp4" />
                </video>
            )
        },
        {
            title: 'Action',
            field: 'action',
            render: (rowData) => (
                <FormControlLabel
                    control={<Checkbox value={rowData.video_id} onChange={() => handleSelectionChanged(rowData.video_id)} />}
                />
            )
        }
    ];

    const Sucess = () => {
        setOpen(true);
    };

    const CloseSucess = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <MainCard>
            <Button onClick={() => {
                // navigate to previous page
                navigate(-1);
            }} style={{ color: 'darkgrey' }}>
                ← Back
            </Button>
            <br />
            <Container>
                <h2>Prescribe Video</h2>
                <FormLabel id="demo-row-radio-buttons-group-label">Select Level :</FormLabel>
                <RadioGroup value={Level} onChange={handleChange} style={{ padding: '10px' }}>
                    <CheckBoxWrapper>
                        <FormControlLabel value="Beginner" control={<Radio />} label="Beginner" />
                        <FormControlLabel value="Intermediate" control={<Radio />} label="Intermediate" />
                        <FormControlLabel value="Advance" control={<Radio />} label="Advance" />
                    </CheckBoxWrapper>
                </RadioGroup>
                <FormLabel id="demo-row-radio-buttons-group-label">Select Workout type:</FormLabel>
                <RadioGroup value={Work} onChange={handleChange} style={{ padding: '10px' }}>
                    <CheckBoxWrapper>
                        <FormControlLabel value="Endurance" control={<Radio />} label="Endurance" />
                        <FormControlLabel value="Strength" control={<Radio />} label="Strength" />
                        <FormControlLabel value="Mix" control={<Radio />} label="Mix" />
                    </CheckBoxWrapper>
                </RadioGroup>
                <Autocomplete
                    multiple
                    id="Autocomplete"
                    options={Arr}
                    disableCloseOnSelect
                    onChange={(event, value) => setTag(value.map((v) => v.data))}
                    getOptionLabel={(option) => option.data}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                            {option.data}
                        </li>
                    )}
                    style={{ width: 500 }}
                    renderInput={(params) => (
                        <TextField {...params} label="Pressure points to Avoid" placeholder="Pressure points to Avoid" />
                    )}
                />
                <Button variant="outlined" onClick={FetchVideos} style={{ marginTop: '20px', marginBottom: '20px' }}>
                    Fetch Videos
                </Button>
                {show ? (
                    <>
                        <div>
                            <MaterialTable
                                title={null}
                                columns={columns}
                                data={data}
                                icons={tableIcons}
                                options={{
                                    search: false,
                                    pageSize: 20,
                                    paging: false,
                                    maxBodyHeight: '700px',
                                    headerStyle: {
                                        backgroundColor: '#01579b',
                                        color: '#FFF',
                                        fontWeight: 100
                                    }, // eslint-disable-next-line consistent-return
                                    rowStyle: (x) => {
                                        if (x.tableData.id % 2) {
                                            return { backgroundColor: '#eef9ff' };
                                        }
                                    }
                                }}
                            />
                        </div>
                        <Button style={{ marginTop: '20px', marginBottom: '20px' }} variant="contained" onClick={handelSubmit}>
                            Submit
                        </Button>
                    </>
                ) : null}
            </Container>
            <Snackbar open={open} autoHideDuration={6000} onClose={CloseSucess}>
                <Alert onClose={CloseSucess} severity="success" sx={{ width: '100%' }}>
                    Videos Assigned Successfully!
                </Alert>
            </Snackbar>
        </MainCard>
    );
};
export default PrescribeView;
